<template>
    <div class="modal__backdrop modal__open" @mousedown.self="$emit('close', $event)">
        <div class="modal__content">
            <div v-if="hasCloseButton" @click="$emit('close')" class="align-right">
                <span class="pointer"><span class="material-icons-outlined">close</span></span>
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    data() {
        return {
            escListener: null,
        };
    },
    props: {
        hasCloseButton: {
            default: false
        }
    },
    methods: {
        createEscapeListener(self) {
            return function (e) {
                if (e.key === 'Escape' && self.open) {
                    self.$emit('close');
                }
            };
        },
    },
    created() {
        this.escListener = this.createEscapeListener(this);
        window.addEventListener('keydown', this.escListener);
        document.querySelector('body').style.overflow = 'hidden';
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.escListener);
        document.querySelector('body').style.overflow = 'auto';
    },
};
</script>
