<template>
    <div>
        <label for="saved-cards-dropdown">Saved Cards</label>
        <select id="saved-cards-dropdown" v-model="selectedCard">
            <option value="" disabled selected>
                {{ hasSavedCards ? 'Choose a card' : 'Client does not have saved cards' }}
            </option>
            <option v-for="card in cards" :key="card.stripe_pm_id" :value="card">
                *{{ card.last4_cc_num }} - {{ card.payment_name }}
            </option>
        </select>
    </div>
</template>

<script>
    import { payments } from '@/util/apiRequests';

    export default {
        name: 'SavedCardsDropdown',
        props: {
            clientId: {
                type: [Number, String],
                required: true,
            },
        },
        data() {
            return {
                cards: [],
                selectedCard: null,
            };
        },
        computed: {
            hasSavedCards() {
                return this.cards.length > 0;
            },
        },
        methods: {
            async fetchSavedCards() {
                const res = await this.$api.get(payments.getSavedCardsForClient(this.clientId));

                if (res.status !== 200) {
                    //this.$toasted.error('Failed to retrieve saved cards');
                }

                this.cards = res.data;
            },
            clear() {
                this.selectedCard = null;
            },
        },
        async created() {
            await this.fetchSavedCards();
        },
        watch: {
            selectedCard(newValue) {
                this.$emit('choseCard', newValue);
            },
        },
    };
</script>

<style scoped></style>
