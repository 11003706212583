<template>
    <div v-if="eventType === 'APPOINTMENT'" class="event-clients">
        <div class="d-flex">
            <h5 class="appt-section-title" style="width: 45%;">{{ headerText }}*</h5>
            <h5 class="appt-section-title" style="margin: 0px 1rem 0px 1rem;">Attendance</h5>
        </div>
       
        <slot />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Clients',
        computed: {
            ...mapState('eventEditor', {
                clients: (state) => state.event.clients,
                eventType: (state) => state.event.type,
            }),
            headerText() {
                return `Member${this.clients.length > 1 ? 's' : ''}`;
            }
        }
    };
</script>
