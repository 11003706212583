<template>
    <div>
        <Schedule />
    </div>
</template>

<script>
    import Schedule from '../../components/calendar/Schedule.vue';
    export default {
        name: 'Calendar',
        components: { Schedule },
    };
</script>
