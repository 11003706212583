<template>
    <NoButtonModal :open="open" @close="$emit('close')">
        <div>
            <h1>Autopay</h1>
            <p>
                {{ name }} has enabled autopay on one of their stored debit/credit cards. Creating an invoice will
                automatically charge their stored card. Are you sure you would like to proceed with creating this
                invoice?
            </p>
            <div class="align-right">
                <button class="secondary" type="button" @click="$emit('close')">Cancel</button>
                <button class="primary" type="button" @click="$emit('confirm')">Confirm</button>
            </div>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    export default {
        name: 'AutopayWarningModal',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            total: {
                type: String,
                required: true
            }
        }
    };
</script>

<style scoped></style>
