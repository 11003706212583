<template>
    <div v-if="eventType === EventType.APPOINTMENT" class="mb-4">
        <h5 class="appt-section-title">Service(s)*</h5>

        <slot />

        <div>
            <button class="primary d-flex align-items-center btn-add-service p-2" v-if="showAddButtonDisabled" type="button" @click="handleAddService" :disabled="addButtonDisabled">
                <span class="material-icons icon-add-service me-1">add_box</span> Add Service
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { EventType } from '@/util/globalConstants';

    export default {
        name: 'Services',
        data() {
            return {
                EventType,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                apptType: (state) => state.event.appt_type,
                eventType: (state) => state.event.type,
                eventServices: (state) => state.event.services,
                clientHasMadePayment: (state) => state.globalAttrs.clientHasMadePayment,
            }),
            ...mapGetters('eventEditor', {
                someClientHasCancelledNoShow: 'someClientHasCancelledNoShow',
                allClientsHaveCancelledNoShow: 'allClientsHaveCancelledNoShow',
                someClientHasBeenInvoiced: 'someClientHasBeenInvoiced',
                someClientHasAProgressNote: 'someClientHasAProgressNote',
            }),
            addButtonDisabled() {
                return this.eventServices.some((s) => s.serviceCodeId === null);
            },
            showAddButtonDisabled(){
                if(this.someClientHasBeenInvoiced){
                    return false
                }

                if(this.someClientHasAProgressNote){
                    return false
                }

                if(this.allClientsHaveCancelledNoShow){
                    return false
                }

                return true
            }
        },
        methods: {
            handleAddService() {
                if(this.apptType === "Individual" && this.someClientHasCancelledNoShow){
                    this.$toasted.error(`Services cannot be added to this appointment.`);
                    return;
                }

                if (this.clientHasMadePayment) {
                    this.$toasted.error(`Services cannot be added if a member has made a payment towards the invoice for this appointment.`);
                    return;
                }

                const newEventServices = [...this.eventServices, {
                    code: null,
                    fee: null,
                    name: null,
                    serviceCodeId: null,
                }];

                this.$store.commit('eventEditor/updateEvent', { property: 'services', value: newEventServices });
            }
        }
    };
</script>
<style scoped>
    .icon-add-service {
        font-size: 17px !important;
    }
    .btn-add-service {
        font-size: .95em;
        font-weight: 600;
    }
</style>
