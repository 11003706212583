<template>
    <div>
        <!-- New calendar filter design -->
        <div class="calendar-search-card p-4" style="min-height: 12.5em;">
            <div class="d-flex search-client-tag mt-4">
                <div class="d-flex me-2">
                    <input type="radio" id="client-search" name="client-tag-search" value="search-by-client" v-model="searchBy" checked/>
                    <label for="client-search" class="pointer">Members</label>
                </div>
                <div class="d-flex ms-2">
                    <input type="radio" id="tag-search" name="client-tag-search" value="search-by-tags" v-model="searchBy"/>
                    <label for="tag-search" class="pointer">Tags</label>
                </div>
            </div>
            <div class="position-relative" style="margin-top: 2.8em;">
                <Multiselect
                    class="calendar-search-box radius-10"
                    v-if="isSearchbyclient"
                    label="name"
                    track-by="id"
                    :options="options"
                    :searchable="true"
                    :placeholder="searchMessage"
                    :show-labels="false"
                    v-model='value'
                    :disabled='isDisabled'
                    @select="(e) => handleSelectionSearch(e)"
                    @open="handleOpen"
                    @close="handleClose"
                >
                    <template slot="noResult">
                        <div class="no-result-message">No members found</div>
                    </template>
                </Multiselect>
                <Multiselect
                    class="calendar-search-box radius-10"
                    v-if="!isSearchbyclient"
                    :options="options"
                    :searchable="true"
                    :placeholder="searchMessage"
                    :show-labels="false"
                    v-model='value'
                    :disabled='isDisabled'
                    @select="(e) => handleSelectionSearch(e)"
                    @open="handleOpen"
                    @close="handleClose"
                >
                    <template slot="noResult">
                        <div class="no-result-message">No tags found</div>
                    </template>
                </Multiselect>
                <!-- <button class="cal-search-reset no-bg" @click='handleRemove'>
                    <span class="material-symbols-outlined">close</span>
                </button> -->
                <span v-if="value" role="button" class="material-symbols-outlined clear-multiselect-search" title="Clear Search" @click="handleRemove">close</span>
            </div>
        </div>
        <div style="margin-top: 6em;margin-bottom: 6em;">
            <Dropdown
                label="Place of Service"
                class="place_of_service"
                name="place_of_service"
                id="place_of_service"
                v-model="selectedLocationFilter"
                :options="locationFilterItems"
                :disableEmpty="true"
                placeholder="--- Choose --------"
                @change="(value) => handleSelect(value)"
            />
        </div>
        <div class="mb-3">
            <div v-if="userIsAdmin" class="calendar-search-card d-flex justify-content-between align-items-center mb-4 px-3 py-3" style="min-height: 2.5em;">
                <span class="calendar-filter-view-label">All Appointments</span>
                <Toggle
                    id="allAppointmentToggle"
                    class="toggle-switch"
                    v-model="showAllAppointment"
                    :labelClass="'switch'"
                    :spanClass="'slider'"
                    @input="(value) => handleToggle(value, 'ALL_APPOINTMENT')"
                />
            </div>
            <div class="calendar-search-card d-flex justify-content-between align-items-center px-3 py-3" style="min-height: 2.5em;">
                <span class="calendar-filter-view-label">My Appointments</span>
                <Toggle
                    id="myAppointmentToggle"
                    class="toggle-switch"
                    v-model="showMyAppointment"
                    :labelClass="'switch'"
                    :spanClass="'slider'"
                    @input="(value) => handleToggle(value, 'MY_APPOINTMENT')"
                />
            </div>
        </div>
        <!-- New calendar filter design -->
        <div class="flex flex-1">
            <!-- <h3>Search</h3> -->
            <!-- <div class="calendar-search flex flex-1 right-20 relative">
                <div class="flex">
                    <input type="radio" class="color" id="client-search" name="client-tag-search" value="search-by-client" v-model="searchBy" checked/>
                    <label for="client-search" class="pointer">Clients</label>
                </div>
                <div class="flex">
                    <input type="radio" class="color" id="tag-search" name="client-tag-search" value="search-by-tags" v-model="searchBy"/>
                    <label for="tag-search" class="pointer">Tags</label>
                </div>
                <Multiselect
                    class="calendar-search-box radius-10"
                    v-if="isSearchbyclient"
                    :taggable="true"
                    :multiple="false"
                    label="name"
                    track-by="id"
                    :options="options"
                    :searchable="true"
                    :placeholder="searchMessage"
                    v-model='value'
                    :disabled='isDisabled'
                    @select="(e) => handleSelectionSearch(e)"
                    @search-change="(e) => handleTypeSearch(e)"
                    @open="handleOpen"
                    @close="handleClose"
                />
                <Multiselect
                    class="calendar-search-box radius-10"
                    v-if="!isSearchbyclient"
                    :options="options"
                    :searchable="true"
                    :placeholder="searchMessage"
                    v-model='value'
                    :disabled='isDisabled'
                    @select="(e) => handleSelectionSearch(e)"
                    @search-change="(e) => handleTypeSearch(e)"
                    @open="handleOpen"
                    @close="handleClose"
                />
                <button class="cal-search-reset no-bg" @click='handleRemove'><span class="material-icons-outlined">close</span></button>
            </div> -->

            <!-- <h3>Filter View</h3> -->
            <!-- <div class="calendar-filters flex center flex-1">
                <ejs-dropdownbutton class="text-link" :items='apptFilterItems' @select="(e) => handleSelect(e) "> {{ apptFilter }} </ejs-dropdownbutton>

                <ejs-dropdownbutton class="text-link white" :items='locationFilterItems' @select="(e) => handleSelect(e) "> {{ locationFilter }} </ejs-dropdownbutton>
            </div> -->
        </div>
    </div>
</template>

<script>

    import Vue from 'vue';
    import {settings} from '@/util/apiRequests'
    import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
    import { Roles } from '@/util/globalConstants'
    import { debounce } from 'lodash';
    Vue.use(DropDownButtonPlugin);

    export default {
        name: 'CalendarFilters',
        props:{
          clients: {
              type: Array,
              required: true
          }
        },
        data(){
            return {
                active: 0,
                selectedLocation: -1,
                apptView: 0,
                allApptFilter: "All Appointments",
                selectedLocationFilter: "All Types",
                searchBy:"search-by-client",
                searchValue: null,
                value: null,
                tags: [],
                apptFilterItems:[
                    {
                        text: 'All Appointments'
                    },
                    {
                        text: 'My Appointments'
                    }],
                locationFilterItems:[
                    {
                        text: 'All Types',
                        value: 'All Types'
                    },
                    {
                        text: 'In Office',
                        value: 'In Office'
                    },
                    {
                        text: 'Telehealth',
                        value: 'Telehealth'
                    }
                    ],
                showAllAppointment: true,
                showMyAppointment: false
            }
        },
        computed: {

            isSearchbyclient(){
                if(this.searchBy == "search-by-client" ){
                    return true
                }
                else return false

            },

            //displays placeholder text of search
            searchMessage(){
                if(this.searchValue){

                    return this.searchValue
                }
                if(this.searchBy == "search-by-client" ){

                    return "Search for members"
                }
                if(this.searchBy == "search-by-tags" ){

                    return "Search by tags"
                }
                else{
                    return "Search by Member or by Tags"
                }
            },

            //disables search if "clients" or "tags" aren't selected
            isDisabled(){
                if(this.searchBy == null){
                    return true
                }
                else return false
            },


            options() {
                if (this.searchBy == "search-by-client") {
                    // if "clients" are selected, make the dropdown contain list of clients
                    return this.clients
                }
                if (this.searchBy == "search-by-tags") {
                    // if "tags" are selected, make the dropdown contain list of tags
                    return this.tags
                }
                else{
                    return this.clients
                }
            },

            // displays text on "appts" dropdown button
            apptFilter(){
                return this.allApptFilter
            },

            // displays text on "location" dropdown button
            locationFilter(){
                return this.selectedLocationFilter
            },
            
            userIsAdmin() {
                if (this.$store.state.user?.role_id == Roles.ADMIN || this.$store.state.user?.role_id == Roles.SUPER_ADMIN) {
                    return true;
                } else return false;
            }

        },
        methods:{
            handleOpen(){
                this.active = 1
            },

            handleToggle(value, appointmentType) {
                switch (appointmentType) {
                    case 'ALL_APPOINTMENT': 
                        if (value) {
                            this.handleSelect('All Appointments');
                            this.showMyAppointment = false;
                        } else {
                            this.handleSelect('All Appointments');
                        }
                        break;
                    case 'MY_APPOINTMENT':
                        if (value) {
                            this.handleSelect('My Appointments');
                            this.showAllAppointment = false;
                        } else {
                            this.handleSelect('All Appointments');
                        }
                        break;
                }
            },

            handleSelect(value){
                switch(value){
                    //handles appt view filters
                    case "All Appointments":
                        this.allApptFilter =  value
                        this.apptView = value
                        break;

                    case "My Appointments":
                        this.allApptFilter =  value
                        this.apptView = value
                        break;

                    // handles location filters
                    case "All Types":
                        this.selectedLocationFilter =  value
                        this.selectedLocation = "all"
                        break;

                    case "In Office":
                        this.selectedLocationFilter =  value
                        this.selectedLocation = "11"
                        break;

                    case "Telehealth":
                        this.selectedLocationFilter =  value
                        this.selectedLocation = "02"
                        break;
                }
            },

            // this function emits events to filter the appts as the user SELECTS the input
            handleSelectionSearch(e){
                switch (this.searchBy) {
                    case "search-by-client":
                        this.searchValue = e.name      // where e = {id, name}
                        if(this.active == 1){
                            this.$emit('searchByClients',  {view: this.apptView, location: this.selectedLocation, searchValue: this.searchValue} )
                            this.value = this.searchValue
                        }
                        break

                    case "search-by-tags"  :
                        this.searchValue = e          // where e
                        if(this.active == 1){
                            this.$emit('searchByClients',  {view: this.apptView, location: this.selectedLocation, searchValue: this.searchValue} )
                            this.value = this.searchValue
                        }
                        break
                }
            },

            // this function emits events to filter the appts as the user types in the input
            // This can be used for debounce in future
            
            // handleTypeSearch: debounce(function(e) {
            // }, 500),

            // this function emits events to filter the appts as the user types in the input
            handleTypeSearch(e){
                switch (this.searchBy) {
                    case "search-by-client":
                        if(this.active == 1){
                            this.searchValue = e     // where e is the string of active characters are being typed
                            this.$emit('searchByClients',  {view: this.apptView, location: this.selectedLocation, searchValue: this.searchValue} )
                        }
                        break
                    case "search-by-tags"  :
                        if(this.active == 1){
                            this.searchValue = e    // where e is the string of active characters are being typed
                            this.$emit('searchByTags',  {view: this.apptView, location: this.selectedLocation, searchValue: this.searchValue} )
                        }
                        break
                }
            },

            handleRemove(e){
                switch (this.searchBy) {
                    case "search-by-client":
                        this.searchValue = null
                        this.value = ""
                        this.$emit('searchByClients',  {view: this.apptView, location: this.selectedLocation, searchValue: this.searchValue} )
                        break
                    case "search-by-tags"  :
                        this.searchValue = null   // where e is the string of active characters are being typed
                        this.value = ""
                        this.$emit('searchByTags', {view: this.apptView, location: this.selectedLocation, searchValue: this.searchValue} )
                        break
                }
            },

            handleClose(){
                this.active = 0
            },
            async getCompanyTags(){
                try{
                    const res = await this.$api.get(settings.getSetting('tags', 'company', this.$store.state.user.company_id))
                    this.tags = res.data.value.split(',').map(el => el?.toString()?.trim());

                }catch(error){
                    this.$toasted.error('Could not retrieve company tags');
                }
            },


        },
        watch: {
            searchBy(){
                this.value= null
                this.searchValue = null
                this.$emit('handleView', {view: this.apptView, location: this.selectedLocation,  searchValue: this.searchValue});
            },
            apptView(){
                this.$emit('handleView', {view: this.apptView, location: this.selectedLocation,  searchValue: this.searchValue});
            },
            selectedLocation(){
                this.$emit('handleView', {view: this.apptView, location: this.selectedLocation,  searchValue: this.searchValue});
            },
            // value(){
            //     this.$emit('handleView', {view: this.apptView, location: this.selectedLocation,  searchValue: this.searchValue})
            // }

        },

        async created(){
            await this.getCompanyTags();
        }
    };
</script>

<style scoped>
    .material-symbols-outlined {
        font-size: 20px;
        line-height: unset;
        font-variation-settings:
        'FILL' 0,
        'wght' 200,
        'GRAD' 200,
        'opsz' 48
    }

    .clear-multiselect-search {
        position: absolute;
        top: 5px;
        right: 33px;
    }

    .no-result-message {
        color: #A82A3D;
        font-weight: 600;
    }
</style>