<template>
    <div v-if="isGroupAppt">

        <label class="e-textlabel fw-bolder" for="noteSummary">
            {{ appointmentType }} Summary Note
        </label>
        <textarea
            class="top-5"
            id="noteSummary"
            name="noteSummary"
            rows="4"
            cols="50"
            :value="note"
            @input="handleInput"
            :maxLength="4000"
        />
        <div class="do-not-print-me char-counter font-12" style="text-align:right;">{{ characterCounter(note, 4000) }}</div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'GroupNote',
        computed: {
            ...mapState('eventEditor', {
                appointmentType: (state) => state.event.appt_type,
                note: (state) => state.event.appt_notes,
            }),
            isGroupAppt() {
                return ['Group', 'Couple', 'Family'].includes(this.appointmentType);
            }
        },
        methods: {
            handleInput(e) {
                this.$store.dispatch('eventEditor/updateNote', e.target.value);
            },
            characterCounter(val, limit) {
                return ((val) ? val.length : 0) + " / " + limit + " character(s) found";
            }
        }
    };
</script>
