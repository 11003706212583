<template>
    <div class="modal__backdrop" :class="{ modal__closed: !open, modal__open: open }" @click.self="$emit('close')">
        <div class="modal__content">
            <p class="">
                Deleting this service will affect any existing Invoices and Progress Notes for this appointment. Are you
                sure you want to delete this service?
            </p>
            <div class="align-right">
                <button class="secondary" type="button" @click="$emit('closeModal')">Cancel</button>
                <button class="primary" type="button" @click="handleDelete">Delete</button>
            </div>
        </div>
    </div>
</template>

<script>

    import { serviceItems } from '@/util/apiRequests';

    export default {
        name: 'DeleteServiceModal',
        props: {
            open: Boolean,
            index: {
                type: Number,
                required: false,
                default: null
            },
            invoiceId: {
              type: Number,
              required: false,
            },
            apptId: {
                type: Number,
                required: false,
            },
            clientId: {
                type: Number,
                required: false
            },
            progressNoteId: {
                type: [Number, String],
                required: false
            },
            service:{
                type: Object,
                required: false
            },
            source:{
                type: String,
                required: false
            }
        },
        data() {
            return {
                escListener: null,
            };
        },
        computed:{
            isOpen(){
                if(this.open == true){
                    return true
                }
                else return false
            },
            hasInvoiceProp(){
                if(this.invoiceId && this.invoiceId > 0){
                    return true
                }
                return false
            },
            hasIndexProp(){
                if(this.index !== null){
                    return true
                }
                else return false
            }
        },
        methods: {
            createEscListener(self) {
                return function (e) {
                    if (e.key === 'Escape' && self.open) {
                        self.$emit('closeDelete');
                    }
                };
            },

            async handleDelete() {
                    if( this.source && this.source== "PN"){
                        //TODO: find the service item and remove that from the invoice'
                        let serviceItemIds = [];
                        serviceItemIds.push(this.service)
                        await this.$api.delete(serviceItems.deleteItem(this.service.service_item_id), {
                            data: { idsToRemove: serviceItemIds },
                        });
                        this.$emit('deleteService')
                    }
                    else{
                        this.$emit('deleteService')
                    }

            }
        },
        created() {
            this.escListener = this.createEscListener(this);

            window.addEventListener('keydown', this.escListener);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        },
    };
</script>
