<template>
    <div>
        <div class="appt-section-title">Service(s)*</div>
        <div
            v-for="(service, key2) in selectedServices"
            :key="service.generateTypeId"
            class="service input-btn-row flex center mb-3"
        >
            <div class="me-3 w-100">
                <Multiselect
                    :allow-empty="false"
                    :options='servicesList'
                    :searchable="true"
                    :show-labels="false"
                    label="name"
                    placeholder="Select Service(s)"
                    track-by="id"
                    v-model='selectedServices[key2]'
                    @input="appendCost($event, key2)"
                />
            </div>
            <div class="d-flex align-items-center">
                <ejs-tooltip
                    class="tooltipcontainer"
                    content="Add service"
                    target="#target"
                    cssClass="customtooltip"
                >
                    <span id="target" class="material-icons icon-add-field" @click="add">add</span>
                </ejs-tooltip>
                <ejs-tooltip
                    class="tooltipcontainer"
                    content="Delete"
                    target="#target"
                    cssClass="customtooltip"
                >
                    <span v-show="selectedServices.length > 1" id="target" class="material-symbols-outlined icon-delete-prescription ms-2" @click="remove(key2)">delete</span>
                </ejs-tooltip>
            </div>

                <!-- <button
                    type="button"
                    name="removeServiceBtn"
                    class="delete no-bg left-15"
                    id="removeServiceBtn"
                    @click="remove(key2)"
                    v-show="selectedServices.length > 1"
                >
                    <span class="material-icons-outlined delete dark-text">delete_outline</span>
                </button> -->
            </div>

            <!-- <div class="flex space-between">
                <button class="text-button" type="button" name="addServiceBtn" id="addServiceBtn" @click="add">
                    <span class="material-icons">add_box</span> Add service
                </button>
            </div> -->
        </div>
</template>

<script>
    import Vue from 'vue';
    import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';
    import { generateId } from '@/util/genericUtilityFunctions';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';

    Vue.use(ComboBoxPlugin);

    export default {
        name: 'ServicesForAppt',
        props: {
            serviceList: {
                type: Array,
                required: true,
            },
        },
        components: { CurrencyInput },
        data() {
            return {
                selectedServices: [{ id: null, cost: null, generateTypeId: generateId(), retail_cost: null }],
            };
        },
        computed: {
            servicesList() {
                const selectedServiceIds = this.selectedServices.map(service => service.id);

                return this.serviceList.map((service) => ({
                    id: service.service_code_id,
                    name: service.service_code + ' - ' + (service.long_descrip ?? service.name),
                    cost: service.cost,
                    $isDisabled: selectedServiceIds.includes(service.service_code_id),
                }))
            },
        },
        methods: {
            add() {
                this.selectedServices.push({ id: null, cost: null, generateTypeId: generateId(), retail_cost: null });
            },

            appendCost(service, index) {
                let selectedService = this.selectedServices[index];
                selectedService.retail_cost = service.cost; // not sure if redundant??
                selectedService.cost = service.cost; // not sure if redundant??
                this.$set(this.selectedServices, index, selectedService);
                this.$emit('serviceAdded', this.selectedServices);
            },

            remove(index) {
                if (this.selectedServices.length >= 2) {
                    this.selectedServices.splice(index, 1);
                    this.$emit('serviceAdded', this.selectedServices)
                }
            },

        },
    };
</script>