<template>
    <Modal @close="handleCancel">
        <button class="close-corner-x" type="button" @click.prevent="handleCancel">
            <span class="material-icons-outlined dark-text">close</span>
        </button>
        <div v-if="canDelete">
            <div v-if="isRecurring">
                <div class="bottom-10">
                    <h1 class="bottom-0">How would you like to delete this event?</h1>
                    <span class="dark-red bold">Once event has been deleted, this action cannot be undone.</span>
                </div>

                <div class="flex flex-column">
                    <label class="flex center bottom-10">
                        <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.OnlyCurrent" />
                        <span class="left-10">Delete this appointment only</span>
                    </label>

                    <label class="flex center bottom-10">
                        <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.AllFuture" id="allFuture" />
                        <span class="left-10">
                            Delete this appointment and the following ones in this series
                        </span>
                    </label>
                </div>
                <div class="align-right">
                    <button type="button" class="secondary bottom-0" @click="handleCancel">Cancel</button>
                    <button type="button" class="primary bottom-0" @click="handleDelete">Save</button>
                </div>
            </div>

            <div v-else>
                <div class="flex">
                    <div class="right-20">
                        <span class="material-icons-outlined red-text large-icon">error_outline</span>
                    </div>
                    <div>
                        <h2 class="bottom-5">Delete {{ eventText }}?</h2>
                        <p>
                            Are you sure you want to delete this {{ eventText.toLowerCase() }}? Once an event is
                            deleted, this action cannot be undone.
                        </p>
                    </div>
                </div>
                <div class="align-right top-20">
                    <button class="secondary cancel bottom-0" type="button" @click="handleCancel">
                        Cancel
                    </button>
                    <button
                        class="primary bottom-0"
                        type="button"
                        @click="handleDelete"
                    >
                        Delete {{ eventText }}
                    </button>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="flex center">
                <div class="right-20">
                    <span class="material-icons-outlined red-text large-icon">error_outline</span>
                </div>

                <p>
                    Unable to delete appointment because {{ deletePreventionText}}.
                </p>
            </div>
            <div class="align-right top-20">
                <button
                    class="secondary cancel bottom-0"
                    type="button"
                    @click="handleCancel"
                >
                    OK
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
    import { mapState } from 'vuex';
    import Modal from '@/components/general/modals/Modal';
    import RecurrenceSaveType from '@/util/recurrenceSaveType';
    import { appts, events } from '@/util/apiRequests';

    const progressNoteStarted = 'a progress note has been started';
    const appointmentAttended = 'an appointment has been attended';
    const invoiceGenerated = 'an invoice has been generated';

    export default {
        name: 'DeleteEventModal',
        components: {
            Modal
        },
        data() {
            return {
                hasStartedProgressNote: '',
                hasAppointmentStatus: '',
                hasGeneratedInvoice: '',
                RecurrenceSaveType,
                saveChoice: RecurrenceSaveType.OnlyCurrent,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                event: (state) => state.originalEvent,
                clients: (state) => state.event.clients,
                isRecurring: (state) => state.event.recurring_appt,
                eventType: (state) => state.event.type,
            }),
            canDelete() {
                return !this.hasStartedProgressNote && !this.hasAppointmentStatus && !this.hasGeneratedInvoice;
            },
            deletePreventionText() {
                const reasonCount = this.countDeletePreventionReasons();

                if (reasonCount === 3) {
                    return `${this.hasStartedProgressNote}, ${this.hasAppointmentStatus}, and ${this.hasGeneratedInvoice}`;
                }

                if (reasonCount === 2) {
                    let reason1 = this.hasStartedProgressNote || this.hasAppointmentStatus || this.hasGeneratedInvoice;
                    let reason2;

                    if (reason1 === this.hasStartedProgressNote) {
                        reason2 = this.hasAppointmentStatus || this.hasGeneratedInvoice;
                    } else if (reason1 === this.hasAppointmentStatus) {
                        reason2 = this.hasStartedProgressNote || this.hasGeneratedInvoice;
                    } else {
                        reason2 = this.hasStartedProgressNote || this.hasAppointmentStatus;
                    }

                    return `${reason1} and ${reason2}`;
                }

                if (reasonCount === 1) {
                    return `${this.hasStartedProgressNote || this.hasAppointmentStatus || this.hasGeneratedInvoice}`;
                }

                return '';
            },
            eventText() {
                return this.eventType === 'EVENT' ? 'Event' : 'Appointment';
            }
        },
        methods: {
            handleCancel() {
                this.$store.commit('eventEditor/closeModal', 'deleteEvent');
            },
            getDeleteAttributes() {
                for (let i = 0; i < this.clients?.length; i++) {
                    const client = this.clients[i];

                    if (client.invoice_id !== null) {
                        this.hasGeneratedInvoice = invoiceGenerated;
                    }

                    if (client.progress_note.id !== null) {
                        this.hasStartedProgressNote = progressNoteStarted;
                    }

                    if (client.appt_status === 'Attended') {
                        this.hasAppointmentStatus = appointmentAttended;
                    }
                }
            },
            countDeletePreventionReasons() {
                let count = 0;

                if (this.hasStartedProgressNote) {
                    count++;
                }

                if (this.hasAppointmentStatus) {
                    count++;
                }

                if (this.hasGeneratedInvoice) {
                    count++;
                }
                return count;
            },
            handleDelete() {
                const errorMessage = 'There was an error saving your changes. Please try again later.';
                this.eventType === "EVENT" ? this.deleteEvent(errorMessage) : this.deleteBatch(errorMessage)
            },
            async deleteEvent(errorMessage){
                try {
                    const res = await this.$api.put(events.deleteEventAdmin(), {
                        eventId: this.event.id,
                    });

                    this.handleDeleteResponse(res, errorMessage)

                } catch (err) {
                    this.$toasted.error(errorMessage);
                }
            },
            async deleteBatch(errorMessage){
                try {
                    const res = await this.$api.put(appts.batchUpdate(), {
                        changeType: this.saveChoice,
                        eventMetadata: this.event,
                        changes: {
                            delete_appts: true,
                        }
                    });

                    this.handleDeleteResponse(res, errorMessage)

                } catch (err) {
                    this.$toasted.error(errorMessage);
                }
            },
            handleDeleteResponse(res, errorMessage){
                if (res.status < 300) {
                    this.$toasted.success('Appointment(s) deleted successfully.');
                    this.$emit('deleteSuccessful');
                    this.handleCancel();
                } else if (res.status === 400) {
                    this.$toasted.error(res.data[0]);
                } else {
                    this.$toasted.error(errorMessage);
                }
            },
        },
        created() {
            this.getDeleteAttributes();
        }
    };
</script>
