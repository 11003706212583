<template>
    <div>
        <Input
            type='text'
            name='title'
            id='title'
            label=''
            v-model='apptTitle'

        />
        <div v-if='isTelehealthAppt || isVirtualEvent' class='bottom-30'>
            <router-link to='/video-conferences' class='secondary start-telehealth'>
                Click here to start this Telehealth appointment
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Title',
        props: {
            title: {
                type: String,
                required: true
            },
            telehealth: {
                type: Number,
                required: true
            },
            isUpcoming: {
                type: Boolean,
                required: false
            },
            virtualEvent: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                apptTitle: ''
            };
        },
        computed: {

            isVirtualEvent() {
                if (parseInt(this.virtualEvent) == 2) {
                    return true;
                }

                return false;
            },

            isTelehealthAppt() {
                if (this.telehealth == 1 && this.isUpcoming) {
                    return true;
                }

                return false;
            }

        },
        watch: {
            title() {
                this.apptTitle = this.title;
            },
            apptTitle() {
                this.changesDirty = true;
                this.$emit('titleUpdated', this.apptTitle);
            }
        },
        created() {
            this.apptTitle = this.title;
        }
    };
</script>

<style scoped>

</style>