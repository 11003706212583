<template>
    <div>
        <label :for="id">{{ label }}</label>
        <input class="" :id="id" v-model="inVal" placeholder="Card Number" @blur="$emit('blur', $event)" />
        <span class="block top-5 cc-type-thumb">
            <img v-if="brandSrc" :src="brandSrc" :alt="brand" />
        </span>
    </div>
</template>

<script>
    export default {
        name: 'CreditCardInput',
        props: {
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                inVal: '',
            };
        },
        computed: {
            cardNumber() {
                return this.inVal.replace(/\s/g, '');
            },
            brand() {
                return this.getCreditCardBrand();
            },
            validLen() {
                if (this.brand === 'amex') {
                    return 15;
                } else {
                    return 16;
                }
            },
            maxCvvLen() {
                if (this.brand === 'amex') {
                    return 4;
                } else {
                    return 3;
                }
            },
            isValid() {
                return Boolean(this.brand) && Boolean(this.cardNumber.length === this.validLen);
            },
            brandSrc() {
                if (!this.brand) {
                    return null;
                }

                try {
                    return require(`../../assets/px/${this.brand}.png`);
                } catch (err) {
                    return null;
                }
            },
        },
        methods: {
            getCreditCardBrand() {
                if (/^4/.test(this.cardNumber)) {
                    return 'visa';
                }

                if (
                    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
                        this.cardNumber
                    )
                ) {
                    return 'mastercard';
                }

                if (/^3[47]/.test(this.cardNumber)) {
                    return 'amex';
                }

                if (
                    /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/.test(
                        this.cardNumber
                    )
                ) {
                    return 'discover';
                }

                if (/^36/.test(this.cardNumber)) {
                    return 'diners';
                }

                return '';
            },
            maskCardNo(cardNumber) {
                const trimmedCardNo = cardNumber.replace(/\s/g, '');
                const stdPattern = '#### #### #### ####';
                const amexPattern = '#### ###### #####';
                let pattern = stdPattern;

                if (this.brand === 'amex') {
                    pattern = amexPattern;
                }

                let i = 0;

                return pattern
                    .replace(/#/g, () => {
                        if (trimmedCardNo[i]) {
                            return trimmedCardNo[i++];
                        } else {
                            return '';
                        }
                    })
                    .trim();
            },
            clear() {
                this.inVal = '';
            },
        },
        watch: {
            inVal(newVal, oldVal) {
                if (newVal !== oldVal) {
                    const scrubbedVal = newVal.replace(/[^0-9]/g, '');
                    this.inVal = this.maskCardNo(scrubbedVal);
                    this.$emit('input', this.cardNumber);
                }
            },
            maxCvvLen(newVal) {
                this.$emit('cvv', newVal);
            },
        },
    };
</script>