<template>
    <div class="top-15">
        <div class="form-group flex bottom-20">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DateTimePickers'
    };
</script>
