<template>
    <div>
        <label :for="id">{{ label }}</label>
        <input :id="id" v-model="inVal" placeholder="CVV" @blur="$emit('blur', $event)" />
    </div>
</template>

<script>
    export default {
        name: 'CvvInput',
        props: {
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            maxLen: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                inVal: '',
            };
        },
        methods: {
            clear() {
                this.inVal = '';
            },
        },
        watch: {
            inVal(newVal, oldVal) {
                if (newVal !== oldVal) {
                    let scrubbedVal = newVal.replace(/[^0-9]/g, '');

                    if (scrubbedVal.length > this.maxLen) {
                        scrubbedVal = scrubbedVal.substring(0, this.maxLen);
                    }

                    this.inVal = scrubbedVal;
                    this.$emit('input', this.inVal);
                }
            },
            maxLen(newVal) {
                this.inVal = this.inVal.substring(0, newVal);
            },
        },
    };
</script>

<style scoped></style>
