<template>
    <div class="flex-1">
        <ejs-dropdownbutton
            id="dropdown-button"
            class="primary purple-bg actions"
            :items="actions"
            @select="handleActionSelect"
        >
            Actions
        </ejs-dropdownbutton>

        <!-- TODO: Refactor this out, it's gross -->
        <PayInvoiceModal
            v-if="payInvoiceOpen"
            :open="payInvoiceOpen"
            :clientId="client.id"
            :balance="clientOwes"
            :invoiceId="client.invoice_id || 0"
            @close="handlePayInvoiceClose"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { checkAutopay } from '@/components/calendar/AppointmentEditor/common/api';
    import { invoices, serviceItems } from '@/util/apiRequests';
    import PayInvoiceModal from '@/components/invoice/PayInvoiceModal';
    import api from '@/util/api';

    export default {
        name: 'ClientActionsDropdown',
        components: { PayInvoiceModal },
        props: {
            client: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                payInvoiceOpen: false,
                clientOwes: 0,
            };
        },
        computed: {
            ...mapState({
                userRole: (state) => state.user.role_id,
            }),
            ...mapState('eventEditor', {
                appointmentType: (state) => state.event.appt_type,
                clients: (state) => state.event.clients,
                changedClients: (state) => state.changeTracker.changed_clients,
                addedClients: (state) => state.changeTracker.added_clients,
                groupUuid: (state) => state.event.group_uuid,
                event: (state) => state.event,
            }),
            ...mapGetters('eventEditor', {
                apptItems: 'appt_items'
            }),
            dropdownDisabled() {
                return Boolean(this.changedClients?.find((c) => c.clientId === this.client.id)
                    || this.addedClients?.includes(this.client.id) || !this.client.appt_id);
            },
            progressNoteDisabled() {
                return this.userRole >= 14 && !this.client.pn_id;
            },
            removeClientDisabled() {
                return this.appointmentType === 'Individual'
                    || this.clients.length === 1
                    || this.client.invoice_id
                    || this.client.appt_status
                    || this.client.pn_id;
            },
            actions() {
                let actionButtons =  [
                    { text: 'Progress Note', disabled:  this.dropdownDisabled },
                    { separator: true },
                    { text: 'Remove Client', disabled: this.removeClientDisabled },
                ];

                if (this.client.invoice_id) {
                    actionButtons = [
                        ...actionButtons,
                        { separator: true },
                        { text: 'Pay Invoice', disabled: this.clientOwes === 0 },
                        { text: 'View Invoice' },
                    ];
                } else {
                    actionButtons = [
                        ...actionButtons,
                        { separator: true },
                        { text: 'Create Invoice', disabled: this.dropdownDisabled },
                    ];
                }
                actionButtons = [
                    ...actionButtons,
                    { separator: true },
                    { text: 'View Client', disabled: false },
                ];

                return actionButtons;
            },
            hasExistingPNote() {
                return Boolean(this.client.progress_note?.id);
            }
        },
        methods: {
            handleActionSelect(e) {
                switch (e.item.text) {
                    case 'Progress Note':
                        return this.goToPNote();
                    case 'Remove Client':
                        return this.removeClient();
                    case 'Create Invoice':
                        return this.createInvoice();
                    case 'Pay Invoice':
                        return this.payInvoice();
                    case 'View Invoice':
                        return this.goToInvoice();
                    case 'View Client':
                        return this.go2client();
                }
            },
            async handlePayInvoiceClose() {
                this.payInvoiceOpen = false;
                this.clientOwes = await this.getInvoiceBalance()
                this.$emit('refresh');
            },
            async goToPNote() {

                // await this.$store.commit('setCurrentClientId', { payload: this.client.id });
                // await this.$store.commit('progressNote/updateInvoiceId', this.client.invoice_id)
                // await this.$store.dispatch('progressNote/setApptItems', this.apptItems)
                //
                // if(this.client.invoice_id){
                //     this.$store.dispatch('progressNote/getServiceItems', {
                //         payload: {
                //             appt_id: this.client.appt_id,
                //             client_id: this.client.id
                //         }
                //     })
                // }
                //
                // if (this.hasExistingPNote) {
                //     this.$store.dispatch('progressNote/setApptItems', this.apptItems)
                //     this.$store.commit('progressNote/updateClient', { payload: this.client });
                //     this.$store.dispatch('progressNote/getServiceItems',
                //         {
                //                 appt_id: this.client.appt_id,
                //                 client_id: this.client.id
                //     })
                //     // this.$store.dispatch('progressNote/setRecId', this.client.progress_note.id)
                // }
                //
                // await this.$store.dispatch('progressNote/getApptData', {
                //     payload: {
                //         apptId: this.client.appt_id
                //     }
                // });
                //
                // await this.$store.dispatch('progressNote/getCancelNoShowServiceCodes')

                await this.$router.push({
                    name: 'ProgressNote',
                    params: {
                        id: this.hasExistingPNote ? this.client.progress_note?.id : 0,
                        apptGroupUuid: this.groupUuid,
                    },
                    query: {
                        client_id: this.client.id,
                        appt_id: this.client.appt_id,
                    }
                });


                // await this.$store.commit('setCurrentClientId', { payload: this.client.id });
                // await this.$store.commit('progressNote/updateInvoiceId', this.client.invoice_id)
                // await this.$store.dispatch('progressNote/setApptItems', this.apptItems)

                // if(this.client.invoice_id){
                //     // this.$store.dispatch('progressNote/getServiceItems', {
                //     //     payload: {
                //     //         appt_id: this.client.appt_id,
                //     //         client_id: this.client.id
                //     //     }
                //     // })
                // }

                // if (this.hasExistingPNote) {
                //     // this.$store.dispatch('progressNote/setApptItems', this.apptItems)
                //     // this.$store.commit('progressNote/updateClient', { payload: this.client });
                //     // this.$store.dispatch('progressNote/getServiceItems',
                //     //     {
                //     //             appt_id: this.client.appt_id,
                //     //             client_id: this.client.id
                //     // })
                //
                //     // this.$store.dispatch('progressNote/setRecId', this.client.progress_note.id)
                // }

                // await this.$store.dispatch('progressNote/getApptData', {
                //     payload: {
                //         apptId: this.client.appt_id
                //     }
                // });
                //
                // await this.$store.dispatch('progressNote/getCancelNoShowServiceCodes')

                // await this.$router.push({
                //     name: 'ProgressNote',
                //     params: {
                //         id: this.hasExistingPNote ? this.client.progress_note?.id : 0,
                //         apptGroupUuid: this.groupUuid,
                //     },
                //     query: {
                //         // client_id: this.client.id,
                //         appt_id: this.client.appt_id,
                //     }
                // });
            },
            async removeClient() {
                if (!this.client.appt_id) {
                    await this.$store.dispatch('eventEditor/removeClient', this.client);
                } else {
                    this.$store.commit('eventEditor/openClientModal', {
                        name: 'deleteClient',
                        client: this.client,
                    });
                }
            },
            async getPNotesServices(){
                let res = {};
                try {
                    res = await api.get(serviceItems.getServiceItemsByAppt(this.client.appt_id, this.client.id));
                }catch(err){
                    res.data = [];
                    return res;
                }
                return res.data;
            },
            async createInvoice() {
                const clientIsAutopay = await checkAutopay(this.client.id);
                const progressNoteServices = await this.getPNotesServices();
                if (clientIsAutopay) {
                    this.$store.commit('eventEditor/openClientModal', {
                        name: 'autopayWarning',
                        client: this.client,
                    });
                } else {
                    try {
                        await this.$api.post(invoices.createInvoices(), {
                            autopay: false,
                            apptGroupUuid: this.groupUuid,
                            invoices: [{
                                invoice: {
                                    office_id: this.event.office_id,
                                    company_id: this.event.company_id,
                                    client_id: this.client.id,

                                },
                                progressNote: {
                                    id: this.client.progress_note.id,
                                    apptId: this.client.appt_id,
                                    services: progressNoteServices
                                },
                                services: this.client.services.map((s) => s.serviceCodeId),
                            }],
                        });

                        this.$toasted.success(`An invoice has been created for ${this.client.first_name} ${this.client.last_name}`);
                        this.$emit('refresh');
                    } catch (err) {
                        console.error(err);
                        this.$toasted.error('Failed to generate invoice - please try again later.');
                    }
                }
            },
            async payInvoice() {
                // TODO: Refactor this to use an improved modal
                this.clientOwes = await this.getInvoiceBalance();
                this.payInvoiceOpen = true;
            },
            async goToInvoice() {
                await this.$router.push(`/invoices/${this.client.invoice_id}`);
            },
            async go2client() {
                await this.$router.push(`/client/${this.client.id}`);
            },
            async getInvoiceBalance() {
                try {
                    const res = await this.$api.get(invoices.getInvoice(this.client.invoice_id));
                    return res.data.amount_owed;
                } catch (err) {
                    this.$toasted.error('Failed to get member balance');
                }
            }
        },
        async created() {
            if (this.client.invoice_id) {
                this.clientOwes = await this.getInvoiceBalance();
            } else {
                this.clientOwes = 0;
            }
        },
        watch: {
            async 'client.invoice_id'() {
                this.clientOwes = await this.getInvoiceBalance();
            }
        }
    };
</script>