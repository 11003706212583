<template>
  <div class="modal__backdrop" :class="{ modal__closed: !open, modal__open: open }" @click.self="$emit('close')">
    <div class="modal__content group-1-client-warning">
      <div class="flex center">
        <div class="right-20">
          <span class="material-icons-outlined orange large-icon">help_outline</span>
        </div>
        <div>
          The appointment type selected is <b>{{type}}</b>, but only one member has
          been selected. Would you like to continue and create this appointment anyway?
        </div>
      </div>
      <div class="align-right top-20">
        <button class="secondary cancel bottom-0" type='button' @click="$emit('closeModal')"> Cancel </button>
        <button class="primary bottom-0" type='button' @click="$emit('createGrpAppt')"> Create Appointment</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GrpApptWarning',
  props: {
    open: Boolean,
    type: {
        type: String,
        default: 'Group'
    }

  },
  data(){
    return {
      escListener: null,
    };
  },
  methods: {
    createEscListener(self) {
      return function (e) {
        if (e.key === 'Escape' && self.open) {
          self.$emit('closeDelete');
        }
      };
    },
  },
  created() {
    this.escListener = this.createEscListener(this);

    window.addEventListener('keydown', this.escListener);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.escListener);
  },

}
</script>
