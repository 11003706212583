<template>
    <div>
        <input class="d-none"
            :name="name"
            type="checkbox"
            :id="id"
            :disabled="disabled"
            :checked="value"
            :value="value"
            @input="$emit('input', $event.target.checked)"
        />
        <label :for="id" class="appointment-type-card" :class="{'disabled': disabled}" 
        @mouseover="hovered = true"
        @mouseout="hovered = false">
            <div class="d-flex flex-column align-items-center">
                <slot :hoverOrSelected="(hovered || value)"/>
                <span class="fw-600">{{ label }}</span>
            </div>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'AppointmentTypeCard',
        props: {
            id: String,
            name: String,
            value: Boolean,
            label: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            hovered: false
        }),
        methods: {},
    };
</script>

<style scoped>
    label {
        font-size: inherit !important;
    }
    
    label.disabled {
        cursor: not-allowed;
    }
    .appointment-type-card {
        border-radius: 8px;
        background: #BCC0FF;
        padding: 1rem 2rem;
        width: 5.1em;
        cursor: pointer;
        color: #5057C3;
        margin-right: .6em;
    }
    .material-symbols-outlined {
        font-size: 25px;
    }
 
    .appointment-type-card:hover {
        background: #5057C3;
        color: #ffffff;
    }

    input[type=checkbox]:checked + .appointment-type-card { 
        background: #5057C3;
        color: #ffffff;
    }
</style>
