<template>
    <div class="attendance flex-1">
        <!-- <label
            class="label-attendance flex-1"
            for="appt-attendance"
        >
            Attendance
        </label> -->
        <select
            id="appt-attendance"
            class="fullwidth attendance-select"
            :value="client.appt_status"
            :disabled="attendanceDisabled"
            @change.prevent="handleAttendanceChange"
        >
            <option disabled :value="AttendanceStatus.None"></option>
            <option
                :value="AttendanceStatus.Attended"
                :disabled="attendedStatusDisabled"
            >
                {{ AttendanceStatus.Attended }}
            </option>
            <option :value="AttendanceStatus.Cancelled">
                {{ AttendanceStatus.Cancelled }}
            </option>
            <option :value="AttendanceStatus.NoShow">
                {{ AttendanceStatus.NoShow }}
            </option>
          <option :value="AttendanceStatus.CancelledFeeWaived">
                {{ AttendanceStatus.CancelledFeeWaived }}
            </option>
            <option :value="AttendanceStatus.NoShowFeeWaived">
                {{ AttendanceStatus.NoShowFeeWaived }}
            </option>
        </select>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { AttendanceStatus } from '@/components/calendar/AppointmentEditor/common/constants';
    import { appts } from '@/util/apiRequests';

    const noShowApptMessage =
        'Updating the attendance status to “No Show” will remove original service fees from the invoice and add a no show fee to the invoice. Are you sure you want to update?';
    const cancelApptMessage =
        'Updating the attendance status to “Cancelled” will remove original service fees from the invoice and add a cancellation fee to the invoice. Are you sure you want to update?';
    const noShowWaivedApptMessage =
        'Updating the attendance status to “No Show - Excused” will remove original service fees from the invoice. Are you sure you want to update?';
    const cancelWaivedApptMessage =
        'Updating the attendance status to “Cancelled - Excused” will remove original service fees from the invoice. Are you sure you want to update?';


    export default {
        name: 'AttendanceDropdown',
        props: {
            client: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                AttendanceStatus,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                currentAttendance: (state) => state.originalEvent.appt_status,
                clientsList: (state) => state.globalLists.clients,
                addedClients: (state) => state.changeTracker.added_clients,
                changedClients: (state) => state.changeTracker.changed_clients,
                event: (state) => state.event,
                autoGenerateInvoices: (state) => state.globalAttrs.autoGenerateInvoices,
            }),
            attendanceDisabled() {
                return Boolean(this.changedClients?.find((c) => c.clientId === this.client.id)
                    || this.addedClients?.includes(this.client.id));
            },
            attendedStatusDisabled() {
                return this.client.appt_status === AttendanceStatus.NoShow
                    || this.client.appt_status === AttendanceStatus.Cancelled
                    || this.client.appt_status === AttendanceStatus.CancelledFeeWaived
                    || this.client.appt_status === AttendanceStatus.NoShowFeeWaived;
            },
        },
        methods: {
            handleAttendanceChange(e) {
                if (e.target.value === AttendanceStatus.Attended && this.autoGenerateInvoices && !this.client.invoice_id) {
                    return this.$store.commit('eventEditor/openClientModal', {
                        name: 'autoGenInvoice',
                        selectedClient: this.client,
                        previousAttendance: this.currentAttendance,
                        nextAttendance: e.target.value,
                    });
                }

                if (e.target.value === AttendanceStatus.Attended) {
                    return this.updateAttendance(e.target.value);
                }

                let message
                switch(e.target.value) {
                    case AttendanceStatus.NoShow:
                        message =  noShowApptMessage;
                        break
                    case AttendanceStatus.Cancelled:
                        message =  cancelApptMessage;
                        break
                    case AttendanceStatus.NoShowFeeWaived:
                        message =  noShowWaivedApptMessage;
                        break
                    case AttendanceStatus.CancelledFeeWaived:
                        message =  cancelWaivedApptMessage;
                        break
                    default:
                        return null;
                }

                this.$store.commit('eventEditor/openClientModal', {
                    name: 'attendanceWarning',
                    message,
                    client: this.client,
                    previousAttendance: this.client.appt_status,
                    nextAttendance: e.target.value,
                });
            },
            resetAttendance() {
                this.$store.dispatch('eventEditor/resetAttendance', {
                    clientId: this.client.id,
                    previousAttendance: this.client.appt_status
                });
            },
            async updateAttendance(status) {
                try {
                    const res = await this.$api.patch(appts.updateApptStatus(this.client.appt_id), {
                        status,
                        addCancelFee: false,
                        pageId: this.pageMeta.id,
                        generateInvoice: false,
                        paymentDetail: {},
                    });

                    if (res.status >= 300 || res.status < 200) {
                        return this.resetAttendance();
                    }

                    if (this.autoGenerateInvoices && this.clientHasInvoice) {
                        this.$toasted.error('Invoice has already been generated for this appointment.');
                    } else {
                        this.$toasted.success('Updated appointment status');
                    }

                    await this.$store.dispatch('eventEditor/updateAttendance', {
                        clientId: this.client.id,
                        nextAttendance: status,
                    });

                    this.$emit('refresh');
                } catch (err) {
                    return this.resetAttendance();
                }
            }
        }
    };
</script>
<style scoped>
.attendance-select{
    min-width: 12rem!important;
}
    .label-attendance {
        font-size: 1.1em;
        font-weight: 600;
        margin-top: -8px;
        margin-bottom: 8px;
        color: #7D7F81;
    }
</style>
