<template>
    <div class="right-10">
        <h5 class="appt-section-title">{{ labelText }}*</h5>

        <Dropdown
            v-if="eventType === 'EVENT'"
            name="room"
            id="room"
            label=""
            :value="location"
            :options="roomOptions"
            @input="handleRoomChange"
        />
        <div v-else class="row ms-0">
            <div class="col-sm-3 calendar-search-card d-flex justify-content-between align-items-center me-3 py-2">
                <span class="calendar-filter-view-label">Telehealth</span>
                <Toggle
                    id="telehealth"
                    class="toggle-switch"
                    v-model="placeOfServiceType.telehealth"
                    labelClass="switch"
                    spanClass="slider"
                    @input="(value) => handlePlaceOfServiceTypeChange(value, 'TELEHEALTH')"
                />
            </div>
            <div class="col-sm-3 calendar-search-card d-flex justify-content-between align-items-center py-2">
                <span class="calendar-filter-view-label">In office</span>
                <Toggle
                    id="inOffice"
                    class="toggle-switch"
                    v-model="placeOfServiceType.inOffice"
                    labelClass="switch"
                    spanClass="slider"
                    @input="(value) => handlePlaceOfServiceTypeChange(value, 'IN_OFFICE')"
                />
            </div>
        </div>

        <!-- <Dropdown
            v-else
            name="room"
            id="room"
            label=""
            :value="placeOfService"
            :options="placeOfServiceOptions"
            @input="handleRoomChange"
        /> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    const placesOfService = [
        { code: '02', name: 'Telehealth' },
        { code: '11', name: 'Office' },
    ];

    export default {
        name: 'LocationDropdown',
        data: () => ({
            placeOfServiceType: {
                telehealth: false,
                inOffice: false
            },
        }),
        computed: {
            ...mapState('eventEditor', {
                eventType: (state) => state.event.type,
                location: (state) => state.event.location,
                placeOfService: (state) => state.event.place_of_service_code,
                rooms: (state) => state.globalLists.rooms,
            }),
            labelText() {
                return this.eventType === 'EVENT' ? 'Room' : 'Place of Service';
            },
            roomOptions() {
                return this.rooms
                    .filter((r) => r.active === 1)
                    .map((r) => ({
                        text: r.room_name === 'Office' ? `11 - ${r.room_name}` : `02 - ${r.room_name}`,
                        value: r.id,
                    }));
            },
            placeOfServiceOptions() {
                return placesOfService.map((p) => ({
                    text: `${p.code} - ${p.name}`,
                    value: p.code,
                }));
            },
        },
        methods: {
            handleRoomChange(e) {
                this.$store.dispatch('eventEditor/updateLocation', e);
            },

            handlePlaceOfServiceTypeChange(value, type) {
                switch (type) {
                    case 'TELEHEALTH':
                        if (value) {
                            this.placeOfServiceType.allType = false;
                            this.placeOfServiceType.inOffice = false;
                            this.handleRoomChange('02');
                        } else {
                            this.handleRoomChange(null);
                        }
                        break;
                    case 'IN_OFFICE':
                        if (value) {
                            this.placeOfServiceType.allType = false;
                            this.placeOfServiceType.telehealth = false;
                            this.handleRoomChange('11');
                        } else {
                            this.handleRoomChange(null);
                        }
                        break;
                }
            },
            populatePlaceOfService() {
                if (this.placeOfService === '02') {
                    this.placeOfServiceType.telehealth = true;
                } else if (this.placeOfService === '11') {
                    this.placeOfServiceType.inOffice = true;
                } else {
                    this.placeOfServiceType.telehealth = false;
                    this.placeOfServiceType.inOffice = false;
                }
            }
        },
        created() {
            this.populatePlaceOfService();
        }
    };
</script>

<style scoped>

</style>