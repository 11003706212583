<template>
    <div class="modal__backdrop" :class="{ modal__closed: !open, modal__open: open }" @click.self="$emit('close')">
        <div v-if="canDeleteAppt && isRecurring" class="modal__content">
            <h1>How would you like to save these changes?</h1>
            <div class="flex flex-column">
                <label class="flex center bottom-10">
                    <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.OnlyCurrent" />
                    <span class="left-10">Save changes for this appointment only</span>
                </label>

                <label class="flex center bottom-10">
                    <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.AllFuture" id="allFuture" />
                    <span class="left-10">Save changes for this appointment and the following ones in this series</span>
                </label>
            </div>
            <div class="align-right pad-10">
                <button type="button" class="secondary bottom-0" @click="$emit('closeModal')">Cancel</button>
                <button type="button" class="primary bottom-0" @click="$emit('deleteTheAppt')">Save</button>
            </div>
        </div>

        <div v-else-if="canDeleteAppt && !isRecurring" class="modal__content">
            <div class="flex">
                <div class="right-20">
                    <span class="material-icons-outlined red-text large-icon">error_outline</span>
                </div>
                <div>
                    <h2 class="bottom-5">Delete {{ calendarType }}?</h2>
                    <p>
                        Are you sure you want to delete this {{ calendarType.toLowerCase() }}? Once an appointment is
                        deleted, this action cannot be undone.
                    </p>
                </div>
            </div>
            <div class="align-right top-20">
                <button class="secondary cancel bottom-0" type="button" @click="$emit('closeModal')">Cancel</button>
                <button class="primary bottom-0" type="button" @click="$emit('deleteTheAppt')">
                    Delete {{ calendarType }}
                </button>
            </div>
        </div>
        <div v-if="!canDeleteAppt" class="modal__content">
            <div class="flex">
                <div class="right-20">
                    <span class="material-icons-outlined red-text large-icon">error_outline</span>
                </div>
                <div>
                    <p>
                        Unable to delete appointment where there are list of actions relevant to this apt. that prohibit
                        deletion: Progress notes signed, member has attended, or invoice has been generated.
                    </p>
                </div>
            </div>
            <div class="align-right top-20">
                <button class="secondary cancel bottom-0" type="button" @click="$emit('closeModal')">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
    import RecurrenceSaveType from '@/util/recurrenceSaveType';

    export default {
        name: 'DeleteApptModal',
        props: {
            open: Boolean,
            type: String,
            canDeleteAppt: Boolean,
            isRecurring: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                escListener: null,
                RecurrenceSaveType,
                saveChoice: RecurrenceSaveType.OnlyCurrent
            };
        },
        computed: {
            calendarType() {
                if (this.type == 'APPOINTMENT') {
                    return 'Appointment';
                } else return 'Event';
            }
        },
        methods: {
            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        self.$emit('closeDelete');
                    }
                };
            }
        },
        created() {
            this.escListener = this.createEscListener(this);

            window.addEventListener('keydown', this.escListener);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        }
    };
</script>
