<template>
    <div class="modal__backdrop" :class="{ modal__closed: !open, modal__open: open }" @click.self="$emit('closeModal')">
        <div class="modal__content">
            <div class="flex">
                <div class="right-20">
                    <span class="material-icons-outlined red-text large-icon">error_outline</span>
                </div>
                <div>
                    <h2 class="bottom-5">Delete Client</h2>
                    <p>Are you sure you want to delete this client?</p>
                </div>
            </div>
            <div class="align-right top-20">
                <button class="secondary cancel" type="button" @click="$emit('closeModal')">Cancel</button>
                <button class="primary" type="button" @click="$emit('deleteClient', client)">Delete Client</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DeleteClientModal',
        props: {
            open: {
                type: Boolean,
                required: true
            },
            client: {
                type: Object
            }
        },
        data() {
            return {
                escListener: null
            };
        },
        methods: {
            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        self.$emit('closeDelete');
                    }
                };
            }
        },
        created() {
            this.escListener = this.createEscListener(this);

            window.addEventListener('keydown', this.escListener);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        }
    };
</script>
