<template>
    <div class="flex space-between">
        <button
            type="button"
            name="addUserBtn"
            id="addUserBtn"
            class="primary btn-add-user p-2"
            @click="handleClick"
        >
            <span class="material-icons icon-add-user">add_box</span> Add User
        </button>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'AddUserButton',
        computed: {
            ...mapState('eventEditor', {
                eventUsers: (state) => state.event.users.users,
            }),
        },
        methods: {
            handleClick() {
                this.$store.dispatch('eventEditor/updateUsers', [
                    ...this.eventUsers,
                    { id: null, name: null },
                ]);
            },
        },
    };
</script>
<style scoped>
    .icon-add-user {
        font-size: 17px !important;
    }
    .btn-add-user {
        font-size: .95em;
        font-weight: 600;
    }
</style>
