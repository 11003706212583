<template>
    <Modal @close="handleCancel">
        <h1>Date Changed</h1>
        <p>
            This appointment is part of a recurrence. Date change will be applied to this appointment only. The remaining appointments for this recurrence will be unaffected.
        </p>
        <div class="align-right pad-10">
            <button type="button" class="secondary bottom-0" @click="handleCancel">Cancel</button>
            <button type="button" class="primary bottom-0" @click="handleConfirm">Save</button>
        </div>
    </Modal>
</template>

<script>
    import { mapState } from 'vuex';
    import Modal from '@/components/general/modals/Modal.vue';
    import { saveAppointment } from '@/components/calendar/AppointmentEditor/common/api';

    export default {
        name: 'ConfirmDateChangeModal',
        components: {
            Modal
        },
        computed: {
            ...mapState('eventEditor', {
                event: (state) => state.event,
                changeTracker: (state) => state.changeTracker,
            }),
        },
        methods: {
            handleCancel() {
                this.$store.commit('eventEditor/closeModal', 'confirmDateChange');
            },
            async handleConfirm() {
                const statusCode = await saveAppointment(this.event, this.changeTracker);

                if (statusCode < 300) {
                    this.$store.commit('eventEditor/closeModal', 'confirmDateChange');
                }
            },
        }
    };
</script>
