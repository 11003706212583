<template>
    <!--         LOCATION       -->
    <div class="locationAndApptType">
        <div class="flex center">
            <div class="flex-1 right-15">
                <h5 class="e-textlabel">Place of Service </h5>
                <select v-model="selectedPlaceOfService" @change="handleSelectedPlaceOfService" required>
                    <option :value="place.code" v-for="place in placesOfService" :key="place.code">
                        {{place.code}} - {{place.name }}
                    </option>
                </select>
            </div>

            <!--         APPOINTMENT TYPE      -->
            <div class="flex-1">
                <h5 class="e-textlabel">Appointment Type</h5>
                <select v-model="eventType" id="apptType" @change="handleEventType" required>
                    <option v-for="option in apptType" v-bind:key="option.id">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LocationApptType',
        props: {
            modalOpen: Boolean,
            typeofAppt: { required: false },
            rooms: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                apptType: ['Individual', 'Group', 'Couple', 'Family', 'Other'],
                eventType: null,
                room: null,
                selectedPlaceOfService: null,
                placesOfService: [
                    {
                        code: '02',
                        name: 'Telehealth'
                    },
                    {
                        code: '11',
                        name: 'Office'
                    },

                ]
            }
        },
        methods: {

            handleSelectedPlaceOfService(e) {
                this.$emit('selectedPlaceOfService', e.target.value);
            },
            handleEventType(e) {
                this.$emit('setApptType', e.target.value);
            },
        },

        watch: {
            modalOpen() {
                //clears the appointment location & appointment type everytime the modal closes
                if (this.modalOpen == false) {
                    this.room = null;
                    this.selectedPlaceOfService = null
                    this.eventType = null;
                }
            },
            eventType() {
                if (this.eventType !== 'Individual') {
                    this.$emit('getAllServices');
                    this.clients = Array.isArray(this.clients) ? this.clients : [this.clients];
                } else {
                    this.clients = Array.isArray(this.clients) ? this.clients[0] : this.clients;
                }
            },
        },

        created() {
            if (this.typeofAppt !== null) {
                this.eventType = this.typeofAppt;
            }
        },
    };
</script>

<style scoped></style>
