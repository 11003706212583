<template>
    <NoButtonModal :open="open" @close="$emit('close', $event)">
        <div class="flex">
            <div class="right-20">
                <span class="material-icons-outlined red-text large-icon">error_outline</span>
            </div>
            <div>
                <h2 class="bottom-5">Warning</h2>
                <p>{{ message }}</p>
            </div>
        </div>
        <div class="align-right top-20">
            <button class="secondary cancel" @click="$emit('close', $event)">Cancel</button>
            <button class="primary" @click="submitChanges">Accept</button>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { appts } from '@/util/apiRequests';
    export default {
        name: 'AttendanceWarningModal',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            message: {
                type: String,
                required: true,
            },
            payload: {
                type: Object,
                required: true,
            },
        },
        methods: {
            async submitChanges() {
                try {
                    const res = await this.$api.patch(appts.updateApptStatus(this.payload.client.apptId), {
                        status: this.payload.status,
                        addCancelFee: true,
                        pageId: this.pageMeta.id
                    });

                    if (res.status < 200 || res.status >= 300) {
                        //this.$toasted.error('Failed to update status');
                        return;
                    }
                    // this.$toasted.success('Updated appointment status');
                    this.$emit('attendanceUpdated');
                } catch (err) {
                    //this.$toasted.error('Failed to update status');
                }
            },
        },
    };
</script>

<style scoped></style>
