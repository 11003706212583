<template>
    <Modal @close="handleClose">
        <div class="flex">
            <div class="right-20">
                <span class="material-icons-outlined red-text large-icon">error_outline</span>
            </div>
            <div>
                <h2 class="bottom-5">Warning</h2>
                <p>{{ message }}</p>
                <p>{{ client.first_name }} has enabled autopay on one of their stored debit/credit cards. Creating this invoice will automatically charge their stored card.</p>
            </div>
        </div>
        <div class="align-right top-20">
            <button
                class="secondary cancel"
                @click="handleCancel"
            >
                Cancel
            </button>
            <button
                class="primary"
                @click="handleAccept"
            >
                Accept
            </button>
        </div>
    </Modal>
</template>

<script>
    import { mapState } from 'vuex';
    import Modal from '@/components/general/modals/Modal';
    import { appts } from '@/util/apiRequests';
    import { checkAutopay } from '@/components/calendar/AppointmentEditor/common/api';

    export default {
        name: 'AttendanceWarningModal',
        components: { Modal },
        data() {
            return {
                autopayEnabled: false,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                message: (state) => state.modals.attendanceWarning.message,
                client: (state) => state.modals.attendanceWarning.client,
                previousAttendance: (state) => state.modals.attendanceWarning.previousAttendance,
                nextAttendance: (state) => state.modals.attendanceWarning.nextAttendance,
            }),
        },
        methods: {
            handleClose() {
                this.$store.commit('eventEditor/closeModal', 'attendanceWarning');
            },
            handleCancel() {
                this.$store.dispatch('eventEditor/resetAttendance', {
                    clientId: this.client.id,
                    previousAttendance: this.previousAttendance,
                });

                this.handleClose();
            },
            async handleAccept() {
                const res = await this.$api.patch(appts.updateApptStatus(this.client.appt_id), {
                    status: this.nextAttendance,
                    addCancelFee: true,
                    pageId: this.pageMeta.id,
                });

                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error('Failed to update status');
                    return this.handleCancel();
                }

                await this.$store.dispatch('eventEditor/updateAttendance', {
                    clientId: this.client.id,
                    nextAttendance: this.nextAttendance,
                });

                this.$toasted.success('Attendance updated');
                this.$emit('refresh');
                this.handleClose();
            },
            async checkAutopay() {
                return checkAutopay(this.client.id);
            },
        },
        async created() {
            this.autopayEnabled = await this.checkAutopay();
        },
    };
</script>
