<template>
    <button v-if="showButton" class="primary top-0 bottom-20 fw-600" @click.prevent="handleClick">
        {{ buttonText}}
    </button>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'RRuleButton',
        computed: {
            ...mapState('eventEditor', {
                apptIsRecurring: (state) => state.event.recurrence_rule,
                recurringLocked: (state) => state.event.recurring_locked,
                eventType: (state) => state.event.type,
            }),
            ...mapGetters('eventEditor', {
                dateHasChanged: 'dateHasChanged',
            }),
            showButton() {
                return this.eventType !== 'EVENT' && !this.recurringLocked && !this.dateHasChanged;
            },
            buttonText() {
                return this.apptIsRecurring ? 'Edit Recurrence Rules' : 'Add Recurring Appointments';
            },
        },
        methods: {
            handleClick() {
                this.$store.commit('eventEditor/openModal', 'editRRule');
            },
        },
    };
</script>
