<template>
    <NoButtonModal :open="open" @close="$emit('cancel')">
        <div v-if="canDelete">
            <div v-if="isRecurring">
                <div class="bottom-10">
                    <h1 class="bottom-0">How would you like to delete this event?</h1>
                    <span class="dark-red bold">Once event has been deleted, this action cannot be undone.</span>
                </div>

                <div class="flex flex-column">
                    <label class="flex center bottom-10">
                        <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.OnlyCurrent" />
                        <span class="left-10">Delete this appointment only</span>
                    </label>

                    <label class="flex center bottom-10">
                        <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.AllFuture" id="allFuture" />
                        <span class="left-10">
                            Delete this appointment and the following ones in this series
                        </span>
                    </label>
                </div>
                <div class="align-right">
                    <button type="button" class="secondary bottom-0" @click="$emit('cancel')">Cancel</button>
                    <button type="button" class="primary bottom-0" @click="$emit('delete', saveChoice)">Save</button>
                </div>
            </div>

            <div v-else class="modal__content">
                <div class="flex">
                    <div class="right-20">
                        <span class="material-icons-outlined red-text large-icon">error_outline</span>
                    </div>
                    <div>
                        <h2 class="bottom-5">Delete {{ calendarType }}?</h2>
                        <p>
                            Are you sure you want to delete this {{ calendarType.toLowerCase() }}? Once an event is
                            deleted, this action cannot be undone.
                        </p>
                    </div>
                </div>
                <div class="align-right top-20">
                    <button class="secondary cancel bottom-0" type="button" @click="$emit('cancel')">
                        Cancel
                    </button>
                    <button
                        class="primary bottom-0"
                        type="button"
                        @click="$emit('delete', RecurrenceSaveType.OnlyCurrent)"
                    >
                        Delete {{ calendarType }}
                    </button>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="flex">
                <div class="right-20">
                    <span class="material-icons-outlined red-text large-icon">error_outline</span>
                </div>
                <div>
                    <p>
                        Unable to delete appointment where there are list of actions relevant to this apt. that prohibit
                        deletion: Progress notes signed, member has attended, or invoice has been generated.
                    </p>
                </div>
            </div>
            <div class="align-right top-20">
                <button class="secondary cancel bottom-0" type="button" @click="$emit('cancel')">OK</button>
            </div>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import RecurrenceSaveType from '@/util/recurrenceSaveType';
    export default {
        name: 'DeleteEventModal',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true
            },
            canDelete: {
                type: Boolean,
                required: true
            },
            isRecurring: {
                type: Boolean,
                required: true
            },
            type: {
                type: String,
                default: 'APPOINTMENT'
            }
        },
        data() {
            return {
                saveChoice: RecurrenceSaveType.OnlyCurrent,
                RecurrenceSaveType
            };
        },
        computed: {
            calendarType() {
                return this.type === 'APPOINTMENT' ? 'Appointment' : 'Event';
            }
        }
    };
</script>

<style scoped></style>
