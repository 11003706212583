<template>
    <div>
        <label :for="id">{{ label }}</label>
        <input :id="id" v-model="inVal" placeholder="MM/YYYY" @keydown="handleKeydown" @blur="$emit('blur', $event)" />
    </div>
</template>

<script>
    export default {
        name: 'ExpirationInput',
        props: {
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                inVal: '',
            };
        },
        computed: {
            month() {
                let monthStr = this.inVal.split('/')[0];

                if (!monthStr) {
                    return null;
                }

                return parseInt(monthStr);
            },
            year() {
                let yearStr = this.inVal.split('/')[1];

                if (!yearStr || yearStr.length < 4) {
                    return null;
                }

                return parseInt(yearStr);
            },
            isValid() {
                const currentYear = new Date().getFullYear();
                const currentMonth = new Date().getMonth() + 1;

                if (this.year < currentYear) {
                    return false;
                }

                if (this.year === currentYear && this.month < currentMonth) {
                    return false;
                }

                if (this.month > 12 || this.month < 1) {
                    return false;
                }

                return true;
            },
        },
        methods: {
            handleKeydown(e) {
                if (e.key === '/') {
                    e.preventDefault();
                    this.formatMonthOnSlashPressed();
                }
            },
            formatMonthOnSlashPressed() {
                if (this.inVal.length === 1 && this.inVal !== '0') {
                    this.inVal = this.inVal.padStart(2, '0');
                }
            },
            maskDate(date) {
                const rawDate = date.replace(/[/\s]/g, '');

                const singleDigitPattern = '#';
                const twoDigitPattern = '##/';
                const fullDatePattern = '##/####';

                let datePattern = singleDigitPattern;

                if (rawDate.length === 2) {
                    datePattern = twoDigitPattern;
                }

                if (rawDate.length > 2) {
                    datePattern = fullDatePattern;
                }

                let i = 0;

                return datePattern
                    .replace(/#/g, () => {
                        if (rawDate[i]) {
                            return rawDate[i++];
                        } else {
                            return '';
                        }
                    })
                    .trim();
            },
            clear() {
                this.inVal = '';
            },
        },
        watch: {
            inVal(newVal, oldVal) {
                if (newVal !== oldVal) {
                    const scrubbedVal = newVal.replace(/[^0-9/]/g, '');

                    if (!newVal.includes('/') && oldVal.includes('/')) {
                        this.inVal = this.maskDate(scrubbedVal.substring(0, scrubbedVal.length - 1));
                    } else {
                        this.inVal = this.maskDate(scrubbedVal);
                    }

                    this.$emit('input', { month: this.month, year: this.year });
                }
            },
        },
    };
</script>

<style scoped></style>
