<template>
    <Modal @close="handleClose">
        <h1>Autopay</h1>
        <p>
            {{ name }} has enabled autopay on one of their stored debit/credit cards. Creating an invoice will
            automatically charge their stored card. Are you sure you would like to proceed with creating this
            invoice?
        </p>
        <div class="align-right">
            <button class="secondary" type="button" @click="handleClose">Cancel</button>
            <button class="primary" type="button" @click="handleConfirm" :disabled='invoicing' :class='{"disabled-cursor": invoicing}'>{{ invoicing ? 'Invoicing...' : 'Confirm'}}</button>
        </div>
    </Modal>
</template>

<script>
    import { mapState } from 'vuex';
    import Modal from '@/components/general/modals/Modal';
    import { invoices } from '@/util/apiRequests';

    export default {
        name: 'AutopayWarningModal',
        components: { Modal },
        data() {
            return {
                invoicing: false,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                client: (state) => state.modals.autopayWarning.client,
                event: (state) => state.event,
                groupUuid: (state) => state.event.group_uuid,
            }),
            name() {
                return `${this.client.first_name} ${this.client.last_name}`;
            }
        },
        methods: {
            handleClose() {
                this.$store.commit('eventEditor/closeModal', 'autopayWarning');
            },
            async handleConfirm() {
                try {
                    this.invoicing = true;
                    await this.$api.post(invoices.createInvoices(), {
                        autopay: true,
                        apptGroupUuid: this.groupUuid,
                        invoices: [{
                            invoice: {
                                office_id: this.event.office_id,
                                company_id: this.event.company_id,
                                client_id: this.client.id,
                            },
                            progressNote: {
                                id: this.client.pn_id,
                                apptId: this.client.appt_id,
                            },
                            services: this.client.services.map((s) => s.serviceCodeId),
                        }],
                    });

                    this.$toasted.success(`An invoice has been created for ${this.client.first_name} ${this.client.last_name}`);
                    this.$emit('refresh');
                    this.handleClose();
                } catch (err) {
                    this.$toasted.error('Failed to generate invoice - please try again later.');
                }
                finally {
                    this.invoicing = false;
                }
            }
        }
    };
</script>

<style scoped>

</style>