<template>
    <div class="input-btn-row flex center bottom-10">
        <Multiselect
            :options="counselors"
            :allowEmpty="false"
            :searchable="true"
            :showLabels="false"
            label="name"
            trackBy="id"
            :value="counselor"
            @select="handleCounselorSelected"
        />

        <ejs-tooltip
            class="tooltipcontainer"
            content="Delete"
            target="#target"
            cssClass="customtooltip"
        >
            <span v-if="removeCounselorEnabled" id="target" class="material-symbols-outlined icon-delete-prescription ms-2" @click="handleRemoveCounselor">delete</span>
        </ejs-tooltip>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'CounselorRow',
        props: {
            counselor: {
                type: Object,
                required: true,
            }
        },
        computed: {
            ...mapState('eventEditor', {
                eventCounselors: (state) => state.event.counselors,
            }),
            ...mapGetters('eventEditor', {
                counselors: 'globalCounselorsWithDisabled',
            }),
            removeCounselorEnabled() {
                return this.eventCounselors.length > 1;
            }
        },
        methods: {
            handleCounselorSelected(e) {
                this.$store.dispatch('eventEditor/updateCounselors', {
                    currentId: this.counselor.id,
                    nextId: e.id,
                });
            },
            handleRemoveCounselor() {
                this.$store.dispatch('eventEditor/removeCounselor', this.counselor.id);
            },
        }
    };
</script>
