<template>
    <div class="d-flex justify-content-center" v-if="eventHasChanges">
        <button
            type="button"
            class="primary fw-600"
            @click="handleSaveChanges"
        >
            Save Changes
        </button>
        <Loading v-if="showLoader" />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { saveAppointment, saveEvent } from '@/components/calendar/AppointmentEditor/common/api';
    import Loading from '@/components/general/loading/loading.vue';

    export default {
        name: 'SaveChangesButton',
        components: {
            Loading
        },
        data() {
            return {
                showLoader: false
            }
        },
        computed: {
            ...mapGetters('eventEditor', {
                eventHasChanges: 'eventHasChanges',
                dateHasChanged: 'dateHasChanged',
            }),
            ...mapState('eventEditor', {
                event: (state) => state.event,
                eventType: (state) => state.event.type,
                changeTracker: (state) => state.changeTracker,
            }),
        },
        methods: {
            validateUpdate() {
                if (!this.event.title) {
                    this.$toasted.error(`Please add appointment title`);
                    return false;
                }

                if (!this.event.dayt_appt_start || this.event.dayt_appt_start == 'Invalid Date') {
                    this.$toasted.error(`Start time is required`);
                    return false;
                }

                if (!this.event.dayt_appt_end || this.event.dayt_appt_end == 'Invalid Date') {
                    this.$toasted.error(`End time is required`);
                    return false;
                }

                if (!this.event.place_of_service_code) {
                    this.$toasted.error(`Please select place of service`);
                    return false;
                }

                if (this.eventType === 'EVENT') {
                    //if any user is added but currently not selected
                    if (this.event.users.users.find((user) => user.id === null)) {
                        this.$toasted.error(`When adding users, make sure that all users are selected.`);
                        return false;
                    }

                    return true;
                }

                if (this.event.clients.length === 0) {
                    this.$toasted.error('You cannot save an appointment with no clients');
                    return false;
                }

                if (this.event.services.length === 0) {
                    this.$toasted.error('You cannot save an appointment with no services');
                    return false;
                }

                if (this.event.counselors.length === 0) {
                    this.$toasted.error('You cannot save an appointment with no counselors');
                    return false;
                }

                return true;
            },
            async handleSaveChanges() {
                if (!this.validateUpdate()) {
                    return;
                }

                if (this.event.recurring_appt) {
                    if (this.dateHasChanged) {
                        this.$store.commit('eventEditor/openModal', 'confirmDateChange');
                    } else {
                        this.$store.commit('eventEditor/openModal', 'saveRecurring');
                    }
                } else {
                    await this.saveEdits();
                }
            },
            async saveEdits() {
                if (this.event.type === 'EVENT') {
                    try {
                        this.showLoader = true;
                        const resStatus = await saveEvent(this.event);
                        if (resStatus < 300) {
                            this.$emit('close');
                        }
                    } catch(e) {
                        console.log(e);
                    } finally {
                        this.showLoader = false;
                    } 
                }

                if (this.event.type === 'APPOINTMENT') {
                    try {
                        this.showLoader = true;
                        const resStatus = await saveAppointment(this.event, this.changeTracker);
                        if (resStatus < 300) {
                            this.$emit('close');
                        }
                    } catch(e) {
                        console.log(e);
                    } finally {
                        this.showLoader = false;
                    }
                }

                this.$emit('refresh');
            },
        },
    };
</script>
