<template>
    <!-- <Modal @close="$emit('close')" class="appt-modal"> -->
    <div class="appointment-editor">
        <form id='form-element'>
            <div class="row form-content pt-1 me-5">
                <div class="col-sm-9 mb-5">
                    <DeleteButton />
                </div>
                <div class="col-sm-9 mb-5">
                    <!--- Title --->
                    <TitleInput />
                </div>
                <div class="col-sm-9 mb-5">
                    <TelehealthLink />
                </div>
                <div class="col-sm-9 mb-5">
                    <SessionCountsCheckbox />
                </div>
                <div class="col-sm-9 mb-4">
                    <!--- Date & Time --->
                    <DateTimePickers>
                        <DatePicker/>
                        <TimePickers>
                            <template #startTime>
                                <TimePicker :kind="TimePickerKind.Start" />
                            </template>
                            <template #endTime>
                                <TimePicker :kind="TimePickerKind.End" />
                            </template>
                        </TimePickers>
                    </DateTimePickers>
                    <RRuleButton />
                </div>
                <div class="col-sm-9 mb-5">
                    <!--- Location & Appt Type --->
                    <LocationSection>
                        <LocationDropdown />
                        <EventTypeDropdown />
                    </LocationSection>
                </div>
                <div class="col-sm-9 mb-5">
                    <!--- Event Host & Users (only for events) --->
                    <Attendees>
                        <HostDropdown />
                        <Users>
                            <UserRow
                                v-for="user in eventUsers"
                                :key="user.id"
                                :user="user"
                            />
                        </Users>
                        <AddUserButton />
                    </Attendees>
                </div>
                <div class="col-sm-9 mb-5">
                    <!--- Clients (only for appointments) --->
                    <Clients>
                        <ClientRow
                            v-for="client in eventClients"
                            :key="client.id"
                            :client="client"
                            @refresh="refreshEvent"
                        />

                        <AddClientButton />
                    </Clients>
                </div>
                <div class="col-sm-9 mb-5">
                    <!--- Counselors (only for appointments) --->
                    <Counselors>
                        <CounselorRow
                            v-for="counselor in eventCounselors"
                            :key="counselor.id"
                            :counselor="counselor"
                        />
                    </Counselors>
                </div>
                <div class="col-sm-9 mb-5">
                    <!--- Services (only for appointments) --->
                    <Services>
                        <ServiceRow
                            v-for="service in eventServices"
                            :key="service.serviceCodeId"
                            :service="service"
                        />
                    </Services>

                    <GroupNote />
                </div>
                <div class="col-sm-9 mb-5">
                    <!--- Footer --->
                    <!-- <hr v-if="eventHasChanges" /> -->
                    <SaveChangesButton @refresh="refreshEvent" @close="$emit('close')" />
                </div>
            </div>
            
        </form>
        <!--- Supplemental Modals --->
        <DeleteEventModal v-if="deleteEventOpen" @deleteSuccessful="$emit('close')" />
            <ConfirmDateChangeModal v-if="confirmDateChangeOpen" />
            <SaveRecurringModal v-if="saveRecurringOpen" />
            <RRuleModal v-if="editRRuleOpen" />
            <AutoGenInvoiceModal v-if="autoGenInvoiceOpen" @refresh="refreshEvent" />
            <AttendanceWarningModal v-if="attendanceWarningOpen" @refresh="refreshEvent" />
            <DeleteClientModal v-if="deleteClientOpen" />
            <AutopayWarningModal v-if="autopayWarningOpen" @refresh="refreshEvent" />
            <DeleteServiceModal v-if="deleteServiceOpen" />
    </div>
    <!-- </Modal> -->
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { TimePickerKind } from '@/components/calendar/AppointmentEditor/common/constants';
    import Modal from '@/components/general/modals/Modal';
    import DeleteButton from '@/components/calendar/AppointmentEditor/components/DeleteButton';
    import DeleteEventModal from '@/components/calendar/AppointmentEditor/modals/DeleteEventModal';
    import TitleInput from '@/components/calendar/AppointmentEditor/components/TitleInput';
    import SaveChangesButton from '@/components/calendar/AppointmentEditor/components/SaveChangesButton';
    import ConfirmDateChangeModal from '@/components/calendar/AppointmentEditor/modals/ConfirmDateChangeModal';
    import SaveRecurringModal from '@/components/calendar/AppointmentEditor/modals/SaveRecurringModal';
    import TelehealthLink from '@/components/calendar/AppointmentEditor/components/TelehealthLink';
    import SessionCountsCheckbox from '@/components/calendar/AppointmentEditor/components/SessionCountsCheckbox';
    import DateTimePickers from '@/components/calendar/AppointmentEditor/components/DateTimePickers';
    import DatePicker from '@/components/calendar/AppointmentEditor/components/DatePicker';
    import TimePickers from '@/components/calendar/AppointmentEditor/components/TimePickers';
    import TimePicker from '@/components/calendar/AppointmentEditor/components/TimePicker';
    import RRuleButton from '@/components/calendar/AppointmentEditor/components/RRuleButton';
    import RRuleModal from '@/components/calendar/AppointmentEditor/modals/RRuleModal';
    import LocationSection from '@/components/calendar/AppointmentEditor/components/LocationSection';
    import LocationDropdown from '@/components/calendar/AppointmentEditor/components/LocationDropdown';
    import EventTypeDropdown from '@/components/calendar/AppointmentEditor/components/EventTypeDropdown';
    import Attendees from '@/components/calendar/AppointmentEditor/components/Attendees';
    import HostDropdown from '@/components/calendar/AppointmentEditor/components/HostDropdown';
    import Users from '@/components/calendar/AppointmentEditor/components/Users';
    import UserRow from '@/components/calendar/AppointmentEditor/components/UserRow';
    import AddUserButton from '@/components/calendar/AppointmentEditor/components/AddUserButton';
    import Clients from '@/components/calendar/AppointmentEditor/components/Clients';
    import ClientRow from '@/components/calendar/AppointmentEditor/components/ClientRow';
    import { getAppointment } from '@/components/calendar/AppointmentEditor/common/api';
    import AutoGenInvoiceModal from '@/components/calendar/AppointmentEditor/modals/AutoGenInvoiceModal';
    import AttendanceWarningModal from '@/components/calendar/AppointmentEditor/modals/AttendanceWarningModal';
    import DeleteClientModal from '@/components/calendar/AppointmentEditor/modals/DeleteClientModal';
    import AutopayWarningModal from '@/components/calendar/AppointmentEditor/modals/AutopayWarningModal';
    import AddClientButton from '@/components/calendar/AppointmentEditor/components/AddClientButton';
    import Counselors from '@/components/calendar/AppointmentEditor/components/Counselors';
    import CounselorRow from '@/components/calendar/AppointmentEditor/components/CounselorRow';
    import Services from '@/components/calendar/AppointmentEditor/components/Services';
    import ServiceRow from '@/components/calendar/AppointmentEditor/components/ServiceRow';
    import DeleteServiceModal from '@/components/calendar/AppointmentEditor/modals/DeleteServiceModal';
    import GroupNote from '@/components/calendar/AppointmentEditor/components/GroupNote';
    import { EventType } from '@/util/globalConstants';

    export default {
        name: 'AppointmentEditor',
        components: {
            GroupNote,
            DeleteServiceModal,
            ServiceRow,
            Services,
            CounselorRow,
            Counselors,
            AddClientButton,
            AutopayWarningModal,
            DeleteClientModal,
            AttendanceWarningModal,
            AutoGenInvoiceModal,
            ClientRow,
            Clients,
            AddUserButton,
            UserRow,
            Users,
            HostDropdown,
            Attendees,
            EventTypeDropdown,
            LocationDropdown,
            LocationSection,
            RRuleModal,
            RRuleButton,
            TimePicker,
            TimePickers,
            DatePicker,
            DateTimePickers,
            SessionCountsCheckbox,
            TelehealthLink,
            SaveRecurringModal,
            ConfirmDateChangeModal,
            SaveChangesButton,
            TitleInput,
            DeleteEventModal,
            DeleteButton,
            Modal
        },
        props: {
            initialEvent: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                TimePickerKind
            };
        },
        computed: {
            ...mapState('eventEditor', {
                event: (state) => state.event,
                deleteEventOpen: (state) => state.modals.deleteEvent.isOpen,
                confirmDateChangeOpen: (state) => state.modals.confirmDateChange.isOpen,
                saveRecurringOpen: (state) => state.modals.saveRecurring.isOpen,
                editRRuleOpen: (state) => state.modals.editRRule.isOpen,
                autoGenInvoiceOpen: (state) => state.modals.autoGenInvoice.isOpen,
                attendanceWarningOpen: (state) => state.modals.attendanceWarning.isOpen,
                deleteClientOpen: (state) => state.modals.deleteClient.isOpen,
                autopayWarningOpen: (state) => state.modals.autopayWarning.isOpen,
                deleteServiceOpen: (state) => state.modals.deleteService.isOpen,
                eventUsers: (state) => state.event.users?.users,
                eventClients: (state) => state.event.clients ?? [],
                eventCounselors: (state) => state.event.counselors,
                eventServices: (state) => state.event.services,
                clientHasMadePayment: (state) => state.globalAttrs.clientHasMadePayment,
                eventType: (state) => state.event.type,
            }),
            ...mapGetters('eventEditor', {
                eventHasChanges: 'eventHasChanges',
            }),
            ...mapGetters('scheduler', {
                calendarFilters: 'filters'
            })
        },
        methods: {
            async refreshEvent() {
                if (this.eventType === EventType.APPOINTMENT) {
                    const appointment = await getAppointment(this.event.group_uuid, this.calendarFilters);
                    await this.$store.dispatch('eventEditor/refreshEvent', appointment);
                }
            }
        },
        async created() {
            this.$store.commit('eventEditor/setClientHasMadePayment', false); //BM-641 additional fix
            this.$store.commit('eventEditor/initialize', this.initialEvent);

            await Promise.allSettled(this.eventClients.map(async (c) => {
                if (c.invoice_id !== null && !this.clientHasMadePayment) {
                    const res = await this.$api.get(`invoices/${c.invoice_id}`);

                    if (res.status < 300 && res.data?.amount_paid > 0) {
                        this.$store.commit('eventEditor/setClientHasMadePayment', true);
                    }
                }
            }));
            this.$forceUpdate()
        },
        async beforeDestroy() {
            this.$store.commit('eventEditor/destroy');
            await this.$router.replace({
                ...this.$router.currentRoute,
                query: {},
            });
        }
    };
</script>

<style scoped>
    form {
        border-radius: 12px;
        padding: 23px 20px 20px 20px;
        height: 54rem;
    }
    .form-content{
        height: 54rem;
        overflow: auto;
    }  
</style>
