<template>
    <button
        v-if="apptType !== 'Individual'"
        type="button"
        class="primary fw-600 btn-add-client p-2"
        :disabled="isDisabled"
        @click="handleAddClient"
    >
        <span class="material-icons icon-add-client">add_box</span> Add Client
    </button>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'AddClientButton',
        computed: {
            ...mapState('eventEditor', {
                clients: (state) => state.event.clients,
                apptType: (state) => state.event.appt_type,
            }),
            isDisabled() {
                return this.clients.some((c) => c.id === null);
            }
        },
        methods: {
            handleAddClient() {
                this.$store.commit('eventEditor/updateEvent', {
                    property: 'clients',
                    value: [...this.clients, { id: null, appt_id: null, name: null }],
                });
            }
        }
    };
</script>
<style scoped>
    .icon-add-client {
        font-size: 17px !important;
    }
    .btn-add-client {
        font-size: .95em;
        font-weight: 600;
    }
</style>
