<template>
    <Modal @close="handleClose">
        <div class="flex">
            <div class="right-20">
                <span class="material-icons-outlined red-text large-icon">error_outline</span>
            </div>
            <div>
                <h2 class="bottom-5">Delete Client</h2>
                <p>Are you sure you want to delete this client?</p>
            </div>
        </div>
        <div class="align-right top-20">
            <button class="secondary cancel" type="button" @click="handleClose">Cancel</button>
            <button class="primary" type="button" @click="handleDelete">Delete Client</button>
        </div>
    </Modal>
</template>

<script>
    import { mapState } from 'vuex';
    import Modal from '@/components/general/modals/Modal';

    export default {
        name: 'DeleteClient',
        components: { Modal },
        computed: {
            ...mapState('eventEditor', {
                client: (state) => state.modals.deleteClient.client,
            }),
        },
        methods: {
            handleClose() {
                this.$store.commit('eventEditor/closeModal', 'deleteClient');
            },
            async handleDelete() {
                await this.$store.dispatch('eventEditor/removeClient', this.client);
                this.handleClose();
            }
        }
    };
</script>
