<template>
    <div class="event-clients" >
        <div class="flex">
            <h5 class="e-textlabel flex-1">Client(s)</h5>
            <label class="e-textlabel flex-1" for="appt-attendance">Attendance</label>
        </div>
        <div class="input-btn-row flex bottom-10" v-for="(client, i) in apptClients" :key="'client' + i">
            <!--          Select the client   -->
            <div class="flex-2">
                <Multiselect
                    :options="clients"
                    label="name"
                    :searchable="true"
                    :show-labels="false"
                    track-by="id"
                    :value="apptClients[i]"
                    @select="clientChange($event, i)"
                    :disabled="!!checkClientSelectionLocked(apptClients[i])"
                />
            </div>

            <!--          Attendance   -->
            <div class="attendance flex-1">
                
                <select
                    id="appt-attendance"
                    class="fullwidth"
                    :value="client.apptStatus"
                    @change="attendanceChange($event, client, i)"
                    :disabled="disableAttendanceChange(client.id) "
                >
                    <option disabled value=""></option>
                    <option :disabled="attendedDisabled(client)" value="Attended">Attended</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="No Show">No Show</option>
                </select>
            </div>

            <!--          Action options   -->
            <ejs-dropdownbutton
                @select="actionSelect($event, client)"
                @beforeOpen="actionBeforeOpen($event, client, i)"
                @beforeClose="actionClose"
                :items="actionButtons"
                :disabled="disableAttendanceChange(client.id)"
                class="flex-1 primary purple-bg actions"
                >Actions
            </ejs-dropdownbutton>
        </div>

        <PayInvoiceModal
            :open="paymentModalOpen"
            :clientId="selectedClientId"
            :balance="clientOwes"
            :invoiceId="selectedClientInvoiceId ? selectedClientInvoiceId : 0"
            @close="paymentModalClose"
        />

        <DeleteClientModal
            :open="deleteClientOpen"
            :client="deletedClient"
            @closeModal="deleteClientOpen = false"
            @deleteClient="deleteClient($event)"
        />
        <button type="button" @click="addClient" v-if="apptType !== 'Individual'">
            <span class="material-icons">add_box</span> Add client
        </button>
        <AutoGenInvoiceModal
            :open="autoGenInvoiceModalOpen"
            @close="closeAutoGenInvoiceModal"
            @confirmCreateInvoice="confirmCreateInvoice"
        />
    </div>
</template>

<script>
    import PayInvoiceModal from '@/components/invoice/PayInvoiceModal.vue';
    import DeleteClientModal from '@/components/general/modals/DeleteClientModal';
    import AutoGenInvoiceModal from '@/components/calendar/EventDetailModal/AutoGenInvoiceModal';

    export default {
        name: 'Clients',
        components: {
            AutoGenInvoiceModal,
            PayInvoiceModal,
            DeleteClientModal
        },
        data() {
            return {
                actionButtons: [
                    { text: 'Progress Note' },
                    { separator: 'true' },
                    { text: 'Remove Client', disabled: false },
                    { separator: 'true' },
                    { text: 'Create Invoice' }
                ],
                autoGenInvoiceModalOpen: false,
              target: {},
              index: {},
              client: {},
            };
        },
        props: {
            clients: {
                type: Array,
                required: true
            },
            apptClients: {
                type: Array,
                required: true
            },
            paymentModalOpen: {
                type: Boolean,
                default: false
            },
            selectedClientId: {
                type: Number,
                default: 0
            },
            clientOwes: {
                type: Number,
                default: 0
            },
            selectedClientInvoiceId: {
                type: Number,
                default: 0
            },
            deleteClientOpen: {
                type: Boolean,
                default: false
            },
            deletedClient: {
                type: Object,
                default: null
            },
            apptType: {
                type: String,
                default: 'Individual'
            },
            autoGenerateInvoices: {
                type: Boolean,
                default: false
            },
          disableClientActions: {
            type: Boolean,
            default: false
          },
          changeTracker:{
            required: false
          }
        },
        methods: {
            checkClientSelectionLocked(client) {
                return client.readOnly || client.progressNoteId || client.apptStatus;
            },
            attendedDisabled(client) {
                return client.apptStatus === 'No Show' || client.apptStatus === 'Cancelled';
            },
            clientChange(event, index) {
                this.$emit('handleClientChange', event, index);
            },
            attendanceChange(event, client, index) {
                if (event.target.value === 'Attended' && this.autoGenerateInvoices && !client.invoice_id) {
                  this.autoGenInvoiceModalOpen = true
                  this.client = client
                  this.index = index
                } else {
                    this.$emit('handleAttendanceChange', event.target.value, client, index);
                }
            },
            actionClose() {
                this.$emit('handleActionClose');
            },
            actionBeforeOpen(args, client) {
                this.actionButtons = [
                    { text: 'Progress Note' },
                    { separator: 'true' },
                    { text: 'Remove Client', disabled: this.disableRemoveClient },
                    { separator: 'true' },
                    { text: 'Create Invoice' }
                ];

                if (this.$store.state.user.role_id >= 14 && !client.progressNoteId) {
                    let object = { text: 'Progress Note', disabled: true };
                    this.$set(this.actionButtons, 0, object);
                }

                if (client.invoice_id !== null) {
                    let item = { text: 'Pay Invoice' };
                    let index = this.actionButtons.findIndex((item) => item.text === 'Create Invoice');
                    this.$set(this.actionButtons, index, item);

                    let ndx2 = this.actionButtons.findIndex((item) => item.text === 'Remove Client');
                    this.actionButtons[ndx2].disabled = true;

                    //add new option to view newly created invoice
                    item = { text: 'View Invoice' };
                    this.actionButtons.push(item);
                }

                if (client.apptStatus !== null || client.progressNoteId !== null) {
                    let ndx2 = this.actionButtons.findIndex((item) => item.text === 'Remove Client');
                    this.actionButtons[ndx2].disabled = true;
                }
                let item = { text: 'View Client', disabled: false };
                this.actionButtons.push(item);
            },
            actionSelect(event, client, index) {
                this.$emit('handleActionSelect', event, client, index);
            },
            paymentModalClose() {
                this.$emit('handlePaymentModalClose');
            },
            deleteClient(event) {
                this.$emit('handleRemoveClient', event, true);
            },
            addClient() {
                this.$emit('handleAddClient');
            },
            closeAutoGenInvoiceModal() {
                this.autoGenInvoiceModalOpen = false;
            },
            confirmCreateInvoice() {
                this.autoGenInvoiceModalOpen = false;
              this.$emit('handleAttendanceChange', "Attended", this.client, this.index, true);
              this.client = {}
              this.index = {}
            },
          disableAttendanceChange(clientId){
              return this.$props.changeTracker?.added_clients?.includes(clientId) ?? false
          }
        }
    };
</script>
