<template>
    <div>
        <div class="appt-section-title">Counselor(s)*</div>
        <div v-for="(_, staffKey) in selectedStaff" :key="staffKey" class="input-btn-row flex center mb-3">
            <div class="me-3 w-100">
                <Multiselect
                    :allow-empty="false"
                    :options="clinicianList"
                    :searchable="true"
                    :show-labels="false"
                    label="name"
                    placeholder="Select Counselor"
                    track-by="id"
                    v-model="selectedStaff[staffKey]"
                    @select="appendCounselor($event, staffKey)"
                />
            </div>
            <div class="d-flex align-items-center">
                <ejs-tooltip
                    class="tooltipcontainer"
                    content="Add counselor"
                    target="#target"
                    cssClass="customtooltip"
                >
                    <span id="target" class="material-icons icon-add-field" @click="add">add</span>
                </ejs-tooltip>
                <ejs-tooltip
                    class="tooltipcontainer"
                    content="Delete"
                    target="#target"
                    cssClass="customtooltip"
                >
                    <span v-show="selectedStaff.length > 1" id="target" class="material-symbols-outlined icon-delete-prescription ms-2" @click="remove(staffKey)">delete</span>
                </ejs-tooltip>
                <!-- <button
                    type="button"
                    name="removeStaffBtn"
                    id="removeStaffBtn"
                    class="delete no-bg"
                    @click="remove(staffKey)"
                    v-show="selectedStaff.length > 1"
                >
                    <span class="material-icons-outlined delete dark-text">delete_outline</span>
                </button> -->
            </div> 
        </div>
        <!-- <button class="text-button" type="button" name="addBtn" id="addCounselorBtn" @click="add">
            <span class="material-icons">add_box</span> Add counselor
        </button> -->
        <!-- <hr /> -->
    </div>
</template>

<script>
    import Vue from 'vue';
    import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';

    Vue.use(ComboBoxPlugin);
    export default {
        name: 'CounselorsForAppt',
        props: {
            clinicians: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                selectedStaff: [{ id: null, name: null, host: false }]
            };
        },
        computed: {
            clinicianList() {
                const selectedStaffIds = this.selectedStaff.map(staff => staff.id);

                return this.clinicians.map((clinician) => ({
                    id: clinician.id,
                    name: `${clinician.first_name} ${clinician.last_name}`,
                    $isDisabled: selectedStaffIds.includes(clinician.id)
                }));
            }
        },
        methods: {
            appendCounselor(e, staffKey) {
                const {id, name} = e;
                this.$set(this.selectedStaff, staffKey, {id, name});
                this.$emit('counselorAdded', this.selectedStaff);
            },

            add() {
                this.selectedStaff.push({ id: null, name: null, host: false });
            },
            remove(index) {
                if (this.selectedStaff.length >= 2) this.selectedStaff.splice(index, 1);
                this.$emit('counselorAdded', this.selectedStaff);
            }
        },
    };
</script>
