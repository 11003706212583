<template>
    <div class="flex center service bottom-10">
        <div class="flex-3 relative has-tool-tip">
            <Multiselect
                trackBy="serviceCodeId"
                :customLabel="constructLabel"
                :searchable="true"
                :showLabels="false"
                :value="service"
                :disabled="selectDisabled"
                :options="serviceList"
                @select="handleServiceSelect"
            />
            <div v-if='showToolTip' class="disabled-tool-tip flex">
                <span class="material-icons-outlined icon dark-red right-15">error_outline</span>
                <span>
                    You cannot remove or add services to this appointment due to a Progress Note being started or invoice generated.
                    Please adjust services at the Progress Note level
                </span>
            </div>
        </div>

        <ejs-tooltip
            class="tooltipcontainer"
            content="Delete"
            target="#target"
            cssClass="customtooltip"
        >
            <span v-if="serviceCount > 1 && hideTrashCan" id="target" class="material-symbols-outlined icon-delete-prescription ms-2" @click="handleDeleteClick">delete</span>
        </ejs-tooltip>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { formatService } from '@/components/calendar/AppointmentEditor/common/formatService';

    export default {
        name: 'ServiceRow',
        props: {
            service: {
                type: Object,
                required: true,
            }
        },
        computed: {
            ...mapState('eventEditor', {
                serviceCount: (state) => state.event.services.length,
                originalEventServices: (state) => state.originalEvent.services,
                clientHasMadePayment: (state) => state.globalAttrs.clientHasMadePayment,
                eventServices: (state) => state.event.services,
            }),
            ...mapGetters('eventEditor', {
                serviceList: 'globalServicesWithDisabled',
                someClientHasBeenInvoiced: 'someClientHasBeenInvoiced',
                someClientHasAProgressNote: 'someClientHasAProgressNote'

            }),
            selectDisabled() {
                return Boolean(this.originalEventServices.find((s) => s.serviceCodeId === this.service.serviceCodeId));
            },
            hideTrashCan(){
                if(this.someClientHasBeenInvoiced){
                    return false
                }

                if(this.someClientHasAProgressNote){
                    return false
                }

                return true
            },
            showToolTip(){
                return this.someClientHasBeenInvoiced || this.someClientHasAProgressNote || this.allClientsHaveCancelledNoShow;
            }
        },
        methods: {
            constructLabel(service) {
                if (service.serviceCodeId === null) {
                    return '';
                }
                return service.code
                    ? `${service.code} ${service?.name ?? service.long_descrip}`
                    : `${service.service_code} ${service?.name ?? service.long_descrip}`;
            },

            handleServiceSelect(service) {
                this.$store.dispatch('eventEditor/updateService', {
                    currentService: this.service,
                    nextService: formatService(service),
                });
            },
            handleDeleteClick() {
                if (this.service.serviceCodeId === null) {
                    this.$store.commit('eventEditor/updateEvent', {
                        property: 'services',
                        value: this.eventServices.filter((s) => s.serviceCodeId !== this.service.serviceCodeId)
                    });
                    return;
                }

                if (!this.originalEventServices.some((s) => s.serviceCodeId === this.service.serviceCodeId)) {
                    this.$store.dispatch('eventEditor/removeAddedService', this.service.serviceCodeId);
                    return;
                }

                this.$store.commit('eventEditor/openClientModal', {
                    name: 'deleteService',
                    service: this.service,
                });
            },
        }
    };
</script>
