<template>
    <div v-if="eventType === 'EVENT'">
        <slot />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Attendees',
        computed: {
            ...mapState('eventEditor', {
                eventType: (state) => state.event.type,
            }),
        },
    };
</script>
