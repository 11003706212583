<template>
    <div v-if="eventType === EventType.APPOINTMENT">
        <h5 class="appt-section-title">Counselor(s)*</h5>
        <slot />
        <button
            class="primary btn-add-councelor d-flex align-items-center p-2"
            type="button"
            @click="handleAddCounselor"
            :disabled="addDisabled"
        >
            <span class="material-icons icon-add-councelor me-1">add_box</span> Add Counselor
        </button>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { EventType } from '@/util/globalConstants';

    export default {
        name: 'Counselors',
        data() {
            return {
                EventType,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                eventType: (state) => state.event.type,
                counselors: (state) => state.event.counselors,
            }),
            addDisabled() {
                return this.counselors.some((c) => c.id === null);
            },
        },
        methods: {
            handleAddCounselor() {
                this.$store.commit('eventEditor/updateEvent', {
                    property: 'counselors',
                    value: [...this.counselors, { host: false, id: null, name: null }],
                });
            },
        }
    };
</script>
<style scoped>
    .icon-add-councelor {
        font-size: 17px !important;
    }
    .btn-add-councelor {
        font-size: .95em;
        font-weight: 600;
    }
</style>
