<template>
    <div class="add-new-card">
        <div>
            <CreditCardInput
                id="newCardInput"
                class=""
                label="Card Number"
                @cvv="handleCvvChange"
                v-model="formData.cardNumber"
                ref="cardNumberInput"
                @blur="validateCardNumber"
                @input='validateCardName'
            />
            <span class="dark-red weight-600" v-if="errors.cardNumber">{{ errors.cardNumber }}</span>
        </div>
        <div class="flex colx3 space-between top-15 bottom-20">
            <div class="flex-1 right-15">
                <ExpirationInput
                    id="newCardExp"
                    label="Expiration"
                    v-model="formData.expDate"
                    ref="expInput"
                    @blur="validateExpiration"
                />
                <span class="dark-red weight-600" v-if="errors.expDate">{{ errors.expDate }}</span>
            </div>
            <div class="flex-1 right-15">
                <CvvInput
                    id="cvvInput"
                    label="CVC"
                    :maxLen="formData.maxCvvLen"
                    v-model="formData.cvv"
                    @blur="validateCvv"
                    ref="cvvInput"
                />
                <span class="dark-red weight-600" v-if="errors.cvv">{{ errors.cvv }}</span>
            </div>
            <div class="flex-1">
                <ZipInput id="zipInput" label="ZIP Code" v-model="formData.zip" @blur="validateZip" ref="zipInput" />
                <span class="dark-red weight-600" v-if="errors.zip">{{ errors.zip }}</span>
            </div>
        </div>
        <div v-if="!hideSave" class="bottom-15">
            <Checkbox id="saveCheckbox" label="Save New Card" class="bottom-15 flex center" v-model="formData.saveCard" />
            <Input
            v-model="formData.saveCardNickname"
            v-if="formData.saveCard"
            label="Card Name"
            placeholder="Card Name"
            />
        </div>
        <div v-if="!hideAutopay && (formData.saveCard || this.hideSave)">
            <Checkbox
                id="autopayCheckbox"
                label=" Set up autopay with this card"
                class="flex center"
                v-model="formData.isAutopay"
            />
            <p class="top-0 left-25 font-13" v-if="formData.isAutopay">
                <em>Setting up autopay for this client will automatically pay any invoice generated for the client.</em>
            </p>
        </div>
    </div>
</template>

<script>
    import CreditCardInput from '@/components/stripe/CreditCardInput';
    import ExpirationInput from '@/components/stripe/ExpirationInput';
    import CvvInput from '@/components/stripe/CvvInput';
    import ZipInput from '@/components/stripe/ZipInput';
    import {settings} from "@/util/apiRequests";
    export default {
        name: 'NewCardForm',
        components: { ZipInput, CvvInput, ExpirationInput, CreditCardInput },
        props: {
            hideAutopay: {
                type: Boolean,
                default: false,
            },
            hideSave: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                formData: {
                    cardNumber: '',
                    expDate: {
                        month: null,
                        year: null,
                    },
                    cvv: '',
                    zip: '',
                    maxCvvLen: 3,
                    isAutopay: false,
                    saveCard: false,
                    saveCardNickname: '',
                },
                saveCard: false,
                errors: {
                    cardNumber: '',
                    expDate: '',
                    cvv: '',
                    zip: '',
                },
            };
        },
        computed: {
            paymentMethod() {
                return {
                    type: 'card',
                    card: {
                        number: this.formData.cardNumber,
                        exp_month: this.formData.expDate.month,
                        exp_year: this.formData.expDate.year,
                        cvc: this.formData.cvv,
                        is_autopay: this.formData.isAutopay,
                        save_card: this.formData.saveCard,
                        save_card_nickname: this.formData.saveCardNickname,
                    },
                    billing_details: {
                        address: {
                            postal_code: this.formData.zip,
                        },
                    },
                };
            },
        },
        methods: {
            handleCvvChange(newLen) {
                this.formData.maxCvvLen = newLen;
            },
            validate() {
                return [
                    this.validateCardNumber(),
                    this.validateExpiration(),
                    this.validateCvv(),
                    this.validateZip(),
                    this.validateCardName()
                ].every((val) => val);
            },
            validateCardNumber() {
                if (!this.$refs.cardNumberInput.isValid) {
                    this.errors.cardNumber = 'Invalid card number';
                    return false;
                }
                return true;
            },
            validateExpiration() {
                if (!this.$refs.expInput.isValid) {
                    this.errors.expDate = 'Invalid expiration date';
                    return false;
                }

                return true;
            },
            validateCvv() {
                if (this.formData.cvv.length < this.formData.maxCvvLen) {
                    this.errors.cvv = 'Invalid CVV';
                    return false;
                }

                return true;

            },
            validateZip() {
                if (this.formData.zip.length < 5) {
                    this.errors.zip = 'Invalid zip code';
                    return false;
                }

                return true;
            },
            validateCardName() {
                if (this.$refs.cardNumberInput.isValid && !this.formData.saveCardNickname) {
                    this.formData.saveCardNickname = this.formData.cardNumber.slice(-4)
                }
                return true;
            },
            clear() {
                this.$refs.cardNumberInput.clear();
                this.$refs.expInput.clear();
                this.$refs.cvvInput.clear();
                this.$refs.zipInput.clear();
            },
        },
        async created(){
            const res = await this.$api.get(settings.getSetting('default_card_to_autopay', 'company', 'company'))
            this.formData.isAutopay = !!res.data
        },
        watch: {
            formData: {
                deep: true,
                handler() {
                    this.$emit('input', this.paymentMethod);
                },
            },
            'formData.cardNumber'() {
                this.errors.cardNumber = '';
            },
            'formData.expDate': {
                deep: true,
                handler() {
                    this.errors.expDate = '';
                },
            },
            'formData.cvv'() {
                this.errors.cvv = '';
            },
            'formData.zip'() {
                this.errors.zip = '';
            }
        },
    };
</script>