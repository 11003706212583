<template>
    <!-- <label v-if="showCheckbox" class="top-15 flex center">
        <input
            type="checkbox"
            class="right-10 inline-block color"
            :value="isRequired"
            :checked="isRequired"
            @change="handleChange"
        />
        <span class="font-14 pointer"> Count towards required sessions</span>
    </label> -->
    <div class="col-sm-9 no-display-block" v-if="showCheckbox">
        <label class="bottom-10">
            <Toggle
                id='isRequired'
                class='toggle-switch ms-0'
                :value='isRequired'
                labelClass="switch"
                spanClass="slider"
                @input="handleChange"
            />
            <span class="fs-6 fw-bolder">Mandatory to attend</span>
        </label>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'SessionCountsCheckbox',
        computed: {
            ...mapState('eventEditor', {
                isRequired: (state) => !!state.event.isRequired,
                eventType: (state) => state.event.type
            }),
            showCheckbox() {
                return this.eventType !== 'EVENT';
            }
        },
        methods: {
            handleChange() {
                this.$store.dispatch('eventEditor/toggleIsRequired');
            }
        }
    };
</script>

<style scoped>
    .no-display-block label {
        display: unset !important;
    }
</style>
