<template>
    <div class="input-btn-row flex center bottom-10">
        <Multiselect
            label="name"
            trackBy="id"
            :allowEmpty="false"
            :searchable="true"
            :showLabels="false"
            :options="userList"
            :value="user"
            @select="handleSelection"
        />

        <ejs-tooltip
            class="tooltipcontainer"
            content="Delete"
            target="#target"
            cssClass="customtooltip"
        >
            <span v-show="eventUsers.length > 1" id="target" class="material-symbols-outlined icon-delete-prescription ms-2" @click="handleDelete">delete</span>
        </ejs-tooltip>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'UserRow',
        props: {
            user: {
                type: Object,
                required: true
            },
        },
        computed: {
            ...mapState('eventEditor', {
                eventUsers: (state) => state.event.users.users,
            }),
            ...mapGetters('eventEditor', {
                userList: 'globalUsersWithDisabled',
            }),
        },
        methods: {
            handleSelection(user) {
                const newUsers = this.eventUsers.map((u) => {
                    if (u.id === this.user.id) {
                        return user;
                    }
                    return u;
                });

                this.$store.dispatch('eventEditor/updateUsers', newUsers);
            },
            handleDelete() {
                const newUsers = this.eventUsers.filter((u) => u.id !== this.user.id);
                this.$store.dispatch('eventEditor/updateUsers', newUsers);
            },
        },
    };
</script>
