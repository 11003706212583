<template>
    <Modal @close="handleClose">
        <p>
            Deleting this service will affect existing invoices and progress notes for this appointment. Are you
            sure you want to delete this service?
        </p>

        <div class="align-right">
            <button
                class="secondary"
                type="button"
                @click="handleClose"
            >
                Cancel
            </button>

            <button
                class="primary"
                type="button"
                @click="handleDelete"
            >
                Delete
            </button>
        </div>
    </Modal>
</template>

<script>
    import { mapState } from 'vuex';
    import Modal from '@/components/general/modals/Modal';

    export default {
        name: 'DeleteServiceModal',
        components: { Modal },
        computed: {
            ...mapState('eventEditor', {
                service: (state) => state.modals.deleteService.service,
            }),
        },
        methods: {
            handleClose() {
                this.$store.commit('eventEditor/closeModal', 'deleteService');
            },
            async handleDelete() {
                await this.$store.dispatch('eventEditor/removeExistingService', this.service);
                this.handleClose();
            },
        }
    };
</script>
