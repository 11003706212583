<template>
    <div class="bottom-15">
        <h5 class="e-textlabel">Meeting Host*</h5>
        <Multiselect
            label="name"
            trackBy="id"
            :allowEmpty="false"
            :searchable="true"
            :showLabels="false"
            :options="users"
            :value="meetingHost"
            @select="handleSelection"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'HostDropdown',
        computed: {
            ...mapState('eventEditor', {
                users: (state) => state.globalLists.users,
                meetingHost: (state) => state.event.users.meetingHost,
            }),
        },
        methods: {
            handleSelection(host) {
                this.$store.dispatch('eventEditor/updateMeetingHost', host);
            },
        },
    };
</script>
