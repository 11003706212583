<template>
    <Modal @close="handleCancel">
        <h1>How would you like to save these changes?</h1>
        <div class="flex flex-column">
            <label class="flex center bottom-10">
                <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.OnlyCurrent" />
                <span class="left-10">Save changes for this appointment only</span>
            </label>

            <label class="flex center bottom-10">
                <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.AllFuture" id="allFuture" />
                <span class="left-10">Save changes for this appointment and the following ones in this series</span>
            </label>
        </div>
        <div class="align-right pad-10">
            <button type="button" class="secondary bottom-0" @click="handleCancel">Cancel</button>
            <button type="button" class="primary bottom-0" @click="handleSave">Save</button>
        </div>
    </Modal>
</template>

<script>
    import { mapState } from 'vuex';
    import Modal from '@/components/general/modals/Modal';
    import RecurrenceSaveType from '@/util/recurrenceSaveType';
    import { saveAppointment } from '@/components/calendar/AppointmentEditor/common/api';

    export default {
        name: 'SaveRecurringModal',
        components: { Modal },
        data() {
            return {
                RecurrenceSaveType,
                saveChoice: RecurrenceSaveType.OnlyCurrent,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                event: (state) => state.event,
                changeTracker: (state) => state.changeTracker,
            }),
        },
        methods: {
            handleCancel() {
                this.$store.commit('eventEditor/closeModal', 'saveRecurring');
            },
            async handleSave() {
                const statusCode = await saveAppointment(this.event, this.changeTracker, this.saveChoice);

                if (statusCode < 300) {
                    this.handleCancel();
                }
            }
        }
    };
</script>
