<template>
    <div class="input-btn-row flex bottom-10">
        <div style="width: 45%;">
            <Multiselect
                trackBy="id"
                :customLabel="label"
                :searchable="true"
                :showLabels="false"
                :value="client"
                :disabled="selectDisabled"
                :options="clientsList"
                @select="handleClientSelected"
            />
        </div>

        <AttendanceDropdown :client="client" @refresh="$emit('refresh')" />

        <ClientActionsDropdown :client="client" @refresh="$emit('refresh')" />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { AttendanceStatus } from '@/components/calendar/AppointmentEditor/common/constants';
    import AttendanceDropdown from '@/components/calendar/AppointmentEditor/components/AttendanceDropdown';
    import ClientActionsDropdown from '@/components/calendar/AppointmentEditor/components/ClientActionsDropdown';

    export default {
        name: 'ClientRow',
        components: { ClientActionsDropdown, AttendanceDropdown },
        props: {
            client: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                AttendanceStatus,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                currentAttendance: (state) => state.originalEvent.appt_status,
                addedClients: (state) => state.changeTracker.added_clients,
                changedClients: (state) => state.changeTracker.changed_clients,
                event: (state) => state.event,
                autoGenerateInvoices: (state) => state.globalAttrs.autoGenerateInvoices,
            }),
            ...mapGetters('eventEditor', {
                clientsList: 'globalClientsWithDisabled',
            }),
            readOnlyClient() {
                return this.client.invoice_id !== null && this.client.invoice_id !== undefined;
            },
            selectDisabled() {
                return Boolean(this.readOnlyClient || this.client.pn_id || this.client.appt_status);
            },
            clientHasInvoice() {
                return this.client.invoice_id;
            },
        },
        methods: {
            label(client) {
                if (client.name) {
                    return client.name;
                }

                if (!client.first_name || !client.last_name) {
                    return '';
                }

                return `${client.first_name} ${client.last_name}`;
            },
            handleClientSelected(client) {
                this.$store.dispatch('eventEditor/updateClient', {
                    previousClient: this.client,
                    nextClient: client
                });
            },
        },
    };
</script>
