<template>
    <NoButtonModal :open='open' @close='handleEventDetailClose'>
        <div class='appt-modal' v-if='event'>
            <div>
                <button
                    type='button'
                    v-if='upcomingForDelete && userIsAdmin && canDeleteEvent'
                    id='deleteApptBtn'
                    class='secondary top-0'
                    name='deleteApptBtn'
                    @click='handleDeleteModalOpen'
                >
                    Delete {{ deleteButtonText }}
                </button>

                <!-- T I T L E  -->
                <Title
                    :title='eventData.title'
                    :telehealth='eventData.telehealth'
                    :virtualEvent='eventData.location'
                    :isUpcoming='eventIsUpcoming'
                    @titleUpdated='handleTitleChange'
                />

                <label v-if="eventType !== 'EVENT'" class='bottom-20 inline-block'>
                    <input
                        id='isRequired'
                        type='checkbox'
                        v-model='eventData.isRequired'
                        class='right-5 inline-block color'
                        @change='handleRequiredToggle'
                    />
                    <span class='left-10'>Counts toward required sessions</span>
                </label>

                <!-- D A T E  &  T I M E -->
                <h5 class='e-textlabel'>Date/Time</h5>
                <div class='flex center space-between bottom-20'>
                    <div class='dateTimeSelection flex-1 right-10'>
                        <ejs-datepicker
                            id='start_date'
                            :format='dateFormat'
                            v-model='eventData.dayt_appt_start'
                            @change='handleDateChange'
                        ></ejs-datepicker>
                    </div>
                    <div class='flex center flex-1'>
                        <ejs-timepicker
                            id='start_time'
                            :min='minTime'
                            :max='maxTime'
                            :step='timeStep'
                            @change='handleTimeChange'
                            v-model='eventData.dayt_appt_start'
                        ></ejs-timepicker>
                        &nbsp;<span> — </span>&nbsp;
                        <ejs-timepicker
                            id='end_time'
                            :min='minTime'
                            :max='maxTime'
                            :step='timeStep'
                            @change='handleTimeChange($event, true)'
                            v-model='eventData.dayt_appt_end'
                        ></ejs-timepicker>
                    </div>
                </div>
                <button v-if="shouldShowRRuleButton" @click='toggleRRuleEditModal' class='primary top-0 bottom-20'>
                    {{ rRuleButtonText }}
                </button>

                <!-- L O C A T I O N   &   A P P T  T Y P E  // add rooms / location to events later-->
                <div class='locationApptType flex bottom-15'>
                    <div v-if="0 && eventType == 'EVENT'" class='flex-1 right-10'>
                        <h5 class='e-textlabel'>Room</h5>
                        <Dropdown
                            name='room'
                            id='room'
                            v-model='eventData.location'
                            label=''
                            :options="roomOptions"
                            @change='handleRoomChange'
                        />
                    </div>

                    <div v-if="1 || eventType !== 'EVENT'" class='flex-1 right-10'>
                        <h5 class='e-textlabel'>Place of Service </h5>
                        <Dropdown
                            name='room'
                            id='room'
                            v-model='eventData.place_of_service_code'
                            label=''
                            :options='placeOfServiceOptions'
                            @change='handleRoomChange'
                        />
                    </div>
                    <div class='flex-1'>
                        <h5 class='e-textlabel'>Appointment Type</h5>
                        <Dropdown
                            name='event_type'
                            id='event_type'
                            v-model='eventData.appt_type'
                            label=''
                            :options='appointmentOptions'
                            disabled
                        />
                    </div>
                </div>

                <!-- M E E T I N G  H O S T    &   U S E R S -->
                <div v-if="eventType == 'EVENT'">
                    <h5 class='e-textlabel'>Meeting Host</h5>
                    <Multiselect
                        :allow-empty='false'
                        :options='activeUsers'
                        :searchable='true'
                        :show-labels='false'
                        label='name'
                        track-by='id'
                        v-model='eventData.users.meetingHost'
                        @select='(e) => handleMeetingHostSelection(e)'
                    />

                    <h5 class='e-textlabel'>User(s)</h5>
                    <div
                        v-for='(users, ndx) in event.users.users'
                        :key='ndx'
                        class='input-btn-row flex center bottom-10'
                    >
                        <Multiselect
                            :allow-empty='false'
                            :options='activeUsers'
                            :searchable='true'
                            :show-labels='false'
                            label='name'
                            track-by='id'
                            v-model='eventData.users.users[ndx]'
                            @select='(e) => handleUserSelection(e)'
                        />

                        <button
                            type='button'
                            name='removeStaffBtn'
                            id='removeStaffBtn'
                            class='delete no-bg'
                            @click='handleDeleteUser(ndx)'
                            v-show='eventData.users.users.length > 1'
                        >
                            <span class='material-icons-outlined delete dark-text'>delete_outline</span>
                        </button>
                    </div>

                    <div class='flex space-between'>
                        <button type='button' name='addUserBtn' id='addUserBtn' @click='handleAddUser'>
                            <span class='material-icons'>add_box</span> Add User(s)
                        </button>
                    </div>
                </div>

                <div v-if="eventType == 'EVENT'" class='flex justify-center'>
                    <button type='button' v-if='changesDirty' @click='handleSaveChanges' class='primary'>
                        Save Changes
                    </button>
                </div>

                <!-- C L I E N T S   -->
              <Clients v-if='eventType === EventType.APPOINTMENT'
                   :clients="clients"
                   :apptClients="apptClients"
                   :actionButtons="actionButtons"
                   :paymentModalOpen="paymentModalOpen"
                   :selectedClientId="selectedClientId"
                   :clientOwes="clientOwes"
                   :deletedClient="deletedClient"
                   :deleteClientOpen="deleteClientOpen"
                   :selectedClientInvoiceId="selectedClientInvoiceId"
                   :apptType="event.appt_type"
                   :autoGenerateInvoices="autoGenerateInvoices"
                   :disableClientActions = "disableClientActions"
                   :changeTracker="changeTracker"
                   @handleClientChange="handleClientChange"
                   @handleAttendanceChange="handleAttendanceChange"
                   @handleActionSelect="handleActionSelect"
                   @handleActionClose="handleActionClose"
                   @handlePaymentModalClose="handlePaymentModalClose"
                   @handleRemoveClient="handleRemoveClient"
                   @handleAddClient="handleAddClient"
              />
<!--                <div v-if='eventType === EventType.APPOINTMENT' class='event-clients'>-->
<!--                    <h5 class='e-textlabel flex-1'Client(s)></h5>-->
<!--                    <div class='input-btn-row flex bottom-10' v-for='(client, i) in apptClients' :key="'client' + i">-->
<!--                        <div class='flex-2'>-->
<!--                            <Multiselect-->
<!--                                :options='clients'-->
<!--                                label='name'-->
<!--                                :searchable='true'-->
<!--                                :show-labels='false'-->
<!--                                track-by='id'-->
<!--                                :value='apptClients[i]'-->
<!--                                @select='handleClientChange($event, i)'-->
<!--                                :disabled='!!checkClientSelectionLocked(apptClients[i])'-->
<!--                            />-->
<!--                        </div>-->

<!--                        <div class='attendance flex-1'>-->
<!--                            <label class='e-textlabel flex-1' for='appt-attendance'>Attendance</label>-->
<!--                            <select-->
<!--                                id='appt-attendance'-->
<!--                                class='fullwidth'-->
<!--                                :value='client.apptStatus'-->
<!--                                @change='handleAttendanceChange($event.target, client, i)'-->
<!--                            >-->
<!--                                <option disabled value=''></option>-->
<!--                                <option :disabled='attendedDisabled(client)' value='Attended'>Attended</option>-->
<!--                                <option value='Cancelled'>Cancelled</option>-->
<!--                                <option value='No Show'>No Show</option>-->
<!--                            </select>-->
<!--                        </div>-->

<!--                        <ejs-dropdownbutton-->
<!--                            @select='handleActionSelect($event, clientInfo[i], i)'-->
<!--                            @beforeOpen='handleBeforeOpen($event, clientInfo[i], i)'-->
<!--                            @beforeClose='handleActionClose'-->
<!--                            :items='actionButtons'-->
<!--                            class='flex-1 primary purple-bg actions'-->
<!--                        >Actions-->
<!--                        </ejs-dropdownbutton>-->
<!--                    </div>-->

<!--                    <PayInvoiceModal-->
<!--                        :open='paymentModalOpen'-->
<!--                        :clientId='selectedClientId'-->
<!--                        :balance='clientOwes'-->
<!--                        :invoiceId='selectedClientInvoiceId ? selectedClientInvoiceId : 0'-->
<!--                        @close='handlePaymentModalClose'-->
<!--                    />-->

<!--                    <DeleteClientModal-->
<!--                        :open='deleteClientOpen'-->
<!--                        :client='deletedClient'-->
<!--                        @closeModal='deleteClientOpen = false'-->
<!--                        @deleteClient='handleRemoveClient($event, true)'-->
<!--                    />-->
<!--                    <button type='button' @click='handleAddClient' v-if="this.event.appt_type !== 'Individual'">-->
<!--                        <span class='material-icons'>add_box</span> Add client-->
<!--                    </button>-->
<!--                </div>-->

                <hr />

                <!-- C O U N S E L O R S    -->

                <div v-if='eventType === EventType.APPOINTMENT'>
                    <h5 class='e-textlabel'>Counselor(s)</h5>
                    <!--  iterate thur attached counselors for appt -->
                    <div v-for='(person, i) in counselors' :key='i' class='input-btn-row flex bottom-10'>
                        <Multiselect
                            :options='clinicianList'
                            :allow-empty='false'
                            :searchable='true'
                            :show-labels='false'
                            label='name'
                            track-by='id'
                            v-model='counselors[i]'
                            @select='(e) => handleCounselorChange(e)'
                        />

                        <button v-if='counselors.length > 1' type='button' class='delete' @click='handleRemoveCounselor(i)'>
                            <span class='material-icons-outlined delete dark-text'>delete_outline</span>
                        </button>
                    </div>
                    <button class="text-button" type='button' @click='handleAddCounselor'>
                        <span class='material-icons'>add_box</span> Add Counselor
                    </button>
                </div>

                <hr />

                <!--  S E R V I C E S  -->
                <div v-if='eventType === EventType.APPOINTMENT'>
                    <h5 class='e-textlabel'>Service(s)</h5>

                    <!-- display services & prices  -->
                    <div
                        class='flex center service bottom-10'
                        v-for='(service, serviceKey) in apptServices'
                        :key="'service' + serviceKey"
                    >
                        <div class='flex-3 right-10 relative has-tool-tip'>
                            <Multiselect
                                v-model='apptServices[serviceKey]'
                                :searchable='true'
                                :disabled='apptServices[serviceKey].disable'
                                label='descrip'
                                track-by='id'
                                :options='serviceList'
                                @select='(e) => handleServiceSelect(e, serviceKey)'
                            />
                            <div class='disabled-tool-tip flex'>
                                <span class='material-icons-outlined icon dark-red right-15'>error_outline</span>
                                <span>You cannot remove or add services to this appointment due to a Progress Note being started or invoice generated. Please adjust services at the Progress Note level.</span>
                            </div>
                        </div>
                        <button
                            v-if='apptServices.length > 1'
                            type='button'
                            class='margin-0'
                            :id='serviceInfo[serviceKey]'
                            @click='
                                handleDeleteServiceModalOpen(
                                    serviceInfo[serviceKey],
                                    serviceKey,
                                    apptServices[serviceKey]
                                )
                            '
                        >
                            <span class='material-icons-outlined delete dark-text'>delete_outline</span>
                        </button>
                    </div>

                    <div class='flex space-between'>
                        <button class="text-button" type='button' @click='handleAddService'>
                            <span class='material-icons'>add_box</span> Add Service
                        </button>
                    </div>

                    <DeleteServiceModal
                        :open='deleteServiceOpen'
                        @closeModal='deleteServiceOpen = false'
                        @deleteService='handleDeletedSelectedService(deletedService)'
                    />

                    <hr />

                    <div v-if="['Group', 'Couple', 'Family'].includes(this.event.appt_type)">
                        <label for='noteSummary'>Group/Couple/Family Summary Note</label>
                        <TextArea
                            type="text"
                            id="noteSummary"
                            name="noteSummary"
                            rows='4'
                            cols='50'
                            label=""
                            v-model='summaryNote'
                            @input='handleSummaryChange'
                            :limit=4000
                            :characterCounter="true"
                        />
                    </div>
                    <hr />

                    <div class='flex justify-center'>
                        <button type='button' v-if='changeTrackerDirty' @click='handleSaveChanges' class='primary'>
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <AttendanceWarningModal
            :open='attendanceWarningOpen'
            :message='attendanceWarningMessage'
            @attendanceUpdated='attendanceUpdated'
            @close='cancelAttendanceUpdate'
            :payload='attendancePayload'
            :autoGenerateInvoices="autoGenerateInvoices"
        />

        <AutopayWarningModal
            :open='autopayWarningOpen'
            :name='autopayWarningName'
            :total='autopayWarningFee'
            @close='autopayWarningOpen = false'
            @confirm='invoiceClients(autopayWarningPayload, true)'
        />

        <DeleteEventModal
            :open='deleteApptOpen'
            :canDelete='canDelete'
            :type='eventType'
            :isRecurring='isRecurring'
            @cancel='deleteApptOpen = false'
            @delete='handleDeleteAppt'
        />

        <SaveRecurringModal
            :open='saveRecurringModalOpen'
            @close='saveRecurringModalOpen = false'
            @selectionConfirmed='handleRecurrenceSaveMethodSelected'
        />

        <EditRRuleModal
            :open='editRRuleModalOpen'
            @close='closeEditRRuleModal'
            @rRuleChangeSave='handleRRuleChangeSave'
            @rRuleDelete='deleteRRule'
            :start='this.eventData.dayt_appt_start'
            :recurrenceRule='this.eventData.recurrence_rule'
        />

        <ConfirmDateChangeModal
            v-if="confirmDateChangeOpen"
            @close="confirmDateChangeOpen = false"
            @confirmDateUpdate="handleRecurrenceSaveMethodSelected(RecurrenceSaveType.OnlyCurrent)"
        />
    </NoButtonModal>
</template>

<script>
    import Vue from 'vue';
    import dayjs from 'dayjs';
    import Title from '@/components/calendar/EventDetailModal/Title.vue';
    import { mapState } from 'vuex';
    import { appts, events, invoices, payments, serviceItems } from '@/util/apiRequests';
    import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import { DropDownListPlugin, ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';
    import { DropDownButtonPlugin } from '@syncfusion/ej2-vue-splitbuttons';
    import { EventType, InvoiceStatus } from '@/util/globalConstants';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import DeleteClientModal from '@/components/general/modals/DeleteClientModal';
    import DeleteServiceModal from '@/components/general/modals/DeleteServiceModal';
    import DeleteApptModal from '@/components/general/modals/DeleteApptModal';
    import PayInvoiceModal from '@/components/invoice/PayInvoiceModal.vue';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import AttendanceWarningModal from '@/components/calendar/AttendanceWarningModal';
    import AutopayWarningModal from '@/components/calendar/AutopayWarningModal';
    import attendance from '@/pages/reports/Attendance';
    import SaveRecurringModal from '@/components/calendar/EventDetailModal/SaveRecurringModal';
    import RecurrenceSaveType from '@/util/recurrenceSaveType';
    import DeleteEventModal from '@/components/calendar/DeleteEventModal';
    import EditRRuleModal from '@/components/calendar/EditRRuleModal';
    import Clients from '@/components/calendar/EventDetailModal/Clients';
    import ConfirmDateChangeModal from '@/components/calendar/EventDetailModal/ConfirmDateChangeModal';

    Vue.use(DatePickerPlugin);
    Vue.use(DropDownListPlugin);
    Vue.use(ComboBoxPlugin);
    Vue.use(DropDownButtonPlugin);

    const noShowApptMessage =
        'Updating the attendance status to “No Show” will remove original service fees from the invoice and add a no show fee to the invoice. Are you sure you want to update?';
    const cancelApptMessage =
        'Updating the attendance status to “Canceled” will remove original service fees from the invoice and add a cancellation fee to the invoice. Are you sure you want to update?';

    const emptyEventData = {
        title: '',
        appt_type: '',
        appt_notes: null,
        type: '',
        result: '',
        user_color: '',
        start: '',
        end: '',
        company_id: 0,
        office_id: 0,
        user_id_create: 0,
        dayt_appt_start: '',
        dayt_appt_end: '',
        active: 0,
        group_uuid: '',
        appointment: '',
        event: '',
        appt_status: null,
        counselors: [],
        room: 0,
        telehealth: 0,
        serviceCodeId: 0,
        service_cost: 0,
        pn_id: 0,
        pn_owner_id: 0,
        dateSigned: null,
        tags: null,
        recurrence_rule: null,
        recurrence_id: null,
        recurring_appt: false,
        services: [],
        clients: [],
        appt_id: 0,
        placesOfService: [
            {
                code: '02',
                name: 'Telehealth'
            },
            {
                code: '11',
                name: 'Office'
            },
        ]
    };

    export default {
        name: 'EventDetailModal',

        components: {
            ConfirmDateChangeModal,
            DeleteEventModal,
            SaveRecurringModal,
            AutopayWarningModal,
            Title,
            CurrencyInput,
            AttendanceWarningModal,
            NoButtonModal,
            DeleteClientModal,
            DeleteServiceModal,
            DeleteApptModal,
            PayInvoiceModal,
            EditRRuleModal,
            Clients
        },

        props: {
            open: {
                type: Boolean,
                required: true
            },
            event: {
                type: Object
            },
            clinicians: {
                type: Array,
                required: true
            },
            clients: {
                type: Array,
                required: true
            },
            offices: {
                type: Array,
                required: true
            },
            services: {
                type: Array,
                required: true
            },
            rooms: {
                type: Array,
                required: true
            },
            activeUsers: {
                type: Array,
                required: true
            },
            autoGenerateInvoices: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                autofill: true,
                allowFiltering: true,
                dateFormat: 'EEEE, MM/dd/yyyy',
                appointmentOptions: ['Individual', 'Group', 'Couple', 'Family', 'Other'],
                changes: {},
                eventData: { ...emptyEventData },
                changeTracker: {},
                shouldTrackChanges: false,
                selectedClients: [],
                initialChangeComplete: false,
                changesDirty: false,
                EventType,
                summaryNote: null,
                InvoiceStatus,
                clientAddedIndex: null,
                serviceAddedIndex: null,
                clientFields: { text: 'name', value: 'id' },
                serviceList: [],
                serviceFields: { text: 'descrip', value: 'id' },
                apptClients: [],
                apptServices: [],
                counselors: null,
                availableCounselors: null,
                selectAll: false,
                //modal stuff
                deleteClientOpen: false,
                deleteApptOpen: false,
                deleteServiceOpen: false,
                deletedService: {
                    id: null,
                    descrip: null,
                    index: null
                },
                deletedClient: {
                    name: null,
                    id: null,
                    appt_id: null,
                    index: null
                },
                clientOwes: 0,
                clientCredit: 0,
                balance: 0,
                paymentModalOpen: false,
                //MISC
                minTime: new Date('3/8/2199 7:00 AM'),
                maxTime: new Date('3/8/2199 9:00 PM'),
                timeStep: 15,
                actionButtons: [
                    { text: 'Progress Note' },
                    { separator: 'true' },
                    { text: 'Remove Client', disabled: false },
                    { separator: 'true' },
                    { text: 'Create Invoice' }
                ],
                attendanceWarningMessage: cancelApptMessage,
                attendancePayload: {},
                attendanceWarningOpen: false,
                selectedClientId: 0,
                selectedClientInvoiceId: 0,
                clientHasMadePayment: false,
                autopayWarningFee: '',
                autopayWarningName: '',
                autopayWarningOpen: false,
                autopayWarningPayload: [],
                attendanceChange: false,
                saveRecurringModalOpen: false,
                editRRuleModalOpen: false,
                placesOfService: [
                    {
                        code: '02',
                        name: 'Telehealth'
                    },
                    {
                        code: '11',
                        name: 'Office'
                    },

                ],
              disableClientActions: false,
              confirmDateChangeOpen: false,
              dateHasChanged: false,
              RecurrenceSaveType,
            };
        },

        computed: {
            ...mapState({
                user: 'user'
            }),

            virtualEvent() {
                if (this.eventType == 'EVENT' && this.eventData.location == '2') {
                    return true;
                }

                return false;
            },
            usersForAppt() {
                return this.users.users.map((clinician) => ({
                    id: clinician.id,
                    name: `${clinician.name}`
                    // $isDisabled: clinician.$isDisabled == true ? true : false
                }));
            },

            eventType() {
                return this.event?.type ?? EventType.APPOINTMENT;
            },

            deleteButtonText() {
                if (this.event?.type === EventType.EVENT) {
                    return 'Event';
                }

                return 'Appointment';
            },

            isGroupAppointment() {
                return this.event && this.event.clients && this.event.clients.length > 1;
            },

            selectedLocation() {
                if (this.changes.room) {
                    return parseInt(this.changes.room);
                } else return parseInt(this.changes.location);
            },

            clinicianList() {
                return this.clinicians.map((clinician) => ({
                    id: clinician.id,
                    name: `${clinician.first_name} ${clinician.last_name}`,
                    $isDisabled: clinician.$isDisabled == true ? true : false
                }));
            },

            userIsAdmin() {
                if (this.$store.state.user.role_id == 1) {
                    return true;
                } else return false;
            },

            userIsClinician() {
                return this.counselors.some((clinician) => clinician.id === this.$store.state.user.id);
            },

            roomOptions() {
                let result = this.rooms.filter((room) => room.active == 1);
                return result.map((room) => ({
                    // text: room.room_name,
                    // value: room.id
                    text: room.room_name == "Office" ? `11 - ${room.room_name}` : `02 - ${room.room_name}`,
                    value: room.id
                }));



            },
            placeOfServiceOptions() {
                // /let result = this.rooms.filter((room) => room.active == 1);
                return this.placesOfService.map((option) => ({
                    text: `${option.code} - ${option.name}`,
                    value: option.code
                }));

            },

            clientInfo() {
                return this.apptClients.map((client) => ({
                    name: client.name,
                    id: client.id,
                    value: client.id,
                    apptId: client.apptId,
                    progressNoteId: client.progressNoteId,
                    services: client.services,
                    pn_owner: client.pn_owner,
                    invoice_id: client.invoice_id,
                    apptStatus: client.apptStatus
                }));
            },

            serviceInfo() {
                return this.apptServices.map((service) => ({
                    name: service.descrip,
                    value: service.id
                }));
            },

            totalCharges() {
                let money = 0;
                this.apptServices.forEach((service) => {
                    money += service.cost;
                });
                return this.$getCurrency(money);
            },
            upcomingForDelete(){
                return dayjs(this.event.dayt_appt_start).isAfter(dayjs());
            },
            eventIsUpcoming() {
              const earliest = dayjs(this.event.dayt_appt_start).add(-15, 'minute');
              const latest = dayjs(this.event.dayt_appt_end).add(60, 'minute');

              const now = dayjs();
              return now.isBetween(earliest, latest);
            },

            changeTrackerDirty() {
                return Object.keys(this.changeTracker).length > 0;
            },

            canDelete() {
                let canDeleteAppt = true;
                this.apptClients.forEach((client) => {
                    //if any client has an invoice
                    if (client.invoice_id !== null) {
                        canDeleteAppt = false;
                    }
                    // if any client has a PN
                    if (client.pn_owner !== null) {
                        canDeleteAppt = false;
                    }
                    // if any client has attended
                    if (client.appt_status == 'Attended') {
                        canDeleteAppt = false;
                    }
                });

                return canDeleteAppt;
            },

            isRecurring() {
                return this.event?.recurring_appt ?? false;
            },
            rRuleButtonText() {
                return !this.eventData.recurrence_rule ? 'Add Recurring Appointments' : 'Edit Recurrence Rule';
            },
            shouldShowRRuleButton() {
                return this.eventType !== 'EVENT' && !this.event.recurring_locked && !this.dateHasChanged;
            },
            canDeleteEvent() {
                // Duplicated for now - will probably use separate logic in the future
                return this.eventType !== 'EVENT' && !this.event.recurring_locked;
            },

            disableRemoveClient(){
                if(this.event.appt_type == 'Individual'){
                    return true;
                }

                if(this.apptClients.length == 1){
                    return true;
                }

                return false;
            }

        },

        methods: {
            /*** Event Handlers ***/
            handleDeleteUser(index) {
                this.eventData.users.users.splice(index, 1);
            },

            handleAddUser() {
                this.eventData.users.users.push({ id: null, name: null });
                this.changesDirty = true;
            },

            handleMeetingHostSelection(e) {
                if (this.initialChangeComplete) {
                    this.changesDirty = true;
                }

                let selection = e;
                this.eventData.users.meetingHost = selection;

                // let selectionindex = this.clinicians.findIndex((host) => host.id == selection.id);
                // this.disableOption(selectionindex);
            },

            disableSelectedUsers() {
                this.clinicians.forEach((clinician) => {
                    clinician.$isDisabled = false;
                });

                let hostIndex = this.clinicians.findIndex((host) => host.id == this.eventData.users.meetingHost.id);
                this.disableOption(hostIndex);

                this.eventData.users.users.forEach((clinician, index) => {
                    let clinicianIndex = this.clinicians.findIndex(
                        (clinician) => clinician.id == this.users.users[index].id
                    );
                    this.disableOption(clinicianIndex);
                });
            },

            disableOption(index) {
                if (index > -1) {
                    let selection = this.clinicians[index];
                    delete selection.$isDisabled;
                    this.$set(this.clinicians, index, selection);
                }
                return;
            },

            handleUserSelection(e, index) {
                // e: {id: 16, name: 'Admin Salima', $isDisabled: false}
                let selection = e;
                // delete selection.$isDisabled;
                this.$set(this.eventData.users.users, index, selection);

                this.disableOption();
            },

            handleTitleChange(e) {
                this.eventData.title = e;

                if (this.shouldTrackChanges) {
                    this.$set(this.changeTracker, 'title', e);
                }
            },

            handleRequiredToggle() {
                this.$set(this.changeTracker, 'isRequired', this.eventData.isRequired);
            },

          async handleAttendanceChange(value, client, i, generateInvoice = false) {
              this.disableClientActions = true;
            const newStatus = value;
            value = client.apptStatus;

            if (newStatus === 'Attended' ) {
              try {
                this.attendanceChange = true;

                let paymentDetail = {}

                const hasInvoice = !!this.apptClients.find(c => c.id === client.id).invoice_id

                if (generateInvoice && !hasInvoice) {
                  paymentDetail.autopay = await this.checkAutopay(client.id)
                  paymentDetail.apptGroupUuid = this.eventData.group_uuid
                  paymentDetail.invoices = [{
                    invoice: {
                      office_id: this.event.office_id,
                      company_id: this.event.company_id,
                      client_id: client.id
                    },
                    progressNote: {
                      id: client.progressNoteId,
                      apptId: client.apptId
                    },
                    service_items: client.services.map((service) => service.service_item_id)

                  }]

                }

                const res = await this.$api.patch(appts.updateApptStatus(client.apptId), {
                    status: newStatus,
                    addCancelFee: false,
                    pageId: this.pageMeta.id,
                    generateInvoice,
                    paymentDetail
                });

                if (res.status < 200 || res.status >= 300) {
                  //this.$toasted.error('Failed to update status');
                  value = client.apptStatus;
                  return;
                }

                if (this.autoGenerateInvoices && hasInvoice){
                  this.$toasted.error('Invoice has already been generated for this appointment.')
                } else {
                  this.$toasted.success('Updated appointment status')
                }

                this.apptClients[i].apptStatus = newStatus;



              } catch (err) {
                //this.$toasted.error('Failed to update status');
                value = client.apptStatus;
              }
            } else {
              this.attendanceWarningMessage = newStatus === 'Cancelled' ? cancelApptMessage : noShowApptMessage;
              this.attendanceWarningOpen = true;

              this.attendancePayload = {
                status: newStatus,
                addCancelFee: true,
                client
              };
            }
            this.disableClientActions = false;
          },

            cancelAttendanceUpdate() {
                this.attendanceWarningOpen = false;
            },
            attendanceUpdated() {
                this.attendanceWarningOpen = false;

                if (this.attendancePayload.status == 'No Show') {
                    let client = this.attendancePayload.client;
                    let index = this.apptClients.findIndex((client) => client.id == this.attendancePayload.client.id);
                    let copy = this.apptClients[index];
                    copy.apptStatus = this.attendancePayload.status;
                    this.$set(this.apptClients[index], index, copy);
                    this.attendanceChange = true;
                    //this.apptClients[index].apptStatus = this.attendancePayload.status;
                    this.$emit('apptUpdated');
                    this.attendanceChange = true;

                    this.$toasted.success('Updated appointment status');
                }
                if (this.attendancePayload.status == 'Cancelled') {
                    this.attendanceChange = true;
                    let client = this.attendancePayload.client;
                    let index = this.apptClients.findIndex((client) => client.id == this.attendancePayload.client.id);
                    let copy = this.apptClients[index];
                    copy.apptStatus = this.attendancePayload.status;
                    this.$set(this.apptClients[index], index, copy);
                    this.$toasted.success('Updated appointment status');
                    this.attendanceChange = true;
                    this.$emit('apptUpdated');
                    this.attendanceChange = true;
                    this.changesDirty = false;
                }
            },

            async handleCounselorChange(event) {
                //stores temp variable of newly selected counselor
                // let id = event.target.value;
                let id = event.id;
                // finds index of id in array of clinicians[]... passed in thru props
                let index = this.clinicians.findIndex((item) => item.id == id); //find where id is in clinicians[]
                // finds index of changed id in counselors[]
                // items in counselors[] was only updating id, need to change other element properties
                let x = this.counselors.findIndex((item) => item.id == id); //find id in counselors
                if (x > -1) {
                    this.counselors[x].id = this.clinicians[index].id;
                    this.counselors[x].name = this.getCounselorName(index);
                }

                await this.$nextTick();

                this.$set(this.changeTracker, 'counselors_changed', [...this.counselors]);
            },

            handleServiceSelect(e, ndx) {
                let index = this.serviceList.findIndex((service) => service.service_code_id == e.id);
                let addedService = this.serviceList[index];
                // addedService.$isDisabled = true
                let copy = this.serviceList[index];
                copy.$isDisabled = true;
                this.$set(this.services, index, copy);

                if (this.shouldTrackChanges) {
                    if (!this.changeTracker.added_services) {
                        // if we're not already tracking added services, start tracking
                        this.$set(this.changeTracker, 'added_services', [{ ...copy }]);
                    } else {
                        // if the previous service has no id, we are appending a change, else we are updating the service where the tracked id matches
                        // the id of the services at the given index in this.apptServices
                        if (this.apptServices[ndx].id === null) {
                            this.$set(this.changeTracker, 'added_services', [
                                ...this.changeTracker.added_services,
                                { ...copy }
                            ]);
                        } else {
                            const trackedIdx = this.changeTracker.added_services.findIndex(
                                (s) => s.id === this.apptServices[ndx].id
                            );

                            const trackedServices = [...this.changeTracker.added_services];
                            trackedServices[trackedIdx] = { ...copy };

                            this.$set(this.changeTracker, 'added_services', trackedServices);
                        }
                    }
                }

                this.$set(this.apptServices, ndx, addedService);
            },

            handleDateChange(e) {
                if (this.shouldTrackChanges) {
                    const newDay = dayjs(e.value);
                    const currentStartTime = dayjs(this.eventData.dayt_appt_start);
                    const currentEndTime = dayjs(this.eventData.dayt_appt_end);

                    //update change tracker
                    this.$set(this.changeTracker, 'new_start_day', newDay.day());
                    this.$set(
                        this.changeTracker,
                        'dayt_appt_start',
                        new Date(
                            newDay.year(),
                            newDay.month(),
                            newDay.date(),
                            currentStartTime.hour(),
                            currentStartTime.minute()
                        ).toISOString()
                    );
                    this.$set(
                        this.changeTracker,
                        'dayt_appt_end',
                        new Date(
                            newDay.year(),
                            newDay.month(),
                            newDay.date(),
                            currentEndTime.hour(),
                            currentStartTime.minute()
                        ).toISOString()
                    );

                    //update event data - main use is for editing EVENTS
                    this.$set(
                        this.eventData,
                        'dayt_appt_start',
                        new Date(
                            newDay.year(),
                            newDay.month(),
                            newDay.date(),
                            currentStartTime.hour(),
                            currentStartTime.minute()
                        )
                    );
                    this.$set(
                        this.eventData,
                        'dayt_appt_end',
                        new Date(
                            newDay.year(),
                            newDay.month(),
                            newDay.date(),
                            currentEndTime.hour(),
                            currentStartTime.minute()
                        )
                    );

                    this.dateHasChanged = true;
                }
            },

            handleTimeChange(e, isEndTime = false) {
                if (this.shouldTrackChanges) {
                    const changedTime = dayjs(e.value);

                    if (isEndTime) {
                        this.$set(this.changeTracker, 'dayt_appt_end', changedTime.format());
                        this.$set(
                            this.eventData,
                            'dayt_appt_end',
                            new Date(
                                changedTime.year(),
                                changedTime.month(),
                                changedTime.date(),
                                changedTime.hour(),
                                changedTime.minute()
                            )
                        );
                    } else {
                        this.$set(this.changeTracker, 'dayt_appt_start', changedTime.format());
                        this.$set(this.changeTracker, 'dayt_appt_end', changedTime.add(1, 'hour').format());

                        //update event data - main use of this variable is for editing EVENTS
                        let changedTimeAddHour = dayjs(e.value).add(1, 'hour');
                        this.eventData.dayt_appt_end = new Date(
                            changedTimeAddHour.year(),
                            changedTimeAddHour.month(),
                            changedTimeAddHour.date(),
                            changedTimeAddHour.hour(),
                            changedTimeAddHour.minute()
                        );
                    }
                }
                this.changesDirty = true;
            },

            async handleActionSelect(args, client, i) {
                switch (args.item.text) {
                    //identifies by name of item selected
                    case 'Progress Note':

                        //use store set current client_id
                        await this.$store.commit('setCurrentClientId',  {payload: client.id});

                        // if no p-note ID is attached to appt, start a new one
                        if (!client.progressNoteId) {
                            this.navigateToNewProgressNote(client);
                        }
                        // else, edit existing p-note
                        else {
                            this.navigateToExistingProgressNote(client);
                        }
                        break;
                    // case 'Cancel Appointment':
                    //     this.apptClients[i].readOnly = true;
                    //     break;

                    case 'Remove Client':
                        this.handleRemoveClient(client);
                        break;

                    case 'Create Invoice':
                        const autopayActive = await this.checkAutopay(client.id);

                        if (autopayActive) {
                            const feeForClient = client.services.reduce((tot, service) => tot + service.retail_cost, 0);
                            this.autopayWarningFee = this.$getCurrency(feeForClient);
                            this.autopayWarningName = client.name;
                            this.autopayWarningPayload = [client];
                            this.autopayWarningOpen = true;
                        } else {
                            await this.invoiceClients([client], i);
                        }
                        break;

                    case 'Pay Invoice':
                        this.openPayment(client);
                        break;

                    case 'View Invoice':
                        this.navigateToInvoice(client.services[0].invoice_id);
                        break;
                    case 'View Client':
                        this.nav2client(client.id);
                        break;
                }
            },

            // async handleBeforeOpen(args, client) {
            //     this.actionButtons = [
            //         { text: 'Progress Note' },
            //         { separator: 'true' },
            //         { text: 'Remove Client', disabled: this.disableRemoveClient },
            //         { separator: 'true' },
            //         { text: 'Create Invoice' }
            //     ];
            //
            //     if (this.$store.state.user.role_id >= 14 && !client.progressNoteId) {
            //         let object = { text: 'Progress Note', disabled: true };
            //         this.$set(this.actionButtons, 0, object);
            //     }
            //
            //     if (client.invoice_id !== null) {
            //         let item = { text: 'Pay Invoice' };
            //         let index = this.actionButtons.findIndex((item) => item.text == 'Create Invoice');
            //         this.$set(this.actionButtons, index, item);
            //
            //         let ndx2 = this.actionButtons.findIndex((item) => item.text == 'Remove Client');
            //         this.actionButtons[ndx2].disabled = true;
            //
            //         //add new option to view newly created invoice
            //         item = { text: 'View Invoice' };
            //         this.actionButtons.push(item);
            //     }
            //
            //     if (client.apptStatus !== null || client.progressNoteId !== null) {
            //         let ndx2 = this.actionButtons.findIndex((item) => item.text == 'Remove Client');
            //         this.actionButtons[ndx2].disabled = true;
            //     }
            // },

            handleActionClose() {
                //resets action buttons to default
                this.actionButtons = [
                    { text: 'Progress Note' },
                    { separator: 'true' },
                    { text: 'Remove Client', disabled: false },
                    { separator: 'true' },
                    { text: 'Create Invoice' }
                ];
            },

            async handleDeleteAppt(saveType) {
                const payload = {
                    changeType: saveType,
                    eventMetadata: this.event,
                    changes: {
                        delete_appts: true
                    }
                };

                const res = await this.$api.put(appts.batchUpdate(), payload);

                if (res.status < 300) {
                    this.$toasted.success('Appointment(s) deleted');
                    this.changeTracker = {};
                    this.$emit('apptUpdated');
                    this.handleEventDetailClose();
                } else if (res.status === 400) {
                    this.$toasted.error(res.data[0]);
                } else {
                    this.$toasted.error('There was an error saving your changes. Please try again later.');
                }
            },

            async handleSubmitUpdate() {
                if (this.event.type === EventType.EVENT) {
                    // TODO: Send event update req
                } else if (this.event.type === EventType.APPOINTMENT) {
                    /** if there are new clients that need to be added to the appt **/
                    //get the new clients

                    /** if there are new services that need to be added to the appt **/
                        // get the new services
                    let newServices = [...this.apptServices.slice(this.serviceAddedIndex)];

                    //apppend new service to the appt
                    let appts1 = [];
                    this.apptClients.forEach((client) => {
                        appts1.push({
                            apptId: client.apptId,
                            clientId: client.id,
                            invoiceId: client.invoice_id,
                            progressNoteId: client.progressNoteId,
                            services: newServices
                        });
                    });

                    await this.$api.post('/appts/add-service-items', {
                        appts: appts1
                    });

                    /** make api call to create appts for new clients with:
                     *    1. same group uuid
                     *    2. all pre-existing services
                     *    3. all new services (if they exist, ofc)
                     **/

                    let newClients = [...this.apptClients.slice(this.clientAddedIndex)];
                    let clientsAdded = [];
                    newClients.forEach((obj) => {
                        clientsAdded.push(obj.id);
                    });

                    let servicesForNewClients = [];
                    this.apptServices.forEach((service) => {
                        let service2add = {};
                        service2add.cost = service.cost;
                        service2add.id = service.serviceCodeId;
                        service2add.retail_cost = service.cost;
                        servicesForNewClients.push(service2add);
                    });

                    if (clientsAdded.length >= 1 && servicesForNewClients.length >= 1) {
                        this.createNewAppointment(clientsAdded, servicesForNewClients);
                        return;
                    }

                    await this.$api.put(appts.updateApptGroup(this.event.group_uuid), {
                        Subject: this.changes.title,
                        Location: this.changes.office_id,
                        dayt_appt_start: this.changes.dayt_appt_start,
                        dayt_appt_end: this.changes.dayt_appt_end,
                        clients: this.apptClients,
                        counselors: JSON.stringify(this.counselors),
                        services: servicesForNewClients,
                        room: parseInt(this.changes.room),
                        isRequired: this.changes.isRequired ? 1 : 0,
                        telehealth: this.changes.room == '2' ? 1 : 0,
                        type: this.changes.appt_type,
                        groupUuid: this.changes.group_uuid,
                        notes: this.summaryNote
                    });

                    this.$toasted.success(`Appointment has been updated`);
                } else {
                    //this.$toasted.error('Invalid event type');
                }
            },

            async handleSaveChanges() {
                const isValidAppt = this.validateUpdate();

                if (!isValidAppt) {
                    return;
                }

                if (this.event.recurring_appt) {
                    if (this.dateHasChanged) {
                        this.confirmDateChangeOpen = true;
                    } else {
                        this.saveRecurringModalOpen = true;
                    }
                } else {
                    await this.saveEdits();
                }
            },

            async handleRecurrenceSaveMethodSelected(method) {
                await this.saveEdits(method);
                this.saveRecurringModalOpen = false;
                this.confirmDateChangeOpen = false;
            },

            getRoomId(location){
                let index
                switch(location){
                    case 'office':
                        index = this.rooms.findIndex(room => room.room_name === "Office")
                        if(index > -1){
                            return this.rooms[index].id
                        }
                        break;
                    case 'telehealth':
                        index = this.rooms.findIndex(room => room.room_name === "Telehealth")
                        if(index > -1){
                            return this.rooms[index].id
                        }
                        break;
                }

            },

            handleRoomChange(e) {
                if (this.shouldTrackChanges) {
                    // set changes in change tracker
                    this.$set(this.changeTracker, 'place_of_service_code', e);
                    //updates "room" in changeTracker...  a temp solution till we figure this whole room thing out
                    let room_id
                    switch(e){
                        case "02":
                            room_id = this.getRoomId('telehealth')
                            this.$set(this.changeTracker, 'room', room_id);
                            break;
                        case "11":
                            room_id = this.getRoomId('office')
                            this.$set(this.changeTracker, 'room', room_id);
                            break;
                    }

                }
            },

            async handlePaymentModalClose() {
                this.paymentModalOpen = false;
            },

            async handleAddService() {
                //when the "add service" button is clicked
                if (this.clientHasMadePayment == true) {
                    this.$toasted.error(
                        `Services cannot be edited if a member has made a payment toward the invoice for this appointment.`
                    );
                    return;
                }

                this.apptServices = [
                    ...this.apptServices,
                    {
                        cost: null,
                        descrip: null,
                        id: null,
                        serviceCodeId: null,
                        newService: true
                    }
                ];
                this.changesDirty = true;
            },

            handleAddCounselor() {
                //when the "add counselor" button is clicked
                this.counselors = [
                    ...this.counselors,
                    {
                        host: false,
                        id: null,
                        name: null
                    }
                ];
            },

            async handleRemoveCounselor(index) {
                this.counselors.splice(index, 1);

                await this.$nextTick();

                this.$set(this.changeTracker, 'counselors_changed', [...this.counselors]);
            },

            handleDeleteModalOpen() {
                this.deleteApptOpen = true;
            },

            handleDeleteServiceModalOpen(service, i, serviceCode) {
                // stop tracking added change
                if (this.changeTracker.added_services) {
                    const updatedServiceTracking = this.changeTracker.added_services.filter((s) => s.id !== service.id);

                    if (updatedServiceTracking.length === 0) {
                        this.$delete(this.changeTracker, 'added_services');
                    } else {
                        this.$set(this.changeTracker, 'added_services', updatedServiceTracking);
                    }
                }

                //if the service is "new" and not attached to the appt yet...
                if (i >= this.serviceAddedIndex) {
                    if (service.name == null) {
                        this.apptServices.pop();
                        return;
                    }

                    let ndx = this.serviceList.findIndex(
                        (serviceListItem) => serviceListItem.service_code_id == service.value
                    );
                    let copy = this.serviceList[ndx];
                    copy.$isDisabled = true;
                    this.$set(this.serviceList, ndx, copy);
                    this.apptServices.splice(i, 1);

                    return;
                }

                //open the actual modal, pass clientInfo back down to be "deleted"
                this.deleteServiceOpen = true;
                this.deletedService = {
                    id: service.value,
                    descrip: service.name,
                    index: i,
                    codeId: serviceCode.serviceCodeId
                };
            },

            async handleDeletedSelectedClient(client) {
                try {
                    //delete client & emit event back up
                    await this.$api.post(appts.deleteClientFromAppt(), client);
                    // await this.$emit('clientJustDeleted', this.event.appt_id, client);
                    this.$emit('apptUpdated');
                    this.$toasted.success(`Appointment has been updated`);
                    //this.$toasted.error(`${client.name} has been deleted from this appt`);
                } catch (err) {
                    //this.$toasted.error('Could not delete client. Please try again');
                }
                //close the modal
                this.deleteClientOpen = false;
            },

            async handleDeletedSelectedService(service) {


                // if we're not already tracking removed services, start tracking
                if (!this.changeTracker.removed_services) {
                    this.$set(this.changeTracker, 'removed_services', [{ serviceCodeId: service.codeId }]);
                } else {
                    // check to see if we're already tracking and delete for this service code
                    const trackedChange = this.changeTracker.removed_services.find(
                        (s) => s.serviceCodeId === service.codeId
                    );

                    // if not - add service code to tracking
                    if (!trackedChange) {
                        this.$set(this.changeTracker, 'removed_services', [
                            ...this.changeTracker.removed_services,
                            { serviceCodeId: service.codeId }
                        ]);
                    }
                }

                // remove service from appt services
                this.apptServices = this.apptServices.filter((s) => s.serviceCodeId !== service.codeId);
                this.deleteServiceOpen = false;

                let serviceIndex = this.serviceList.findIndex((s) => s.codeId == service.service_code_id);
                if(serviceIndex > -1){
                    let copy = this.serviceList[serviceIndex];
                    copy.$isDisabled = false;
                    this.$set(this.serviceList, serviceIndex, copy);
                }
            },

            handleClientChange(e, index) {
                if (this.shouldTrackChanges) {
                    const prevClient = this.apptClients[index];

                    // if prevClient has a null apptId - this is coming from an added client, not a changed client
                    if (prevClient.apptId === null) {
                        // if not currently tracking added_clients - create that prop on the changeTracker and add this clientId to it
                        if (!this.changeTracker.added_clients) {
                            this.$set(this.changeTracker, 'added_clients', [e.id]);
                            this.$set(this.apptClients, index, { apptId: null, id: e.id, name: e.name });
                            return;
                        }

                        // check if you're editing an already added client
                        const addedClientIdx = this.changeTracker.added_clients.findIndex(
                            (c) => c === prevClient.clientId
                        );

                        // if editing an already added client, just edit that entry, else add a new entry
                      if (addedClientIdx >= 0) {
                        this.$set(this.changeTracker.added_clients, addedClientIdx, e.id);
                      } else {
                        const oldApptClients = this.event.clients.map((c) => c.id);
                        const newApptClients = this.apptClients.map((c, i) => {
                          if (i === index) {
                            return e.id;
                          } else {
                            return c.id;
                          }
                        });

                        const addedClients = newApptClients
                            .filter((c) => !oldApptClients.includes(c) &&  c !== null)

                        this.$set(this.changeTracker, 'added_clients', addedClients);
                      }

                      // update apptClients so the dropdown reflects the change
                      this.$set(this.apptClients, index, { apptId: null, id: e.id, name: e.name });
                      return;
                    }

                    // if we get here, we know we're dealing with a changed client and not an added one
                    // check to see if changed clients is being tracked
                    if (!this.changeTracker.changed_clients) {
                        // if not - start tracking changed clients and add this client to the list of changes
                        this.$set(this.changeTracker, 'changed_clients', [
                            { apptId: prevClient.apptId, clientId: e.id }
                        ]);

                        // update apptClients so the dropdown reflects the change
                        this.$set(this.apptClients, index, { ...prevClient, id: e.id, name: e.name });
                        return;
                    }

                    // check to see if we're changing a client that we're already tracking or not
                    const changedApptIdx = this.changeTracker.changed_clients.findIndex(
                        (c) => c.apptId === prevClient.apptId
                    );

                    // if we're already tracking change, just update the client id there, else push a new change entry
                    if (changedApptIdx >= 0) {
                        this.$set(this.changeTracker.changed_clients, changedApptIdx, {
                            ...this.changeTracker.changed_clients[index],
                            clientId: e.id
                        });
                    } else {
                        const changedClients = [...this.changeTracker.changed_clients];
                        changedClients.push({ apptId: prevClient.apptId, clientId: e.id });

                        this.$set(this.changeTracker, 'changed_clients', changedClients);
                    }

                    // update apptClients so the dropdown reflects the change
                    this.$set(this.apptClients, index, { ...prevClient, id: e.id, name: e.name });
                }
            },

            handleAddClient() {
                this.apptClients.push({
                    id: null,
                    apptId: null,
                    name: null
                });
            },

            handleEventDetailClose() {
                this.changeTracker = {};
                if (this.eventType !== 'EVENT') {
                    this.eventData = { ...emptyEventData };
                }
                this.shouldTrackChanges = false;
                this.deleteApptOpen = false;
                this.$emit('close');
            },

            handleSummaryChange(e) {
                if (this.shouldTrackChanges) {
                    const newVal = e.target.value;
                    this.$set(this.changeTracker, 'changed_summary', newVal);
                }
            },

            /*** Utility Methods ***/
            validateUpdate() {
                // if event
                if (this.eventType == 'EVENT') {
                    //if any user is added but currently not selected
                    if (this.eventData.users.users.find((user) => user.id === null)) {
                        this.$toasted.error(`When adding users, make sure that all users are selected.`);
                        return false;
                    }

                    return true;
                }

                if (this.apptClients.length === 0) {
                    this.$toasted.error('You cannot save an appointment with no clients');
                    return false;
                }

                if (this.apptServices.length === 0) {
                    this.$toasted.error('You cannot save an appointment with no services');
                    return false;
                }

                if (this.clinicians.length === 0) {
                    this.$toasted.error('You cannot save an appointment with no counselors');
                    return false;
                }

                return true;
            },

            getFreshEndTime(newStart) {
                return newStart.add(1, 'hour').format();
            },

            // attendedDisabled(client) {
            //     return client.apptStatus === 'No Show' || client.apptStatus === 'Cancelled';
            // },

            getCounselorName(i) {
                return this.clinicians[i].first_name + ' ' + this.clinicians[i].last_name;
            },

            async checkAutopay(clientId) {
                const res = await this.$api.get(payments.checkAutopay(clientId));
                if (res.status < 300) {
                    return res.data.autopayActive;
                } else {
                    this.$toasted.error('Error checking autopay status');
                    return false;
                }
            },

            openPayment(client) {
                this.selectedClientId = client.id;
                this.selectedClientInvoiceId = client.invoice_id;
                this.getBalance(client.invoice_id);
            },

            async createNewAppointment(addedClients, servicesForNewClients) {
                try {
                    await this.$api.post('/appts/create-appointment', {
                        subject: this.event.title,
                        room: this.event.room,
                        startTime: this.changes.dayt_appt_start,
                        endTime: this.changes.dayt_appt_end,
                        clients: Array.isArray(addedClients) ? addedClients : [addedClients],
                        counselors: this.changes.counselors,
                        services: servicesForNewClients,
                        type: this.changes.appt_type,
                        telehealth: this.event.telehealth,
                        groupUuid: this.changes.group_uuid,
                        notes: this.summaryNote ? this.summaryNote : null
                    });
                    this.$toasted.success(`Successfully added client(s) to this appointment`);
                    this.$emit('apptUpdated');
                } catch (err) {
                    this.$cl(err);
                }
            },

            navigateToInvoice(invoiceId) {
                this.$router.push({ path: `/invoices/${invoiceId}` });
            },
            nav2client(client_id) {
                this.$router.push({ path: `/client/${client_id}` });
            },
            async navigateToNewProgressNote(client) {

                //gets index of client from the event
                let index = this.event.clients.findIndex((findClient) => findClient.appt_id == client.apptId);

                // dispatch action to get appt data
                await this.$store.dispatch('progressNote/getApptData', {
                    payload: {
                        apptId: client.apptId,
                    }
                })

                await this.$router.push({
                    name: 'ProgressNote',
                    params: {
                        id: 0,
                        apptGroupUuid: this.event.group_uuid
                    },
                    query: {
                        client_id: client.value,
                        // appt_id: client.apptId,
                        // offices: this.offices,
                        // invoice_id: client.invoice_id,
                        // selectedServices: this.event.clients[index].services,
                        // availableServices: this.services
                    }
                });
            },

            async navigateToExistingProgressNote(clientInfo) {

                await Promise.all([
                    // send client info to vuex store
                     this.$store.commit('progressNote/updateClient', { payload: clientInfo}),

                    // dispatch action to get appt data
                    await this.$store.dispatch('progressNote/getApptData', {
                        payload: {
                            apptId: clientInfo.apptId,
                        }
                    })
                ])

                // navigate to progress note
                this.$router.push({
                    name: 'ProgressNote',
                    params: { id: clientInfo.progressNoteId },
                    query: {
                        client_id: clientInfo.value,
                        appt_id: clientInfo.apptId
                    }
                });
            },

            async getBalance(invoiceId) {
                // get balance from invoice using the invoice_id
                const res = await this.$api.get(invoices.getInvoice(invoiceId));
                this.clientOwes = res.data.amount_owed;
                this.clientCredit = res.data.amount_paid;
                this.paymentModalOpen = true; // opens the payment modal
            },

            handleRemoveClient(client, confirmedByModal = false) {
                const apptClientIdx = this.apptClients.findIndex((c) => c.id === client.id);

                // if appt id is null we know we added this client and haven't saved them yet (new client)
                if (client.apptId === null) {
                    // remove them from change tracking
                    this.$set(
                        this.changeTracker,
                        'added_clients',
                        this.changeTracker.added_clients.filter((c) => c !== client.id)
                    );

                    // remove them from apptClients
                    this.apptClients = this.apptClients.filter((_, idx) => idx !== apptClientIdx);

                    // stop tracking added clients if this brings the changes down to 0
                    if (this.changeTracker.added_clients.length === 0) {
                        this.$delete(this.changeTracker, 'added_clients');
                    }

                    return;
                }

                // if we've confirmed delete through the modal, track removed client - else open up the modal
                if (confirmedByModal) {
                    this.deleteClientOpen = false;

                    // if we're not tracking any removed clients yet, just start tracking, else verify it's not a dupe and add to tracking array
                    if (!this.changeTracker.removed_clients) {
                        this.$set(this.changeTracker, 'removed_clients', [{ apptId: client.apptId }]);
                    } else {
                        const alreadyRemoved = this.changeTracker.removed_clients.find(
                            (c) => c.apptId === client.apptId
                        );

                        if (!alreadyRemoved) {
                            this.$set(this.changeTracker, 'removed_clients', [
                                ...this.changeTracker.removed_clients,
                                { apptId: client.apptId }
                            ]);
                        }
                    }

                    this.apptClients = this.apptClients.filter((_, idx) => idx !== apptClientIdx);
                } else {
                    this.deleteClientOpen = true;
                    this.deletedClient = {
                        name: client.name,
                        id: client.id,
                        apptId: client.apptId
                    };
                }
            },

            async invoiceClients(client, isAutopay = false) {
                try {
                    this.autopayWarningOpen = false;

                    await this.$api.post(invoices.createInvoices(), {
                        autopay: isAutopay,
                        apptGroupUuid: this.event.group_uuid,
                        invoices: [
                            {
                                invoice: {
                                    office_id: this.event.office_id,
                                    company_id: this.event.company_id,
                                    client_id: client[0].id
                                },
                                progressNote: {
                                    id: client[0].progressNoteId,
                                    apptId: client[0].apptId
                                },
                                service_items: client[0].services.map((service) => service.service_item_id)
                            }
                        ]
                    });
                    this.$toasted.success(`An invoice has been created for ${client[0].name}`);
                    this.$emit('invoicesCreated', this.event.appt_id);
                } catch (err) {
                    console.log(err);
                    //this.$toasted.error('Failed to generate invoices - please try again later');
                }

                this.selectAll = false;
                this.selectedClients = [];
            },

            getInvoiceStatus(client) {
                if (client.services?.length > 0) {
                    if (client.services[0].invoice_id) {
                        if (client.services[0].invoice_paid) {
                            return InvoiceStatus.PAID;
                        } else {
                            return InvoiceStatus.PENDING_PAYMENT;
                        }
                    } else {
                        return InvoiceStatus.NOT_INVOICED;
                    }
                }
            },

            // checkClientSelectionLocked(client) {
            //     return client.readOnly || client.progressNoteId || client.apptStatus;
            // },

            async saveEdits(changeType = null) {
                if (this.event.type == 'EVENT') {
                    this.saveEventChanges();
                    return;
                }

                const payload = {
                    changes: this.changeTracker,
                    eventMetadata: this.event,
                    changeType: changeType ? changeType : RecurrenceSaveType.OnlyCurrent
                };

                const res = await this.$api.put(appts.batchUpdate(), payload);

                if (res.status < 300) {
                    this.$toasted.success('Successfully saved updates');
                    this.changeTracker = {};
                    this.$emit('apptUpdated');
                } else if (res.status === 400) {
                    this.$toasted.error(res.data[0]);
                } else {
                    this.$toasted.error('There was an error saving your changes. Please try again later.');
                }
            },

            toggleRRuleEditModal() {
                this.editRRuleModalOpen = !this.editRRuleModalOpen;
            },
            closeEditRRuleModal() {
                this.editRRuleModalOpen = false;
            },
            async handleRRuleChangeSave(rrule) {
                this.event.rrule = rrule;

                const res = await this.$api.put(appts.editRecurrenceRule(), {
                    recurrenceRule: rrule,
                    eventMetadata: { ...this.event, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, utcOffset: new Date().getTimezoneOffset() }
                });

                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error('There was an error saving your changes.');
                    this.closeEditRRuleModal();
                } else {
                    this.$emit('apptUpdated');
                    this.$toasted.success('Successfully saved changes');
                    this.closeEditRRuleModal();
                }


            },
            async deleteRRule() {
                const res = await this.$api.put(appts.removeRecurrenceRule(), { eventMetadata: this.event });
                if (res.status < 200 || res.status >= 300) {

                    this.$toasted.error('There was an error saving your changes.');
                } else {
                    this.$toasted.success('Successfully deleted future events in this rule');
                    this.closeEditRRuleModal();
                    this.$emit('apptDeleted')

                }

            },

            // handles saving events
            async saveEventChanges() {
                //remove unnecessary stuff
                delete this.eventData.users.meetingHost.$isDisabled;
                this.eventData.users.users.forEach((user) => {
                    delete user.$isDisabled;
                });

                //save to DB
                try {
                    await this.$api.put(events.updateEvent(), {
                        id: this.eventData.id,
                        event: this.eventData.title,
                        location: parseInt(this.eventData.location),
                        dayt_appt_start: this.eventData.dayt_appt_start,
                        dayt_appt_end: this.eventData.dayt_appt_end,
                        users: JSON.stringify(this.eventData.users)
                    });

                    this.$emit('eventUpdated');
                    this.$toasted.success(`Successfully saved updates`);
                } catch (err) {
                    this.$cl(`Error saving the event changes `, err);
                    this.$toasted.error(`There was an error updating your changes, please try again.`);
                }
            }
        },

        watch: {
            async open(newVal, oldVal) {
                if (newVal === oldVal) {
                    return;
                }

                this.disableClientActions = false

                if (newVal) {
                    this.dateHasChanged = false;

                    //populates apptClients[]
                    this.apptClients = this.event.clients
                        ? this.event.clients.map((client) => ({
                            id: client.id,
                            apptStatus: client.appt_status,
                            apptId: client.appt_id,
                            name: client.first_name + ' ' + client.last_name,
                            services: client.services,
                            progressNoteId: client.pn_id,
                            pn_owner: client.pn_owner_id,
                            invoice_id: client.invoice_id,
                            readOnly: client.invoice_id == null ? false : true
                        }))
                        : [];

                    // disable selected clients from being selected in the dropdown
                    this.apptClients.forEach((client) => {
                        let ndx = this.clients.findIndex((clientListItem) => clientListItem.id == client.id);
                        let copy = this.clients[ndx];
                        copy.$isDisabled = true;
                        this.$set(this.clients, ndx, copy);
                    });

                    //populate serviceList[]
                    this.serviceList = this.services
                        ? this.services.map((service) => ({
                            id: service.service_code_id,
                            descrip:
                                service.service_code +
                                ' ' +
                                (service.descrip ? service.descrip : service.long_descrip),
                            service_code_id: service.service_code_id,
                            service_code: service.service_code,
                            cost: service.cost,
                            level: service.level,
                            $isDisabled: false
                        }))
                        : [];

                    // Can't add services or remove any clients if any client has made a payment?
                    // TODO: Create a lighter API for this - this one is pretty heavy
                    this.clientHasMadePayment = false;

                    await Promise.allSettled(
                        this.apptClients.map(async (client) => {
                            if (client.invoice_id !== null && !this.clientHasMadePayment) {
                                const res = await this.$api.get(`invoices/${client.invoice_id}`);

                                if (res.status < 300 && res.data.amount_paid > 0) {
                                    this.clientHasMadePayment = true;
                                }
                            }
                        })
                    );

                    //populates apptServices[]
                    this.apptServices = this.event.services
                        ? this.event.services.map((service) => ({
                            id: service.service_item_id,
                            descrip: service.code + ' ' + service.name,
                            cost: service.actual_cost,
                            serviceCodeId: service.serviceCodeId,
                            // readOnly: this.clientHasMadePayment,
                            disable: true,
                            $isDisabled: true
                        }))
                        : [];

                    //disables selections in list
                    this.apptServices.forEach((service) => {
                        let ndx = this.serviceList.findIndex(
                            (serviceListItem) => serviceListItem.id == service.serviceCodeId
                        );
                        let copy = this.serviceList[ndx];

                        if (copy) {
                            copy.$isDisabled = true;
                            this.$set(this.serviceList, ndx, copy);
                        }
                    });

                    //gets index of added clients for apptClients[] slicing
                    if (this.clientAddedIndex == null) {
                        this.clientAddedIndex = this.apptClients.length;
                    }

                    //gets index of added clients for apptServices[] slicing
                    this.serviceAddedIndex = this.apptServices.length;

                    this.summaryNote = this.event.appt_notes;
                    //appends counselors
                    this.counselors = this.event.counselors;

                    await Vue.nextTick();
                    this.initialChangeComplete = true;
                    this.changesDirty = false;
                    this.selectedAttendance = this.event.appt_status;

                    if (this.changes.location) {
                        this.changes.location = parseInt(this.changes.location);
                    }

                    this.attendanceChange = false;
                } else {
                    this.initialChangeComplete = false;
                    this.clientAddedIndex = null;
                    this.attendanceChange = false;
                    this.changesDirty = false;
                }
            },

            async event(newVal) {
                /** Delete This **/
                const changes = {
                    ...newVal,
                    dayt_appt_start: dayjs(newVal.dayt_appt_start).format(),
                    dayt_appt_end: dayjs(newVal.dayt_appt_end).format(),
                    Guid: undefined,
                    Id: undefined
                };
                delete changes.Id;
                delete changes.Guid;
                this.changes = changes;
                /** Delete Above **/

                this.eventData = {
                    ...emptyEventData,
                    ...newVal,
                    title: newVal.type === EventType.EVENT ? newVal.event : newVal.appointment,
                    appt_type: newVal.type === EventType.EVENT ? 'Other' : newVal.appt_type
                };

                if (this.event.appt_type == 'Individual') {
                    this.$emit('getClientServiceFees', this.apptClients[0].id);
                }

                this.apptClients = this.eventData.clients.map((client) => ({
                    id: client.id,
                    apptStatus: client.appt_status,
                    apptId: client.appt_id,
                    name: client.first_name + ' ' + client.last_name,
                    services: client.services,
                    progressNoteId: client.pn_id,
                    pn_owner: client.pn_owner_id,
                    invoice_id: client.invoice_id,
                    readOnly: client.invoice_id == null ? false : true
                }));

                await this.$nextTick();
                this.shouldTrackChanges = true;
            },

            attendanceChange() {
                this.changesDirty = false;
            },

            eventData: {
                deep: true,
                handler() {
                    if (this.event !== this.eventData) {


                        if (this.open == false) {
                            return;
                        }

                        // this.disableSelectedUsers();
                        this.changesDirty = true;

                        //resets all disabled selections when choosing meeting host & users
                        this.activeUsers.forEach((user, index) => {
                            let user_new;
                            user_new = {
                                id: user.id,
                                name: user.name,
                                $isDisabled: false
                            };
                            if (user.id !== null) {
                                this.$set(this.activeUsers, index, user_new);
                            }
                        });

                        //disables current host selected from being selected as an option
                        let hostIndex = this.activeUsers.findIndex((user) => user.id === this.eventData?.users?.meetingHost?.id);
                        if (hostIndex > -1) {
                            let copy = this.activeUsers[hostIndex];
                            copy.$isDisabled = true;
                            this.$set(this.activeUsers, hostIndex, copy);
                        }

                        // iterate thru users and disable them from being added twice
                        this.eventData?.users?.users?.forEach((userSelected) => {
                            if (userSelected.id !== null) {
                                let userIndex = this.activeUsers.findIndex((user) => user.id === userSelected.id);
                                if (userIndex > -1) {
                                    let copy = this.activeUsers[userIndex];
                                    copy.$isDisabled = true;
                                    this.$set(this.activeUsers, userIndex, copy);
                                }
                            }

                        });

                        return;
                    }


                    this.changesDirty = false;
                }
            },
            changes: {
                deep: true,
                handler() {
                    if (!this.initialChangeComplete) {
                        return;
                    }

                    if (this.eventType !== 'EVENT') {
                        this.disableSelectedUsers();
                    }
                    //this.changesDirty = true;
                }
            },

            apptClients: {
                deep: true,
                handler: function() {
                    if (this.attendanceChange == true) {
                        this.changesDirty = false;
                        this.$emit('apptUpdated');
                    }

                    this.clients.forEach((client) => (client.$isDisabled = false));
                    this.apptClients.forEach((client) => {
                        let clientListIndex = this.clients.findIndex(
                            (clientListItem) => clientListItem.id == client.id
                        );
                        if (clientListIndex > -1) {
                            let copy = this.clients[clientListIndex];
                            copy.$isDisabled = true;
                            this.$set(this.clients, clientListIndex, copy);
                        }
                    });
                }
            },

            apptServices() {
                this.services.forEach((service) => (service.$isDisabled = false));
                this.apptServices.forEach((service) => {
                    let serviceListIndex = this.services.findIndex(
                        (serviceListItem) => serviceListItem.id == service.id
                    );
                    if (serviceListIndex > -1) {
                        let copy = this.services[serviceListIndex];
                        copy.$isDisabled = true;
                        this.$set(this.services, serviceListIndex, copy);
                    }
                });
            },

            counselors() {
                this.changesDirty = true;

                if (this.clinicians) {
                    this.clinicians.forEach((clinician) => (clinician.$isDisabled = false));
                }

                if (this.counselors) {
                    this.counselors.forEach((counselor) => {
                        let clinicianListIndex = this.clinicians.findIndex(
                            (clinicianListItem) => clinicianListItem.id == counselor.id
                        );
                        if (clinicianListIndex > -1) {
                            let copy = this.clinicians[clinicianListIndex];
                            copy.$isDisabled = true;
                            this.$set(this.clinicians, clinicianListIndex, copy);
                        }
                    });
                }
            },


            selectAll(newVal) {
                if (newVal) {
                    const selectedClients = [];
                    for (const client of this.event.clients) {
                        if (this.getInvoiceStatus(client) === InvoiceStatus.NOT_INVOICED) {
                            selectedClients.push(client);
                        }
                    }
                    this.selectedClients = selectedClients;
                } else {
                    if (this.selectedClients.length === this.event.clients.length) {
                        this.selectedClients = [];
                    }
                }
            },

            selectedClients(newVal, prevVal) {
                if (this.selectedClients.length === this.event.clients.length) {
                    this.selectAll = true;
                }
                if (newVal.length < prevVal.length) {
                    this.selectAll = false;
                }
            },

            summaryNote() {
                this.changesDirty = true;
                this.changes.appt_notes = this.summaryNote;
            }
        }
    };
</script>
