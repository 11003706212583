<template>
    <div class="rRule-modal">
        <Modal @close="handleClose">
            <h1>Edit this Event's Recurrence Rules</h1>

            <RecurrenceEditor
                @rRule="handleRuleUpdate"
                @validityUpdate="handleValidityUpdate"
                :initial="initialRule"
                :startDate="startDate"
            />

            <button
                v-if="initialRuleStr"
                class="red-btn initialRuleStr-btn"
                @click="handleDelete"
            >
                Delete Recurring Appointments
            </button>
            <button
                class="secondary"
                @click="handleClose"
            >
                Cancel
            </button>
            <button
                class="primary"
                :class="{ 'disabled-content': submitDisabled }"
                :disabled="submitDisabled"
                :title="submitDisabled ? 'Invalid Recurrence' : ''"
                @click="handleSubmit"
            >
                Save
            </button>
        </Modal>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import  { RRule } from 'rrule';
    import Modal from '@/components/general/modals/Modal';
    import dayjs from '@/util/dayjs';
    import RecurrenceEditor from '@/components/calendar/RecurrenceEditor';
    import { appts } from '@/util/apiRequests';

    export default {
        name: 'RRuleModal',
        components: { RecurrenceEditor, Modal },
        data() {
            return {
                initialRule: {},
                rule: {},
                submitDisabled: false,
            };
        },
        computed: {
            ...mapState('eventEditor', {
                initialRuleStr: (state) => state.event.recurrence_rule,
                startDate: (state) => state.event.dayt_appt_start,
                event: (state) => state.event,
            }),
        },
        methods: {
            handleClose() {
                this.$store.commit('eventEditor/closeModal', 'editRRule');
            },
            handleValidityUpdate(isValid) {
                this.submitDisabled = !isValid;
            },
            handleRuleUpdate(rule) {
                this.rule = rule;
            },
            parseRule(rule) {
                if (!rule) {
                    return {};
                }

                const mapKeys = {
                    FREQ: 'freq',
                    INTERVAL: 'interval',
                    COUNT: 'count',
                    UNTIL: 'until',
                    BYDAY: 'byweekday',
                    BYMONTHDAY: 'byMonthDay',
                    BYMONTH: 'byMonth',
                    BYYEARDAY: 'byYearDay',
                    BYWEEKNO: 'byWeekNo',
                    BYHOUR: 'byHour',
                    BYMINUTE: 'byMinute',
                    BYSECOND: 'bySecond',
                    BYSETPOS: 'bysetpos',
                    WKST: 'weekStart'
                };

                const rRule = {};

                const fields = rule.split(';');
                fields.forEach((field) => {
                    const [key, value] = field.split('=');
                    if (mapKeys[key]) {
                        let val;
                        if (mapKeys[key] === 'byweekday') {
                            const dayLetters = value.split(',');
                            let values = [];
                            if (dayLetters) {
                                const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
                                for (let i of dayLetters) {
                                    if (days.includes(i)) {
                                        const day = days.indexOf(i);
                                        values.push(day);
                                    }
                                }
                            }
                            val = values;
                        } else if (mapKeys[key] === 'until') {
                            const [dt] = value.split('T');
                            val = dayjs(dt, "YYYYMMDD").format('YYYY-MM-DD');
                        } else if (parseInt(value)) {
                            val = parseInt(value);
                        } else val = value;

                        rRule[mapKeys[key]] = val;
                    }
                });

                rRule.dtstart = dayjs(this.startDate).toDate();
                this.$set(this, 'initialRule', rRule);
                this.$set(this, 'rule', rRule);
            },
            getCurrentRuleString() {
                const translatedRule = { ...this.rule };

                switch (this.rule.freq) {
                    case 'WEEKLY':
                        translatedRule.freq = RRule.WEEKLY;
                        break;
                    case 'MONTHLY':
                        translatedRule.freq = RRule.MONTHLY;
                        break;
                }

                if (translatedRule.byweekday) {
                    translatedRule.byweekday = translatedRule.byweekday.map((day) => {
                        switch (day) {
                            case 0:
                                return RRule.SU;
                            case 1:
                                return RRule.MO;
                            case 2:
                                return RRule.TU;
                            case 3:
                                return RRule.WE;
                            case 4:
                                return RRule.TH;
                            case 5:
                                return RRule.FR;
                            case 6:
                                return RRule.SA;
                        }
                    });
                }

                if (translatedRule.until) {
                    translatedRule.until = dayjs(translatedRule.until).utc().toDate();
                }

                let output = new RRule(translatedRule).toString();

                output = output.split('\n');
                output = output.reduce((out, cur) => {
                    const [k, v] = cur.split(':');
                    if (k && v) {
                        out[k] = v;
                    }
                    return out;
                }, {});

                return output.RRULE;
            },
            async handleDelete() {
                const res = await this.$api.put(appts.removeRecurrenceRule(), { eventMetadata: this.event });

                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error('There was an error saving your changes.');
                } else {
                    this.$toasted.success('Successfully deleted future events in this rule.');
                    this.handleClose();
                }
            },
            async handleSubmit() {
                const res = await this.$api.put(appts.editRecurrenceRule(), {
                    recurrenceRule: this.rule,
                    eventMetadata: {
                        ...this.event,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        utcOffset: new Date().getTimezoneOffset(),
                    },
                });

                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error('There was an error saving your changes.');
                } else {
                    this.$store.commit('eventEditor/updateEvent', {
                        property: 'recurrence_rule',
                        value: this.getCurrentRuleString(),
                    });
                    this.$toasted.success('Successfully saved changes.');
                    this.handleClose();
                }
            },
        },
        created() {
            this.parseRule(this.initialRuleStr);
        },
    };
</script>
<style scoped>
    .initialRuleStr-btn {
        border-radius: 6px;
    }
</style>