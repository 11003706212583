<template>
    <div class="d-flex flex-column w-60 ms-5">
        <div class="d-flex justify-content-between w-75">
            <div class="appt-section-title">Start Time*</div>
            <div class="appt-section-title">End Time*</div>
        </div>
        <div class="flex center">
            <slot name="startTime" />
            &nbsp;<span> — </span>&nbsp;
            <slot name="endTime" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TimePickers'
    };
</script>

