<template>
    <div v-if="showLink" class="fw-600">
        <router-link  to="/video-conferences" class="secondary start-telehealth">
            Start this Telehealth Appointment
        </router-link>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import dayjs from "@/util/dayjs";

    export default {
        name: 'TelehealthLink',
        computed: {
            ...mapState('eventEditor', {
                isTelehealth: (state) => !!state.event.telehealth,
                isVirtualEvent: (state) => state.event.location === 2,
                startTime: (state) => state.event.dayt_appt_start,
                endTime: (state) => state.event.dayt_appt_end,
                showLink() {
                    return (this.isTelehealth || this.isVirtualEvent) && this.isInTimeframe();
                }
            }),
        },
      methods: {
          isInTimeframe() {
            const earliest = dayjs(this.startTime).add(-15, 'minute');
            const latest = dayjs(this.endTime).add(60, 'minute');

            const now = dayjs();
            return now.isBetween(earliest, latest);
          }
      }
    };
</script>

<style scoped>

</style>