<template>
    <ejs-timepicker
        :min="new Date('3/8/2199 0:00 AM')"
        :max="new Date('3/8/2199 11:59 PM')"
        :step="15"
        :value="timeValue"
        @change="handleTimeChange"
    />
</template>

<script>
    import Vue from 'vue';
    import { mapState } from 'vuex';
    import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import { TimePickerKind } from '@/components/calendar/AppointmentEditor/common/constants';

    Vue.use(DatePickerPlugin);

    export default {
        name: 'TimePicker',
        props: {
            kind: {
                type: String,
                required: true,
            }
        },
        computed: {
            ...mapState('eventEditor', {
                startTime: (state) => state.event.dayt_appt_start,
                endTime: (state) => state.event.dayt_appt_end,
            }),
            timeValue() {
                if (this.kind === TimePickerKind.Start) {
                    return this.startTime;
                }

                if (this.kind === TimePickerKind.End) {
                    return this.endTime;
                }

                return null;
            },
        },
        methods: {
            handleTimeChange(e) {
                this.$store.dispatch('eventEditor/updateTime', { value: e.value, kind: this.kind });
            },
        }
    };
</script>
