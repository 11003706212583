<template>
    <div>
        <!--  meeting host       -->
        <h5 class='e-textlabel'>Meeting Host</h5>
        <Multiselect
            :allow-empty='false'
            :options='userList'
            :searchable='true'
            :show-labels='false'
            label='name'
            track-by='id'
            v-model='users.meetingHost'
            @select='(e) => handleMeetingHostSelection(e)'
        />

        <hr />

        <!--  list of available users/clinicians      -->
        <h5 class='e-textlabel'>User(s) </h5>
        <div v-for='(users, ndx) in users.users' :key='ndx' class='input-btn-row flex center bottom-10'>
            <Multiselect
                :allow-empty='false'
                :options='userList'
                :searchable='true'
                :show-labels='false'
                label='name'
                track-by='id'
                v-model='usersForAppt[ndx]'
                @select='(e) => handleUserSelection(e,ndx)'
            />

            <button
                type='button'
                name='removeStaffBtn'
                id='removeStaffBtn'
                class='delete no-bg'
                @click='handleDeleteUser(ndx)'
                v-show='usersForAppt.length > 1'
            >
                <span class='material-icons-outlined delete dark-text'>delete_outline</span>
            </button>

        </div>

        <div v-if='maxUsers' class='flex space-between'>
            <button type='button' name='addServiceBtn' id='addServiceBtn' @click='handleAddUser'>
                <span class='material-icons'>add_box</span> add User(s)
            </button>
        </div>

        <hr />
    </div>
</template>

<script>
    export default {
        name: 'UsersForAppt',
        props: {
            activeUsers: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                users:
                    {
                        meetingHost: {
                            name: null,
                            id: null
                        },
                        users: [
                            {
                                name: null,
                                id: null
                            }
                        ]
                    }
            };
        },
        computed: {


            userList() {
                return this.activeUsers.map((user) => ({
                    id: user.id,
                    name: user.name,
                    $isDisabled: user.$isDisabled == true ? true : false
                }));
            },

            usersForAppt() {
                return this.users.users.map((user) => ({
                    id: user.id,
                    name: user.name
                    // $isDisabled: clinician.$isDisabled == true ? true : false
                }));
            },

            maxUsers() {
                if (this.usersForAppt.length <= 25) {
                    return true;
                }

                return false;
            }
        },
        methods: {
            handleMeetingHostSelection(e) {
                // e: {id: 16, name: 'Admin Salima', $isDisabled: false}
                this.users.meetingHost.name = e.name;
                this.users.meetingHost.id = e.id;
            },

            handleUserSelection(e, index) {
                // e: {id: 16, name: 'Admin Salima', $isDisabled: false}
                let user = {
                    name: e.name,
                    id: e.id
                };
                this.$set(this.users.users, index, user);
            },

            handleAddUser() {
                this.users.users.push({ name: null, id: null });
            },

            handleDeleteUser(index) {
                this.users.users.splice(index, 1);
            },

            disableOption(index) {
                if (index > -1) {
                    let selection = this.activeUsers[index];
                    //delete selection.$isDisabled;
                    selection.$isDisabled = true;
                    this.$set(this.activeUsers, index, selection);
                }
                return;
            }
        },
        watch: {
            users: {
                deep: true,
                immediate: true,

                // every time a user or meeting host is set or changed
                handler: function() {
                    this.activeUsers.forEach((user) => {
                        user.$isDisabled = false;
                    });

                    let hostIndex = this.activeUsers.findIndex((host) => host.id == this.users.meetingHost.id);
                    this.disableOption(hostIndex);

                    this.users.users.forEach((clinician, index) => {
                        let userIndex = this.activeUsers.findIndex((user) => user.id == this.users.users[index].id);
                        this.disableOption(userIndex);
                    });

                    // emit the users back up to CreateEventModal
                    this.$emit('setUsers', this.users);
                }
            }

        }
    };
</script>

<style scoped>

</style>