<template>
    <div>
        <label :for="id">{{ label }}</label>
        <input :id="id" v-model="inVal" placeholder="Zip" @blur="$emit('blur', $event)" />
    </div>
</template>

<script>
    export default {
        name: 'ZipInput',
        props: {
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                inVal: '',
            };
        },
        methods: {
            clear() {
                this.inVal = '';
            },
        },
        watch: {
            inVal(newVal, oldVal) {
                if (newVal !== oldVal) {
                    let scrubbedVal = newVal.replace(/[^0-9]/g, '');

                    if (scrubbedVal.length > 5) {
                        scrubbedVal = scrubbedVal.substring(0, 5);
                    }

                    this.inVal = scrubbedVal;

                    this.$emit('input', this.inVal);
                }
            },
        },
    };
</script>

<style scoped></style>
