<template>
    <input
        @blur="emitTitle"
        :placeholder="titlePlaceholder"
        id="title"
        name="title"
        type="text"
        value=""
        v-model="title"
        required
    />
</template>

<script>
    export default {
        name: 'Title',
        props: {
            modalOpen: Boolean,
            eventType: { required: true },
        },
        computed: {
            titlePlaceholder() {
                if (this.eventType == null) {
                    return 'New Calendar Event';
                }
                if (this.eventType == 'Other') {
                    return 'Create New Event';
                } else {
                    return 'New Appointment';
                }
            },
        },
        data() {
            return {
                title: '',
            };
        },
        methods: {
            emitTitle() {
                this.$emit('setTitle', this.title);
            },
        },
        watch: {
            modalOpen() {
                //clears the title input everytime the modal closes
                if (this.modalOpen == false) {
                    this.title = null;
                }
            },
        },
    };
</script>

<style scoped></style>
