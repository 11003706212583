<template>
    <div class="locationApptType bottom-15">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'LocationSection'
    };
</script>
