<template>
    <div>
        <h5 class="e-textlabel">Counselor(s)</h5>

        <div v-for="(_, staffKey) in selectedStaff" :key="staffKey" class="input-btn-row flex center bottom-10">
            <Multiselect
                :allow-empty="false"
                :options="clinicianList"
                :searchable="true"
                :show-labels="false"
                label="name"
                track-by="id"
                v-model="selectedStaff[staffKey]"
                @select="appendCounselor($event, staffKey)"
            />

            <button
                type="button"
                name="removeStaffBtn"
                id="removeStaffBtn"
                class="delete no-bg"
                @click="remove(staffKey)"
                v-show="selectedStaff.length > 1"
            >
                <span class="material-icons-outlined delete dark-text">delete_outline</span>
            </button>
        </div>
        <button class="text-button" type="button" name="addBtn" id="addCounselorBtn" @click="add">
            <span class="material-icons">add_box</span> Add counselor
        </button>
        <hr />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';

    Vue.use(ComboBoxPlugin);
    export default {
        name: 'CounselorsForAppt',
        props: {
            clinicians: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                selectedStaff: [{ id: null, name: null, host: false }]
            };
        },
        computed: {
            clinicianList() {
                const selectedStaffIds = this.selectedStaff.map(staff => staff.id);

                return this.clinicians.map((clinician) => ({
                    id: clinician.id,
                    name: `${clinician.first_name} ${clinician.last_name}`,
                    $isDisabled: selectedStaffIds.includes(clinician.id)
                }));
            }
        },
        methods: {
            appendCounselor(e, staffKey) {
                const {id, name} = e;
                this.$set(this.selectedStaff, staffKey, {id, name});
                this.$emit('counselorAdded', this.selectedStaff);
            },

            add() {
                this.selectedStaff.push({ id: null, name: null, host: false });
            },
            remove(index) {
                if (this.selectedStaff.length >= 2) this.selectedStaff.splice(index, 1);
                this.$emit('counselorAdded', this.selectedStaff);
            }
        },
    };
</script>
