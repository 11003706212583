<template>
    <div>
        <h5 class="e-textlabel">Client(s)</h5>
        <!-- individual appt -->
        <div v-if="eventType === 'Individual'">
            <Multiselect
                :allow-empty="false"
                :options='clientList'
                :searchable="true"
                :show-labels="false"
                :value='clientsToBeScheduled'
                label="name"
                @input="handleSelection($event)"


            />
        </div>
        <!-- group appt -->
        <div v-else v-for="(client, key) in apptClients" :key="key" class="input-btn-row flex center bottom-10">

            <Multiselect
                :allow-empty="false"
                :options='clientList'
                :searchable="true"
                :show-labels="false"
                label="name"
                track-by="id"
                v-model='apptClients[key]'
                @input="handleSelection($event, key)"

            />
            <button
                type="button"
                name="removeClientBtn"
                class="delete no-bg"
                id="removeBtn"
                @click= "handleDeleteClient(key)"
                v-show="apptClients.length > 1"
            >
                <span class="material-icons-outlined delete dark-text">delete_outline</span>
            </button>
        </div>

        <!-- add more clients -->
        <button
            type="button"
            name="addClientsBtn"
            id="addBtn"
            :disabled="disableAdd"
            v-if="this.eventType !== 'Individual'"
            @click="handleAddClient"
            class="text-button"
        >
            <span class="material-icons">add_box</span> Add client
        </button>
        <hr />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';
    import { generateId } from '@/util/genericUtilityFunctions';
    Vue.use(ComboBoxPlugin);

    export default {
        name: 'ClientsForAppt',

        props: {
            eventType: {
                type: String,
                required: true,
            },
            clientList: {
                type: Array,
                required: true,
            },
            clientScheduledNow: {
                required: false,
            },
            clientsToBeScheduled: {
                required: true,
            },
            modalOpen: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                loading: true,
            };
        },

        computed: {
            apptClients: {
                get() {
                    return this.clientsToBeScheduled;
                },

                set() {
                    if (this.clientScheduledNow !== null) {
                        this.clients = this.clientScheduledNow;
                    }
                },
            },
            disableAdd() {
                return this.eventType === 'Couple' && this.clients.length === 2;
            },
        },

        methods: {
            handleSelection(e, key){
                this.$emit('clientSelected', { info: e, index: key });
            },

            handleAddClient() {
                if (this.apptClients.length === 2 && this.disableAdd) {
                    this.$toasted.error("You can only add two clients under the appointment type 'Couple'");
                    return;
                }
                this.$emit('addClientBtnClick');
            },

            handleDeleteClient(key) {
                this.$emit('deleteClientBtnClick', { info: this.apptClients[key], index: key });
            },


        },
        watch: {
            modalOpen() {
                //clears the appointment location & appointment type everytime the modal closes
                if (!this.modalOpen) {
                    this.clients = [null];
                }
            },

            clients() {
                if( Array.isArray(this.clients)){
                    this.clientList.forEach((client) => client.$isDisabled = false)
                    this.clients.forEach((client) => {
                        if(client !== null){
                            let clientListIndex = this.clientList.findIndex((clientListItem) => clientListItem.id === client.id);
                            if(clientListIndex > -1){
                                let copy = this.clientList[clientListIndex]
                                copy.$isDisabled = true
                                this.$set(this.clientList, clientListIndex, copy)
                            }
                        }

                    })
                }
            },

            eventType: {
                deep: true,
                immediate: true,
                handler: function() {
                    if (this.eventType !== 'Individual') {
                        //check if data structure is an array
                        //if not, make it an array
                        if(!Array.isArray(this.clients)){
                            let selectedClient = this.clients;
                            this.clients = [];
                            this.clients.push(selectedClient);
                        }
                    }
                },
            },
        },
        //
        // mounted(){
        //     this.$emit('mounted')
        // }

    };
</script>

<style scoped></style>
