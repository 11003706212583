<template>
    <!-- 
        Add this condition to show delete option for admin only
        v-if="displayButton"
    -->
    
    <button
        type="button"
        id="deleteApptBtn"
        class="secondary top-0 fw-600"
        name="deleteApptBtn"
        @click="openDeleteModal"
    >
        {{ buttonText}}
    </button>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'DeleteButton',
        computed: {
            ...mapState('eventEditor', {
                start: (state) => state.event.dayt_appt_start,
                end: (state) => state.event.dayt_appt_end,
                recurringLocked: (state) => state.event.recurring_locked,
                eventType: (state) => state.event.type,
                clients: (state) => state.event.clients,
                apptType: (state) => state.event.appt_type,
            }),
            ...mapState({
                user: (state) => state.user,
            }),
            userIsAdmin() {
                return this.user.role_id === 1;
            },
            displayButton() {
                /** Users can delete appts if:
                 *  - User is an admin
                 *  - A signed PN does NOT exist for the appt
                 *  - All client's status on the appt is NOT 'attended' (invoice not generated yet)
                 *  - An invoice has been generated for a client (note: marking client attendance as cancelled, or no-show generates an invoice)
                 * **/
                return Boolean( this.userIsAdmin );
            },
            buttonText() {
                return `Delete ${this.eventType === 'EVENT' ? 'Event' : 'Appointment'}`;
            }
        },
        methods: {
            openDeleteModal() {
                this.$store.commit('eventEditor/openModal', 'deleteEvent');
            },
        }
    };
</script>