<template>
    <div>
        <div class="form-group flex bottom-20">
            <div class="d-flex flex-column w-40">
            <div class="appt-section-title">Date*</div>
                <ejs-datepicker
                    v-model="startDate"
                    :format="dateFormat"
                    name="date"
                    :change="handleDateChange"
                    placeholder="Select Date"
                >
                </ejs-datepicker>
            </div>
            <div class="d-flex flex-column w-60 ms-5">
                <div class="d-flex justify-content-between w-75">
                    <div class="appt-section-title">Start Time*</div>
                    <div class="appt-section-title">End Time*</div>
                </div>
                <div class="flex center">
                    <ejs-timepicker
                        id="StartTime"
                        v-model="startDate"
                        :min="minTime"
                        :max="maxTime"
                        :step="timeStep"
                        placeholder="Start Time"
                        @change="handleStartTimeChange"
                    >
                    </ejs-timepicker>
                    &nbsp;
                    <span> — </span>
                    &nbsp;
                    <ejs-timepicker
                        id="EndTime"
                        v-model="endDate"
                        :min="minTime"
                        :max="maxTime"
                        :step="timeStep"
                        placeholder="End Time"
                        @change="handleEndTimeChange"
                    >
                    </ejs-timepicker>
                </div>
            </div>
        </div>

        <RecurrenceEditor
            v-show="isRecurring"
            :startDate="startDate"
            ref="recurrenceEditor"
            @rRule="$emit('rRule', $event)"
        />
    </div>
</template>

<script>
    import Vue from 'vue';

    Vue.use(TimePickerPlugin);
    Vue.use(DatePickerPlugin);

    // import dayjs from 'dayjs';
    import { DatePickerPlugin, TimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import dayjs from 'dayjs';
    import RecurrenceEditor from '@/components/calendar/RecurrenceEditor';

    export default {
        name: 'DateTime',
        components: { RecurrenceEditor },
        props: {
            date: Date,
            open: {
                type: Boolean,
                required: false
            },
            isRecurring: Boolean,
            recurringType: String,
            dailyInterval: Number,
            dailyEndIsCount: Boolean,
            dailyEndCount: Number,
            dailyEndDate: Date,
            dateDay: Number,
            weeklyInterval: Number,
            weeklyDays: Array,
            weeklyEndIsCount: Boolean,
            weeklyEndCount: Number,
            weeklyEndDate: Date,
            monthlyInterval: Number,
            monthlyPatternIsDay: Boolean,
            monthlyDayPattern: Number,
            monthlyWeekPattern: Number,
            monthlyEndIsCount: Boolean,
            monthlyEndCount: Number,
            monthlyEndDate: Date
        },

        data() {
            return {
                dateFormat: 'EEEE, MM/dd/yyyy',
                endDate: null,
                maxDate: new Date('5/25/2029 2:00 AM'),
                maxTime: new Date('3/8/2199  11:59 PM'),
                minDate: new Date(),
                minTime: new Date('3/8/2199  0:00 AM'),
                startDate: new Date(this.date),
                timeStep: 15
            };
        },
        computed: {
            weekday() {
                return dayjs()
                    .day(this.dateDay)
                    .format('dddd');
            },
            monthlyPatternModel: {
                set(value) {
                    this.changeFn({
                        monthlyPatternIsDay: value
                    });
                },
                get() {
                    return this.monthlyPatternIsDay;
                }
            }
        },
        methods: {
            changeFn(e) {
                this.$emit('changeRRuleData', e);
            },

            weeklyDayChange(e) {
                const day = parseInt(e.target.value, 10);
                if (this.weeklyDays.includes(day)) {
                    this.changeFn({ weeklyDays: this.weeklyDays.filter((d) => d !== day) });
                } else {
                    this.changeFn({ weeklyDays: [...this.weeklyDays, day] });
                }
            },

            handleDateChange(e) {
                if (e.value) {
                    const day = dayjs(e.value).day();
                    this.changeFn({ dateDay: day, weeklyDays: [day] });

                    let startTime = dayjs(e.value);
                    let endTime = startTime.add(1, 'hour');
                    this.startDate = startTime.format();
                    this.endDate = endTime.format();
                    this.apptDate = e.value;
                }
           
                let data = [
                    {
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                ];
                this.$emit('dateTimeSelected', data);
            },

            handleStartTimeChange(e) {
                if (e.value) {
                    let startTime = dayjs(e.value);
                    let endTime = startTime.add(1, 'hour');
                    this.endDate = endTime.format();
                    // this.endDate = dayjs(e.value).format();
                } else {
                    this.startDate = null;
                    this.endDate = null;
                }
              
                let data = [
                    {
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                ];
                this.$emit('dateTimeSelected', data);
            },

            handleEndTimeChange(e) {
                if (e.value) {
                    this.endDate = dayjs(e.value).format();
                } else {
                    this.endDate = null;
                }
                
                let data = [
                    {
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                ];
                this.$emit('dateTimeSelected', data);
            }
        },

        watch: {
            date(newVal) {
                //handles date changes
                this.startDate = newVal;
                let hourDifference = this.startDate.getHours() + 1;
                this.endDate = new Date(new Date(this.startDate));
                this.endDate.setHours(hourDifference);
            },
            open() {
                if (this.open == true) {
                    let data = [
                        {
                            startDate: dayjs(this.startDate).format(),
                            endDate: dayjs(this.endDate).format()
                        }
                    ];
                    this.$refs.recurrenceEditor.reset();
                    this.$emit('dateTimeSelected', data);
                }
            }
        },

        created() {
            if (this.endDate == null) {
                let hourDifference = this.startDate.getHours() + 1;
                this.endDate = new Date(new Date(this.startDate));
                this.endDate.setHours(hourDifference);
            }
        }
    };
</script>

