<template>
    <Modal @close="$emit('close')">
        <h1>Date Changed</h1>
        <p>
            This appointment is part of a recurrence. Date change will be applied to this appointment only. The remaining appointments for this recurrence will be unaffected.
        </p>
        <div class="align-right pad-10">
            <button type="button" class="secondary bottom-0" @click="$emit('close')">Cancel</button>
            <button type="button" class="primary bottom-0" @click="$emit('confirmDateUpdate')">Save</button>
        </div>
    </Modal>
</template>

<script>
    import Modal from '@/components/general/modals/Modal';
    export default {
        name: 'ConfirmDateChangeModal',
        components: { Modal },
    };
</script>

<style scoped>

</style>