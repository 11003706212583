<template>
    <NoButtonModal :open="open" @close="closeModal">
        <h1>Edit This Events Recurrence</h1>
        <RecurrenceEditor
            @rRule="logChanges"
            @validityUpdate="handleValidityUpdate"
            :startDate="start"
            :initial="rRule"
            :suppressCount="!open"
            ref="rEditor"
        />
        <button class="red-btn" @click="deleteRecurring" v-if="this.recurrenceRule">
            Delete Recurring Appointments
        </button>
        <button class="secondary" @click="closeModal">Cancel</button>
        <button
            class="primary"
            :class="{ 'disabled-content': disableSubmit }"
            @click="submitChanges"
            :disabled="disableSubmit"
            :title="disableSubmit ? 'Invalid Recurrence' : ''"
        >
            Save
        </button>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import RecurrenceEditor from '@/components/calendar/RecurrenceEditor';
    import dayjs from 'dayjs';
    export default {
        components: { RecurrenceEditor, NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true
            },
            start: {
                type: [String, Date]
            },
            recurrenceRule: {
                type: String
            }
        },
        data() {
            return {
                rRule: {},
                newRRule: {},
                disableSubmit: false,
            };
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },
            submitChanges() {
                this.$emit('rRuleChangeSave', this.newRRule);
                this.$emit('close');
            },
            logChanges(arg) {
                this.newRRule = arg;
            },
            deleteRecurring() {
                this.$emit('rRuleDelete');
                this.$emit('close');
            },
            handleValidityUpdate(isValid) {
                this.disableSubmit = !isValid;
            },
            setRrule(newValue) {
                const mapKeys = {
                    FREQ: 'freq',
                    INTERVAL: 'interval',
                    COUNT: 'count',
                    UNTIL: 'until',
                    BYDAY: 'byweekday',
                    BYMONTHDAY: 'byMonthDay',
                    BYMONTH: 'byMonth',
                    BYYEARDAY: 'byYearDay',
                    BYWEEKNO: 'byWeekNo',
                    BYHOUR: 'byHour',
                    BYMINUTE: 'byMinute',
                    BYSECOND: 'bySecond',
                    BYSETPOS: 'bysetpos',
                    WKST: 'weekStart'
                };

                const rRule = {};

                if (!newValue) {
                    return;
                }

                const fields = newValue.split(';');
                fields.forEach((field) => {
                    const [key, value] = field.split('=');
                    if (mapKeys[key]) {
                        let val;
                        if (mapKeys[key] === 'byweekday') {
                            const dayLetters = value.split(',');
                            let values = [];
                            if (dayLetters) {
                                const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
                                for (let i of dayLetters) {
                                    if (days.includes(i)) {
                                        const day = days.indexOf(i);
                                        values.push(day);
                                    }
                                }
                            }
                            val = values;
                        } else if (mapKeys[key] === 'until') {
                            const [dt] = value.split('T');
                            val = dayjs(dt, "YYYYMMDD").format('YYYY-MM-DD');
                        } else if (parseInt(value)) {
                            val = parseInt(value);
                        } else val = value;

                        rRule[mapKeys[key]] = val;
                    }
                });
                rRule.dtstart = dayjs(this.start).toDate();

                this.$set(this, 'rRule', rRule);
            }
        },
        watch: {
            recurrenceRule(newValue) {
                this.setRrule(newValue);
            },
            open(isOpen) {
                if (!isOpen) {
                    this.rRule = {};
                } else {
                    this.setRrule(this.recurrenceRule);
                }
            }
        }
    };
</script>
