<template>
    <Modal @close="$emit('close')" :hasCloseButton="true">
        <h2>Pay Invoice</h2>
        <div class="pay-invoice-popup">
            <div class="form">
                <div>
                    <div class="flex center">
                        <input class="color"
                            type="radio"
                            id="currentBalanceRadio"
                            value="BALANCE"
                            v-model="selectedAmountOption"
                            :disabled="totalAmountUnavailable"
                        />
                        <label class="bottom-0 font-14" for="currentBalanceRadio">Invoice Amount Due: {{ $getCurrency(balance) }}</label>
                    </div>
                </div>
                <div>
                    <div class="flex center">
                        <input type="radio" class="color" id="otherBalanceRadio" value="OTHER" v-model="selectedAmountOption" />
                        <label class="bottom-0 font-14" for="otherBalanceRadio">Other Amount</label>
                        <CurrencyInput
                            class="left-10"
                            @currency="handleCurrencyChange"
                            :disabled="selectedAmountOption !== 'OTHER'"
                            :max="amountMax.amount"
                            :maxMsg="amountMax.message"
                            :initialValue="$getNumFromCurrency(otherBalance || '0')"
                            ref="currencyInput"
                        />
                    </div>
                </div>
                

                <Dropdown
                    :options="paymentTypes"
                    name="Payment Type"
                    id="paymentTypes"
                    label="Payment Type"
                    v-model="paymentTypeModel"
                    disableEmpty
                />

                <Input
                    v-if="paymentType === 'CHECK'"
                    type="text"
                    name="checknum"
                    id="checknum"
                    placeholder="Check Number"
                    label="Check Number"
                    v-model="checkNumber"
                />

                <div v-if="paymentType === 'ACCOUNT_BALANCE'">
                    <p><strong>Available Balance: </strong>{{ $getCurrency(availableCredits) }}</p>
                </div>

                <div v-if="paymentType === 'CARD'">
                    <div class="flex center bottom-15">
                        <div class="flex center right-15">
                            <input type="radio" class="color" id="useSavedCardRadioTrue" v-model="useSavedCard" :value="true" />
                            <label class="bottom-0" for="useSavedCardRadioTrue">Use Saved Card</label>
                        </div>
                        
                        <div class="flex center">
                            <input type="radio" class="color" id="useSavedCardRadioFalse" v-model="useSavedCard" :value="false" />
                            <label class="bottom-0" for="useSavedCardRadioFalse">Use New Card</label>
                        </div>
                    </div>

                    <div v-if="useSavedCard">
                        <SavedCardsDropdown :clientId="clientId" @choseCard="handleCardChosen" />
                    </div>
                    <div v-else>
                        <NewCardForm v-model="cardInfo" ref="newCardForm" />
                    </div>
                </div>
            </div>

            <div class="top-20 align-right">
                <button class="margin-0 secondary" type="button" @click="$emit('close')">Cancel</button>
                <button
                    class="primary top-0 bottom-0"
                    type="button"
                    @click="makePayment"
                    :disabled="paymentInProgress"
                >
                    <span class="" v-if="!paymentInProgress">Submit</span>
                    <Loading
                        outerHeight="16px"
                        outerWidth="16px"
                        height="16px"
                        width="16px"
                        :color="'white transparent transparent transparent'"
                        :borderWidth="'3px'"
                        v-else
                    />
                </button>
            </div>
        </div>
        <PaymentSuccessModal :open="successModalOpen" :data="successModalData" @close="handleSuccessClose" />
    </Modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Modal from '@/components/general/modals/Modal';
    import SavedCardsDropdown from '@/components/payments/SavedCardsDropdown';
    import NewCardForm from '@/components/stripe/NewCardForm';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import Loading from '@/components/general/loading/loading';
    import { invoices, ledger } from '@/util/apiRequests';
    import PaymentSuccessModal from '@/components/client-manager/ClientBilling/PaymentSuccessModal';

    export default {
        name: 'PayInvoiceModal',
        components: { PaymentSuccessModal, CurrencyInput, NewCardForm, SavedCardsDropdown, Modal, Loading },
        props: {
            balance: {
                type: Number,
                required: true
            },
            clientId: {
                type: [Number, String],
                required: true
            },
            invoiceId: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                useSavedCard: true,
                selectedSavedCard: null,
                cardInfo: null,
                selectedAmountOption: 'BALANCE',
                paymentType: 'CASH',
                paymentTypes: [
                    { text: 'Cash', value: 'CASH' },
                    { text: 'Check', value: 'CHECK' },
                    { text: 'Card', value: 'CARD' },
                    { text: 'Account Balance', value: 'ACCOUNT_BALANCE' }
                ],
                availableCredits: 0,
                otherBalance: 0,
                checkNumber: '',
                successModalOpen: false,
                successModalData: {},
                paymentInProgress: false,
                amountMax: {
                    amount: this.balance,
                    message: "Amount must be less than or equal to the amount due."
                }
            };
        },
        computed: {
            ...mapGetters(['officeId']),
            paymentTypeModel: {
                get() {
                    return this.paymentType;
                },
                set(value) {
                    if (value === "ACCOUNT_BALANCE") {
                        // set the amount limits
                        this.amountMax = {
                            amount: Math.min(this.balance, this.availableCredits),
                            message: this.availableCredits < this.balance ? "You do not have sufficient credit to pay more." : "Amount must be less than or equal to the amount due."
                        }

                        if (this.otherBalance && this.$getNumFromCurrency(this.otherBalance) > this.availableCredits){
                            this.otherBalance = this.$getCurrency(this.availableCredits);
                        }

                        if (this.balance > this.availableCredits && this.selectedAmountOption !== 'OTHER') {
                            this.selectedAmountOption = 'OTHER';
                            this.otherBalance = this.$getCurrency(this.availableCredits);
                        }

                    } else {
                        this.amountMax = {
                            amount: this.balance,
                            message: "Amount must be less than or equal to the amount due."
                        }
                    }

                    this.paymentType = value;
                }
            },
            hasBalance() {
                return this.balance > 0;
            },
            totalAmountUnavailable() {
                return (
                    (this.paymentType === 'ACCOUNT_BALANCE' && this.availableCredits < this.balance) || !this.hasBalance
                );
            },
        },
        methods: {
            clear() {
                this.$refs.currencyInput.clear();
                this.checkNumber = '';
                this.paymentType = 'CASH';
                this.otherBalance = 0;
                this.selectedAmountOption = 'BALANCE';
            },
            async makePayment() {
                if (this.selectedAmountOption !== 'BALANCE') {
                    const paymentAmount =
                        typeof this.otherBalance === 'number'
                            ? this.otherBalance
                            : this.$getNumFromCurrency(this.otherBalance);

                    if (paymentAmount <= 0) {
                        this.$toasted.error('Payment amount must be greater than $0.00');
                        return;
                    }
                }

                this.paymentInProgress = true;
                let paymentSuccessful = false;
                const self = this;

                if (this.paymentType === 'ACCOUNT_BALANCE') {
                    paymentSuccessful = await this.payWithBalance();
                } else {
                    paymentSuccessful = await this.payDirect();
                }

                if (paymentSuccessful && this.paymentType === 'ACCOUNT_BALANCE') {
                    setTimeout(function() {
                        //self.$emit('close');
                        self.$toasted.success('Payment successful');
                        self.handleSuccessClose();
                    }, 300);
                }

                if (paymentSuccessful && this.paymentType !== 'ACCOUNT_BALANCE') {
                    setTimeout(function() {
                        self.successModalOpen = true;
                    }, 500);
                }

                this.paymentInProgress = false;
            },
            async payWithBalance() {
                const noFundsErr = 'Member does not have sufficient account credit to pay the selected amount';

                if (this.selectedAmountOption === 'BALANCE' && this.balance > this.availableCredits) {
                    this.$toasted.error(noFundsErr);
                    return;
                }

                if (
                    this.selectedAmountOption === 'OTHER' &&
                    this.$getNumFromCurrency(this.otherBalance) > this.availableCredits
                ) {
                    this.$toasted.error(noFundsErr);
                    return;
                }
                let page = this.$store.getters['availablePages'].find(
                    (element) => element.component_name == 'Invoices'
                );
                let page_id = page.id;
                const res = await this.$api.post(invoices.payWithBalance(), {
                    invoice_id: this.invoiceId,
                    office_id: this.officeId,
                    client_id: this.clientId,
                    page_id: page_id,
                    amount:
                        this.selectedAmountOption === 'BALANCE'
                            ? this.balance
                            : this.$getNumFromCurrency(this.otherBalance)
                });

                if (res.status >= 200 && res.status < 300) {
                    return true;
                } else {
                    this.$toasted.error('Payment unsuccessful');
                }
            },
            async payDirect() {
                const paymentPayload = {
                    client_id: this.clientId,
                    payment_type: this.paymentType,
                    invoice_id: this.invoiceId,
                    office_id: this.officeId,
                    payment_amount:
                        this.selectedAmountOption === 'BALANCE'
                            ? this.balance
                            : this.$getNumFromCurrency(this.otherBalance)
                };

                switch (this.paymentType) {
                    case 'CASH':
                        break;
                    case 'CHECK':
                        if (!this.checkNumber) {
                            this.$toasted.error('Please enter a check number');
                            return;
                        }

                        break;
                    case 'CARD':
                        if (this.useSavedCard) {
                            if (!this.selectedSavedCard) {
                                this.$toasted.error('Please select a card');
                                return;
                            }

                            paymentPayload.saved_card_id = this.selectedSavedCard.stripe_pm_id;
                        } else {
                            if (this.cardInfo && this.cardInfo.card.save_card && !this.cardInfo.card.save_card_nickname) {
                                this.$toasted.error('Please give card a nickname');
                                return;
                            }

                            const isValid = this.$refs.newCardForm.validate();

                            if (!isValid) {
                                this.$toasted.error('Invalid card');
                                return;
                            }

                            paymentPayload.card_info = {
                                number: this.cardInfo.card.number,
                                exp_month: this.cardInfo.card.exp_month,
                                exp_year: this.cardInfo.card.exp_year,
                                cvc: this.cardInfo.card.cvc,
                                zip: this.cardInfo.billing_details.address.postal_code,
                                name: this.cardInfo.card.save_card ? this.cardInfo.card.save_card_nickname : undefined
                            };

                            paymentPayload.save_card = this.cardInfo.card.save_card;
                            paymentPayload.is_autopay = this.cardInfo.card.is_autopay;
                        }
                        break;
                }
                let page = this.$store.getters['availablePages'].find(
                    (element) => element.component_name == 'Invoices'
                );
                paymentPayload.page_id = page.id;
                const res = await this.$api.post(invoices.directPayment(), paymentPayload);

                if (res.status >= 200 && res.status < 300) {
                    this.$toasted.success('Payment successful');
                    this.successModalData = res.data;
                    return true;
                } else if (res.status === 400) {
                    this.$toasted.error(res.data.balance || 'Payment unsuccessful');
                } else {
                    this.$toasted.error('Payment unsuccessful');
                }
            },
            async getAvailableCredits() {
                const res = await this.$api.get(ledger.getClientBalance(this.clientId));

                this.availableCredits = res.data.credit;
            },
            handleCardChosen(card) {
                this.selectedSavedCard = card;
            },
            handleCurrencyChange(newCurrencyTotal) {
                this.otherBalance = newCurrencyTotal;
            },
            handleSuccessClose() {
                this.successModalOpen = false;
                this.$emit('close');
                this.clear();
            }
        },
        async created() {
            await this.getAvailableCredits();
        },
        watch: {
            async open(val) {
                if (val) {
                    await this.getAvailableCredits();
                }
                if(val === false){
                    this.clear();
                }
            },
            paymentType(val) {
                if (val !== 'CHECK') {
                    this.checkNumber = '';
                }

                if (this.totalAmountUnavailable) {
                    this.selectedAmountOption = 'OTHER';
                }
            },

        }
    };
</script>

<style scoped></style>
