import Vue from 'vue';
import store from '@/store';
import { appts, events, payments, apptItems } from '@/util/apiRequests';
import RecurrenceSaveType from '@/util/recurrenceSaveType';
import api from '@/util/api';
import { EventType } from '@/util/globalConstants';

export const saveAppointment = async (event, changeTracker, changeType = RecurrenceSaveType.OnlyCurrent) => {
    const errorMessage = 'There was an error saving your changes. Please try again later.';

    try {
        const res = await api.put(appts.batchUpdate(), {
            changes: changeTracker,
            eventMetadata: event,
            changeType,
        });

        if (res.status < 300) {
            Vue.toasted.success('Successfully saved updates');
            await store.dispatch('eventEditor/changesSaved');
        } else {
            Vue.toasted.error(errorMessage);
        }

        return res.status || 500;
    } catch (err) {
        console.error(err);
        Vue.toasted.error(errorMessage);
        return 500;
    }
}

export const saveEvent = async (event) => {
    const users = event.users;

    // remove unnecessary fields
    delete users.meetingHost.$isDisabled;
    users.users.forEach((u) => delete u.$isDisabled);

    const errorMessage = 'There was an error updating your changes, please try again.';

    try {
        const res = await api.put(events.updateEvent(), {
            id: event.id,
            event: event.title,
            location: parseInt(event.location),
            dayt_appt_start: event.dayt_appt_start,
            dayt_appt_end: event.dayt_appt_end,
            users: users,
        });

        if (res.status >= 300) {
            Vue.toasted.error(errorMessage);
        } else {
            await store.dispatch('eventEditor/changesSaved');
            Vue.toasted.success('Successfully saved updates');
        }

        return res.status || 500;
    } catch (err) {
        Vue.cl('Error saving event changes:', err);
        Vue.toasted.error(errorMessage);
        return 500;
    }
};

export const getAppointment = async (groupUuid, filters) => {
    // const res = await api.get(appts.getByGroupUuid(groupUuid));
    const res = await api.get(apptItems.getApptItems(),{
        params: {
            getApptsForGroup: true,
            groupUuid: groupUuid,
            filters: filters
        },
    })
    const appointment = res.data[0];
    return {
        ...appointment,
        title: appointment.appointment,
        type: EventType.APPOINTMENT,
    };
};

export const checkAutopay = async (clientId) => {
    const res = await api.get(payments.checkAutopay(clientId));
    if (res.status < 300) {
        return res.data.autopayActive;
    } else {
        Vue.toasted.error('Error checking autopay status');
        return false;
    }
};
