<template>
    <NoButtonModal :open="open" @close="handleCancel">
        <h1>How would you like to save these changes?</h1>
        <div class="flex flex-column">
            <label class="flex center bottom-10">
                <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.OnlyCurrent" />
                <span class="left-10">Save changes for this appointment only</span>
            </label>

            <label class="flex center bottom-10">
                <input type="radio" class="color" v-model="saveChoice" :value="RecurrenceSaveType.AllFuture" id="allFuture" />
                <span class="left-10">Save changes for this appointment and the following ones in this series</span>
            </label>
        </div>
        <div class="align-right pad-10">
            <button type="button" class="secondary bottom-0" @click="handleCancel">Cancel</button>
            <button type="button" class="primary bottom-0" @click="handleSave">Save</button>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import RecurrenceSaveType from '@/util/recurrenceSaveType';

    export default {
        name: 'SaveRecurringModal',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                RecurrenceSaveType,
                saveChoice: RecurrenceSaveType.OnlyCurrent
            };
        },
        methods: {
            handleCancel() {
                this.saveChoice = RecurrenceSaveType.OnlyCurrent;
                this.$emit('close');
            },
            handleSave() {
                this.$emit('selectionConfirmed', this.saveChoice);
            }
        }
    };
</script>

<style scoped></style>
