<template>
    <div>
        <h5 class="e-textlabel">{{ headerText }}*</h5>
        <slot />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Users',
        computed: {
            ...mapState('eventEditor', {
                users: (state) => state.event.users.users,
            }),
            headerText() {
                return `User${this.users.length > 1 ? 's' : ''}`;
            },
        }
    };
</script>
