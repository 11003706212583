<template>
    <input
        type="text"
        id="title"
        :value="title"
        placeholder="Add Appointment Title*"
        @input="handleChange"
    />
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'TitleInput',
        computed: {
            ...mapState('eventEditor', {
                title: (state) => state.event.title,
            }),
        },
        methods: {
            ...mapActions('eventEditor', {
                updateTitle: 'updateTitle',
            }),
            handleChange(e) {
                this.updateTitle(e.target.value);
            }
        },
    };
</script>
<style scoped>
      input#title {
        height: 1.8em;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
